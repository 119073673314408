@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-tourokuba_menu {
  padding: 16px 0 40px;

  @include r.sp {
    padding: 16px 8px 40px;
  }

  .contents950,
  .contents770 {
    max-width: 640px;
    margin: auto;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }

  &__txt {
    font-size: 1.3rem;

    span {
      &:nth-child(2) {
        margin: 0 16px 0 8px;
      }
    }
  }

  .nk23_c-title02 {
    margin-top: 16px;
  }
}
@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-about_jyusyosiki {
	margin: 0 auto;
	padding: 16px 16px 40px !important;
	display: flex;
	max-width: 992px;
	box-sizing: border-box;

	#contents750 {
		padding: 15px 10px 30px;
		background-color: #fff;
		border-radius: 0 0 8px 8px;
		display: flex;
		flex-wrap: wrap;

		a {
			&:nth-child(2),
			&:nth-child(5) {
				margin-left: 10px;
			}

			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(4),
			&:nth-child(5) {
				margin-bottom: 10px;
				width: 370px;
			}

			&:nth-last-child(1),
			&:nth-last-child(2) {
				border-radius: 8px;
				overflow: hidden;
			}

			&:nth-last-child(1) {
				margin-left: 10px;
			}
			#btn_qanda {
				max-width: 278px;
			}
		}
	}

	.dotline {
		margin: 12px 0 22px;
		width: 100%;
		height: 1px;
		border-top: 1px solid v.$color19;
	}
}

@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-news {
  max-width: 960px;
  margin: 0 auto;

  &#contents {
    @include r.sp {
      padding: 8px 8px 24px;
    }
  }

  .contents950,
  #contents770 {
    max-width: 100%;
    width: 100%;
    margin: 0 !important;
  }

  .tl-main {
    margin-bottom: 16px;

    @include r.sp {
      margin-bottom: 8px;
    }
  }

  #contents770 {
    .boxNews {
      &:nth-child(2) {
        .ls-news-blog li span img {
          margin-right: 13px;
        }
        @include r.sp {
          margin-bottom: 32px;
        }
      }
    }
  }
}

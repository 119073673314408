@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;
.p-present {
	//--pc------------------------

	.display_pc {
		#contents {
			display: flex;
			max-width: 992px;
			margin: 16px auto 40px;
			padding: 0 16px !important;
			box-sizing: border-box;
			@include r.sp {
				flex-wrap: wrap;
				flex-direction: column-reverse;
				padding: 0 8px !important;
			}
		}
		//--layout------------------------
		#contents770a,
		#contents770 {
			border-radius: 8px;
			@include r.sp {
				background: transparent;
				padding: 0 !important;
				border-radius: none;
				width: 100% !important;
			}
		}
		#contents740 {
			margin: 0 auto;
			padding: 15px 0 0 0;
			width: 740px;
			@include r.sp {
				width: 100%;
			}
		}
		.bg-fbfaf4 {
			background: v.$color34;
		}
		.bg-eff5ec {
			background: #fff;
		}
		#breadcrumbs {
			@include r.sp {
				display: none;
			}
		}
		//--heading------------------------
		#tl-present {
			margin-bottom: 16px;
			height: 139px;
			background: url(/info/present/images/bg-tl-head.jpg) no-repeat;
			background-position: bottom;
			@include r.sp {
				background: none;
				height: auto;
			}
			h2 {
				text-indent: -9999px;
				@include r.sp {
					background-image: none;
					height: auto;
					text-indent: 0;
					font-size: 2rem;
					text-align: center;
					font-weight: 700;
				}
			}
		}

		//---present form top text---------------
		#notice-present {
			.mL15 {
				width: 700px;
				margin: 0 auto;
				padding: 10px 15px;
				font-size: 1.3rem;
				line-height: 19px;
				border-radius: 8px;
				background-color: rgba(230, 53, 171, 0.1);
				@include r.sp {
					width: 100%;
					font-size: 1.6rem;
					text-align: center;
					background-color: transparent;
				}
			}
			//--only confirmation page----
			+ p {
				display: inline-block;
				font-size: 1.3rem;
				margin-top: 20px;
				margin-bottom: 13px;
				margin-right: 10px;
			}
			+ p + p {
				display: inline-block;
				font-size: 1.3rem;
				margin-top: 20px;
				margin-bottom: 13px;
			}
		}
		//--present form info-----------
		.bl-left {
			strong {
				font-weight: 400;
			}
		}
		.bg-2chaku {
			width: 330px;
			margin-left: 30px;
			float: left;
			padding: 15px 15px 0;
			border-radius: 8px;
			label[for="pre1"] {
				font-size: 1.3rem;
				line-height: 20.8px;
			}
			p {
				margin-bottom: 8px !important;
			}
			p:nth-child(1) {
				img {
					margin-bottom: 6px;
				}
			}
		}
		.bg-3chaku {
			width: 330px;
			margin-right: 30px;
			float: right;
			padding: 15px 15px 0;
			border-radius: 8px;
			p {
				margin-bottom: 8px !important;
			}
			label[for="pre2"] {
				font-size: 1.3rem;
				line-height: 20.8px;
			}
		}
		//-----table---------------
		.tb00-i th {
			padding: 4px 2px;
			border: none;
			border-bottom: 1px solid #fff;
			font-weight: normal;
			line-height: 1.8;
			text-align: left;
		}
		#contents740 table {
			width: 100%;
			font-size: 1.3rem;
			line-height: 20.8px;
			@include r.sp {
				font-size: 1.6rem;
			}
			.w180px {
				width: 190px;
				@include r.sp {
					width: 146px;
				}
			}
			th {
				@include r.sp {
					//confirm sp page
					padding: 4px 5px;
					border-bottom: 1px solid v.$color19;
				}
			}
			td {
				@include r.sp {
					//confirm sp page
					border-bottom: 1px solid v.$color19;
				}
			}
			//first table
			&:nth-of-type(1) {
				margin-bottom: 15px;
				tr {
					&:nth-child(odd) {
						background-color: v.$color34;
						@include r.sp {
							background-color: transparent;
						}
					}
				}
				td {
					line-height: 23px;
					@media screen and (orientation: landscape) and (max-width: 767px) {
						line-height: 1;
					}
				}
			}
			//second table
			&:nth-of-type(2) {
				margin-top: 0 !important;
				margin-bottom: 0 !important;
				.bg-eff5ec {
					background: v.$color34;
				}
				.bg-fbfaf4 {
					background: #ffff;
				}
				.w180px {
					@include r.sp {
						width: 146px;
					}
				}
				tr {
					th {
						&:nth-child(odd) {
							@include r.sp {
								background-color: v.$color34;
							}
						}
						text-align: left;
					}
					td {
						margin-left: 184px;
						@include r.sp {
							margin-left: 0;
						}
					}
					th,
					td {
						@include r.sp {
							//confirm sp page
							display: table-cell;
						}
					}
				}
			}
		}
		@include r.sp {
			//confirm sp page
			//table border radius
			table {
				border-collapse: separate;
				overflow: hidden;
				border-spacing: 0;
				border-radius: 8px;
				th,
				td {
					border-right: 1px solid v.$color19;
					border-bottom: 1px solid v.$color19;
					&:last-child {
						border-right: none;
					}
				}
				tr {
					&:first-child {
						th,
						td {
							border-top: none !important;
						}
					}
					&:last-child {
						th,
						td {
							border-bottom: none;
						}
					}
				}
			}
		}
		//only present form----------
		form {
			> input + p {
				margin-top: 30px;
				margin-bottom: 0 !important;
			}
			p.clr10 {
				margin-bottom: 0 !important;
			}
			//---form table----
			table {
				td {
					font-weight: 400;
				}
				//first table
				&:nth-of-type(1) {
					margin-bottom: 10px;
					td {
						line-height: 2;
					}
				}
				//second table
				&:nth-of-type(2) {
					margin-top: 15px;
					margin-bottom: 8px;
					.w180px {
						width: 185px;
						@include r.sp {
							width: auto;
						}
					}
					tr {
						&:nth-child(even) {
							background-color: v.$color34;
						}
						th {
							&:nth-child(odd) {
								background-color: transparent;
							}
						}
						th,
						td {
							display: table-cell;
						}
					}
				}
			}
		}
		//bottom text----------------
		#contents770a a {
			@include r.hover {
				text-decoration: underline;
			}
			img {
				width: 121px;
			}
		}
		.al-center {
			margin-bottom: 0 !important;
			text-align: center;
			font-size: 1.3rem;
			line-height: 2;
			font-weight: 400;
			br {
				line-height: 0;
			}
			@include r.sp {
				//confirm sp page
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
			a {
				color: v.$color-link;
				margin-bottom: 13px;
				text-decoration: none;
			}
			&:last-of-type {
				a {
					margin-bottom: 0;
					color: v.$color0;
				}
			}
		}
	}
	//--sp--------------------------------
	.display_sp {
		article {
			margin-top: 15px;
		}
		#contents {
			padding: 10px !important;
			p {
				font-size: 1.6rem;
				font-weight: 400;
				&.al_center {
					a {
						color: v.$color-link;
					}
				}
			}
		}
		hr {
			display: block;
			height: 1px;
			margin: 1em 0;
			padding: 0;
			border: 0;
			border-top: 1px solid #ccc;
		}
		//---table border radius
		table {
			border-collapse: separate;
			overflow: hidden;
			border-spacing: 0;
			border-radius: 8px;
			&:nth-of-type(1) {
				margin-top: 30px;
			}
			&:nth-of-type(2) {
				border-radius: 0 0 8px 8px;
			}
			th,
			td {
				border-right: 1px solid v.$color19;
				border-bottom: 1px solid v.$color19;
				&:last-child {
					border-right: none;
				}
			}
			tr {
				&:first-child {
					th,
					td {
						border-top: none !important;
					}
				}
				&:last-child {
					th,
					td {
						border-bottom: none;
					}
				}
			}
		}
		//-----------
		table {
			border-collapse: collapse;
			border-spacing: 0;
			font-size: 1.6rem;
			//first table
			&:nth-of-type(1) {
				margin-bottom: 16px;
			}
			&:nth-of-type(2) {
				margin-bottom: 10px;
			}
			tr {
				border-collapse: collapse;
				border-spacing: 0;
				vertical-align: top;
			}
			th {
				border-collapse: collapse;
				border-spacing: 0;
				padding: 5px;
				background-color: v.$color34;
				text-align: left;
				width: 40%;
			}
			td {
				background: #fff;
				border-collapse: collapse;
				border-spacing: 0;
				padding: 5px;
				word-wrap: break-word;
				word-break: break-all;
			}
			th,
			td {
				vertical-align: middle;
			}
		}
		button {
			margin: 7px auto 0;
			width: 320px;
			min-height: 42px;
			display: flex;
			justify-content: center;
			align-items: center;
			min-width: -webkit-max-content;
			min-width: max-content;
			font-size: 1.6rem;
			font-weight: 400;
			line-height: 2rem;
			color: v.$color0;
			padding-left: 4px;
			padding-right: 4px;
			max-width: 100%;
			cursor: pointer;
			border-radius: 999px;
			border: 1px solid v.$color19;
			box-shadow: 0 2px 0 v.$color19;
			background-color: #fff;
			&[disabled] {
				box-shadow: 0 2px 0 rgba(201, 201, 201, 0.5);
				border: 1px solid rgba(201, 201, 201, 0.5);
				pointer-events: none;
			}
		}
		.title01 {
			// margin-left: 0;
			margin-bottom: 10px;
			font-size: 2rem;
			line-height: 1;
			text-align: center;
		}
		.title02 {
			background: #fff;
			border-radius: 8px 8px 0 0;
			padding: 7px;
			border-bottom: solid 1px v.$color19;
		}
		//--sp form----------------
		.tx_orange01 {
			color: v.$color13;
		}
		.al_center {
			text-align: center;
		}
		.present_radio {
			background: #fff;
			border-radius: 5px;
			padding: 5px;
			width: 100%;
			li {
				width: 100%;
				border-bottom: solid 1px v.$color19;
				text-indent: -1em;
				padding-left: 22px;
				&.last {
					border-bottom: none;
				}
			}
			li label {
				width: 100%;
				display: block;
				padding: 10px;
				font-weight: 400;
			}
			img {
				margin: 0 auto 0;
			}
			input[type="radio"],
			input[type="checkbox"] {
				margin-right: 10px;
			}
		}
		form#present {
			p {
				margin-bottom: 3px;
			}
			.present_title {
				margin-bottom: 9px;
			}
			.title02 {
				border-radius: 8px;
				border-bottom: none;
				margin-bottom: 6px;
			}
			.btn_next {
				margin-top: 0;
			}
		}
		form {
			margin-bottom: 20px;
		}
	}
	//--sp & pc common
	//present form parts-------------
	input,
	select,
	textarea {
		margin-left: 1px;
	}
	input[type="text"],
	input[type="tel"],
	input[type="email"],
	input[type="adress"] {
		height: 23px;
		padding: 0 7px;
		border: 1px solid v.$color19;
		border-radius: 8px;
		@include r.sp {
			height: 33px;
			font-size: 1.8rem;
		}
		&::placeholder {
			color: v.$color19;
		}
		&[disabled] {
			pointer-events: none;
			background-color: v.$color19;
		}
	}
	input[type="text"] {
		width: 184px;
		@include r.sp {
			width: 100%;
		}
	}
	input[name="sei"] {
		width: 184px;
		@include r.sp {
			width: 100%;
		}
	}
	input[name="mei"] {
		width: 184px;
		@include r.sp {
			width: 100%;
		}
	}
	input[name="kana_sei"] {
		width: 184px;
		@include r.sp {
			width: 100%;
		}
	}
	input[name="kana_mei"] {
		width: 184px;
		@include r.sp {
			width: 100%;
		}
	}
	input[name="address"] {
		width: 394px;
		@include r.sp {
			width: 100%;
		}
	}
	input[name="yubin"] {
		width: 93px;
		@include r.sp {
			width: 100%;
		}
	}
	input[name="tel"] {
		width: 219px;
		@include r.sp {
			width: 100%;
		}
	}
	input[name="email"] {
		width: 219px;
		@include r.sp {
			width: 100%;
		}
	}
	input[type="checkbox"],
	input[type="radio"] {
		width: 13px;
		height: 13px;
		margin-right: 1px;
		position: relative;
		margin-top: -2px;
		vertical-align: middle;
		border: 1px solid v.$color0;
		border-radius: 4px;
		background-color: #fff;
		background-repeat: no-repeat;
		background-position: center top 2px;
		background-size: 10px auto;
		transition: all 0.3s ease-in-out;
		@include r.sp {
			width: 18px;
			height: 18px;
			background-size: 9px auto;
			background-position: center top 5px;
		}
		&:before {
			content: "";
			display: inline-block;
			width: 13px;
			height: 13px;
			position: absolute;
			top: -1px;
			left: 0;
			pointer-events: none;
			@include r.sp {
				width: 18px;
				height: 18px;
			}
		}
		&:checked {
			background-color: v.$color0;
			background-image: url("/assets/images/common/icon/icon_checked-white.svg");
		}
		&:disabled {
			opacity: 0.25;
			background-color: v.$color0;
			background-image: url("/assets/images/common/icon/icon_checked-white.svg");
		}
	}
	input[type="checkbox"] {
		border-radius: 4px;
	}
	input[type="radio"] {
		border-radius: 50%;
	}
	select {
		padding: 0 15px 0 4px;
		width: 126px;
		height: 23px;
		border-radius: 8px;
		border: 1px solid v.$color19;
		text-align: center;
		font-size: 1.3rem;
		line-height: 1;
		background-image: none !important;
		background: #fff url("/assets/images/common/icon/icon_down.svg") no-repeat center right 4px / 10px auto !important;
		color: v.$color0;
		@include r.sp {
			width: 100%;
			height: 37px;
			font-size: 1.6rem;
			text-align: left;
		}
	}
	textarea {
		width: 522px;
		height: 77px;
		border: 1px solid v.$color19;
		border-radius: 8px;
		padding: 0 7px;
		font-size: 1.3rem;
		display: inline-block;
		@include r.sp {
			font-size: 1.6rem;
			margin-top: 3px;
			width: 100%;
			height: 50px;
		}
		&::placeholder {
			color: v.$color19;
		}
		&[disabled] {
			pointer-events: none;
			background-color: v.$color19;
		}
	}
	input[type="image"] {
		width: 121px;
	}
}

@media print {
	.p-present .display_pc #contents {
		max-width: none;
		padding-top: 0 !important;
		margin: 0 !important;
	}
	.p-present .display_pc #contents740 {
		width: 100%;
		form {
			p {
				display: none;
			}
		}
	}
	.p-present .display_pc #tl-present {
		background-size: 100%;
		height: 180px;
	}
}

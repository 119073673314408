@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-cho_info {
	&#contents-nw {
		max-width: 992px;
		margin: auto;

		.kanriba {
			.round-cnr.resp {
				&:nth-child(4) {
					border-radius: 0 0 8px 8px !important;
					margin-bottom: 8px;

					@include r.sp {
						border-top: 0;
						border-radius: 0 !important;
					}
				}
			}
		}
		.twoColEq_L,
		.twoColEq_R {
			margin: 0 0 16px;
		}
		.twoColEq_L {
			.tb04 {
				.logo img {
					width: 32px;

					&.fnb {
						width: 24px;
					}
				}
			}
		}
	}

	#tl-prof {
		@include r.sp {
			font-size: 2rem;
		}
	}

	#trainer-prof {
		.btn-style-reset {
			margin-bottom: 0;
		}
	}

	.kanriba {
		.round-cnr {
			&.resp {
				margin-top: 0 !important;
				.round-cnr {
					margin: 0 auto !important;
					padding: 0;
					@include r.sp {
						padding: 0;
					}
				}
				.bl-left.mL5 {
					@include r.sp {
						width: 100%;
						text-align: center;
					}
				}
				.odds-pagination {
					@include r.sp {
						display: flex;
						justify-content: center;
						margin-top: 8px;
						width: 100%;
					}
					li.current {
						font-weight: bold;
					}
				}
				&:nth-child(1) {
					margin-bottom: 0 !important;
				}

				&:nth-child(4) {
					margin-top: 0 !important;
				}
			}
		}

		.table-wrapper .tb01 {
			border-radius: 0;
		}
	}

	@media print {
		&#contents-nw {
			max-width: none;
		}

		&#contents-nw .twoColEq_L,
		&#contents-nw .twoColEq_R {
			width: 45%;
		}

		.nk23_c-title01 {
			margin-bottom: 50px;
		}

		h3.tl-h3 {
			padding: 0;
		}

		.w770px {
			width: 100%;
		}

		.round-cnr.resp {
			padding: 0;
		}

		.tx-orange01 {
			color: #221f1f;
		}
		.round-cnr.resp {
			border-top: none;
		}
	}
}

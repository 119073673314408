@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-cho_detail_search_result {
	#contents950 {
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
	}
}

@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-win_uma {
  &1 {
    padding: 16px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }

    .nk23_c-title01 {
      @include r.sp {
        margin-bottom: 8px;
      }
    }

    .nk23_c-title01,
    .nk23_c-banner4 {
      margin-bottom: 16px;
    }

    .nk23_c-table01 {
      margin-top: 16px;
    }
  }

  .nk23_c-banner4 {
    padding: 16px;
    border-radius: 8px;
    background-color: #fff;

    @include r.sp {
      padding: 16px 8px;
    }

    &__text {
      font-size: 1.2rem;
      line-height: 2.2rem;
      margin-bottom: 8px;
    }

    &__bottom {
      font-size: 2rem;
      line-height: 2.9rem;
      font-weight: 700;

      &.is-flex-sp {
        @include r.sp {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1.6rem;
          line-height: 2.7rem;
        }
      }

      @include r.sp {
        font-size: 1.6rem;
        line-height: 2.7rem;
      }

      .is-distance {
        @include r.pc {
          margin-left: 24px;
          display: inline-block;
        }

        @include r.sp {
          text-align: right;
          width: 170px;
          margin-left: 16px;
        }
      }
    }
  }

  .nk23_c-table01 {
    &.is-style15 {
      thead {
        th {
          word-break: keep-all;
          white-space: nowrap;
          min-width: auto;

          &:nth-child(1),
          &:nth-child(4) {
            min-width: 27px;
          }

          // &:nth-child(5),
          // &:nth-child(8),
          // &:nth-child(9) {
          //   min-width: 151px;
          // }

          // &:nth-child(8) {
          //   min-width: 182px;
          // }

          // &:nth-child(11),
          // &:nth-child(13) {
          //   min-width: 116px;
          // }

          // &:nth-child(12) {
          //   min-width: 77px;
          // }
        }
      }

      tbody {
        td {
          word-break: keep-all;
          white-space: nowrap;
          &:nth-child(3),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(10) {
            text-align: center !important;
          }
          @include r.sp {
            &:last-child {
              padding: 4px 8px;
            }
          }
        }
      }
    }

    &__table {
      tbody tr {
        @include r.hover {
          td {
            background-color: v.$color28;
          }
        }
      }
    }

    &__icon {
      @include r.sp {
        top: 160px;
        transform: translate(-50%, 0);
      }
    }
  }
}

.p-win_uma_variation1 {
  .nk23_c-table01 {
    &.is-style15 {
      .nk23_c-table01__icon {
        @include r.sp {
          top: 41px;
          transform: translate(-50%, 0);
        }
      }
    }
  }
}

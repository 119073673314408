@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-netbank_accountapplication {
  display: flex;
  max-width: 960px;
  margin: auto;

  #contents770 {
    .contents750 {
      background: #fff;
      border-radius: 8px;
      padding: 20px 10px 0;
      width: 770px;

      p {
        font-size: 1.3rem;
        line-height: 2.4rem;

        &.tx-small {
          font-size: 1.1rem;
          line-height: 1.9rem;
        }
      }

      >.clearfix {
        &:last-child {
          margin: 34px -10px 0;

          .title-spat4-02.title_netbank {
            margin-left: 0px !important;
          }
        }
      }
    }

    .bg-spat4-01 {
      background-size: auto auto !important;
      background-color: #004ea2 !important;
      background-image: none !important;
      margin: 8px 0 0;
      border-radius: 0;
      background-image: repeating-linear-gradient(-45deg,
          transparent,
          transparent 4px,
          #125aa9 4px,
          #125aa9 6px) !important;
      padding: 12px 10px 22px;

      a {
        background: #ffffff !important;
        color: #004ea2 !important;
        font-size: 1.3rem;
        border-radius: 24px;
        text-align: center;
        display: block;
        font-weight: 400;
        margin: 0 0 12px;
        width: 362px;
        box-sizing: border-box;
        transition: none;
        border-color: #004ea2;
        box-shadow: 0 2px #004ea2;

        &:hover {
          transform: translateY(2px);
          border: 1px solid #004ea2 !important;
          box-shadow: none;
        }
      }

      .clearfix {
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;

        &:after {
          display: none;
        }
      }
    }

    .clearfix {
      &:nth-child(6) {
        a {
          margin-bottom: 0;
        }
      }
    }
  }

  .title-spat4-02 {
    &.title_netbank {
      margin-bottom: 20px !important;
      margin-left: -9px !important;

      &:before {
        display: none;
      }
    }
  }

  .tablebanks {
    margin-bottom: 19px;
  }

  .bankList {
    tr {
      td {
        font-size: 1.1rem;

        a {
          color: #2461ee;
          display: inline;

          &:hover {
            text-decoration: underline;
          }
        }

        &:nth-child(1) {
          img {
            width: 143px;
          }
        }
      }

      th,
      td {
        padding: 4px 6px;
      }
    }

    .bankGroup {
      td {
        padding: 0;

        &:nth-child(1) {
          width: 143px;
        }

        &:nth-child(2) {
          width: 211px;
        }
      }
    }

    .titleBank {
      color: #004ea2;
      font-size: 1.3rem;
      font-weight: bold;
      line-height: 1.9rem;
      padding: 2px 6px 6px;

      &:before {
        background: #004ea2;
        width: 12px;
        height: 12px;
        vertical-align: -1px;
      }
    }
  }
}

.modal_content {
  .title-spat4-03 {
    margin-bottom: 10px !important;
    color: #005aa9 !important;
  }

  ol {
    font-size: 1.3rem;
    margin-left: 20px;

    li {
      list-style: decimal outside;
    }
  }

  .tb01 {
    border: 1px #ccc solid;
    border-collapse: collapse;
    width: auto;

    td {
      text-align: left;
    }
  }
}
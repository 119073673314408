@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-race_trend_cho {
  &1 {
    padding: 16px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }
  }

  .nk23_c-slide02 {
    margin-top: 16px;
    @include r.sp {
      margin-top: 8px;
    }
  }

  .nk23_c-box1 {
    margin-top: 16px;
    @include r.sp {
      margin-top: 8px;
    }
  }

  .nk23_c-tab2 {
    margin-top: 16px;
  }
  .nk23_c-table15__box {
    align-items: flex-start;
		padding: 8px 0;

		@include r.sp {
			padding: 0;
		}
  }
}

@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-taisen {
	&1 {
		padding: 16px 0 40px;

		@include r.sp {
			padding-top: 8px;
		}

		.nk23_l-container1 {
			@include r.sp {
				padding: 0;
			}
		}
	}

	.nk23_c-title01 {
		margin-bottom: 16px;
	}

	.nk23_c-tab2 {
		margin-top: 16px;
	}

	.nk23_c-text01 {
		@include r.sp {
			width: calc(100% - 16px);
			margin: 0 auto;
		}

		+ .nk23_c-text01 {
			margin-top: 4px;
		}
	}
}

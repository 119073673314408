@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-download_index_past {
	display: flex;
	max-width: 960px;
	margin: auto;

	#contents740 {
		width: 770px;
		background: #fff;
		padding: 15px 16px 40px !important;
		border-radius: 8px;
		font-size: 1.3rem;
		line-height: 2.1rem;

		h2 {
			margin-bottom: 16px;
		}

		.tl-download {
			height: 29px;
			margin: 36px 0 20px;
		}

		.title__download {
			font-size: 1.8rem;
			line-height: 1.5;
			color: v.$color0;
		}

		table {
			max-width: 100%;
			margin: 5px 0 0 52px;

			th,
			td {
				font-size: 1.3rem;
				line-height: 1.2;
				color: v.$color0;
				padding-bottom: 8.5px;
			}

			th {
				white-space: nowrap;
				word-break: keep-all;
			}

			td {
				padding-left: 5.5px;
			}
		}

		a {
			color: v.$color-link;
			word-break: break-all;
			@include r.hover {
				text-decoration: underline;
			}

			&.ico-popup {
				margin: 0;
				padding: 0 0 0 23px;
				background: url(p.$path + 'images/common/icon/ico-popup-gif.svg')
					no-repeat left top 4px / 20px;
			}
		}

		.bd-btm-ccc {
			border-bottom: 1px solid v.$color19;
			margin: 36px 0 15px;
		}

		h4.tx-gray01 {
			font-size: 1.3rem;
			line-height: 1.2;
			color: v.$color0;
			font-weight: bold;
			margin-bottom: 13px;
		}

		.caption {
			font-size: 1.3rem;
			line-height: 1.7;
			color: v.$color0;
		}
	}
}

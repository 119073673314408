@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-about_movie {
  display: flex;
  max-width: 960px;
  margin: auto;

  #contents730 {
    width: 770px;
    background: #fff;
    padding: 15px 20px 15px !important;
    border-radius: 8px;
    font-size: 1.3rem;
    line-height: 2.1rem;

    h2 {
      margin-bottom: 14px;
    }

    ol {
      counter-reset: section;
      list-style-type: none;
      padding-left: 6px;
      margin-bottom: 22px;
      li {
        &::before {
          counter-increment: section;
          content: counters(section, ".") ".";
          display: inline-block;
          width: 18px;
          text-align: right;
          margin-right: 3px;
        }
        a {
          color: v.$color-link;
          @include r.hover {
            text-decoration: underline;
          }
        }
      }
    }
    ul {
      margin-left: 11px;
      li {
        a {
          position: relative;
          display: flex;
          align-items: center;
          max-width: max-content;
          &::before {
            content: "";
            position: relative;
            display: inline-block;
            width: 4px;
            height: 4px;
            background: #221f1f;
            margin-right: 11px;
            border-radius: 100%;
          }
        }
      }
    }

    p {
      font-size: 1.3rem;
      line-height: 2.1rem;
      display: block;
      margin-bottom: 11px;
      img {
        margin: 0;
      }
      strong {
        font-weight: 400;
      }
    }
    a {
      color: #2461ee;

      &:hover {
        text-decoration: underline;
      }
    }

    h3 {
      margin-bottom: 14px;
      margin-top: 5px;
    }
    h4 {
      font-weight: 700;
      margin-bottom: 13px;
      line-height: 21px;
    }
    .tb01 {
      border-radius: 8px;
      overflow: hidden;
      border-collapse: separate;
      border-spacing: 0;
      margin: 14px 0 13px;
      tr {
        td {
          text-align: left;
          border: none;
          border-bottom: 1px solid v.$color19;
          border-left: 1px solid v.$color19;
          font-size: 1.3rem;
          padding: 2px 4px;
          &:first-child {
            background: #404040;
            color: #ffffff;
            font-weight: bold;
          }
          &:last-child {
            border-right: 1px solid v.$color19;
          }
        }
        &:first-child {
          td {
            border-top: 1px solid v.$color19;
            &:first-child {
              border-radius: 8px 0 0 0;
            }
            &:last-child {
              border-radius: 0 8px 0 0;
            }
          }
        }
        &:last-child {
          td {
            &:first-child {
              border-radius: 0 0 0 8px;
            }
            &:last-child {
              border-radius: 0 0 8px 0;
            }
          }
        }
      }
    }

    .link-pagetop.page-top {
      margin-top: 0;
      padding-right: 0;
      margin-right: -2px;
      margin-bottom: 6px;
      background: url(p.$path + "images/common/icon/ico-page-top.svg") no-repeat left center / 11px;
    }
  }

  &__txt {
    margin-top: 13px;
  }

  &__img {
    margin: 13px 0;

    &:last-child {
      margin: 13px 0 0;

      img {
        width: 440px;
      }
    }
  }
}

@use "../../settings/variable"as v;
@use "../../settings/mixin"as r;
@use "../../settings/_path"as p;

.p-spat4_about {
  display: flex;
  max-width: 960px;
  margin: auto;

  .contents750 {
    background: #fff;
    padding: 20px 10px 30px;
    border-radius: 8px;
    width: 770px;

    .title-spat4-02 {
      &.title_about {
        margin: 20px 0 20px -9px !important;

        &:before {
          background: none;
        }

        &:first-child {
          margin-top: 0 !important;
        }
      }
    }

    >p {
      font-size: 1.3rem;
    }

    p {
      &.tx-small {
        font-size: 1.1rem;
        margin-top: 8px;
      }

      img {
        margin: auto;
      }
    }

    .tb00 {
      tr {
        td {
          img {
            height: 75px;
          }
        }
      }
    }
  }

  .title-spat4-01 {
    color: #fff;
    padding: 16px;
    margin-bottom: 20px !important;
  }

  .list00 {
    li {
      display: inline-block;
    }
  }

  .tb01 {
    border-radius: 8px;
    overflow: hidden;
    font-size: 1.3rem;
    margin: 16px 0;
    border: 1px solid #C9C9C9;
    border-spacing: 0;
    border-collapse: separate;

    tr {
      &:first-child {
        td {
          background: #F7F7F7;
        }

        th,
        td {
          border-top: 0;
        }
      }

      &:last-child {
        td {
          border-bottom: 0;

          &:first-child {
            border-bottom-left-radius: 8px;
          }

          &:last-child {
            border-bottom-right-radius: 8px;
          }
        }
      }

      &:nth-child(2) {
        th {
          background: #F7F7F7;
          color: #221F1F;

          &:last-child {
            border-right: 1px solid #C9C9C9;

          }
        }
      }

      th,
      td {
        border: 0;
        border-top: 1px solid #C9C9C9;
        border-right: 1px solid #C9C9C9;

        &:last-child {
          border-right: 0;
        }
      }

      td {
        text-align: left;
        padding: 10px 4px;

        &:nth-child(1) {
          width: 75px;
        }

        &:nth-child(2) {
          width: 226px;
        }

        &:nth-child(3) {
          width: 225px;
        }
      }
    }
  }
}

.bg-netbank-pl {
  background: #DFE8F7;

  &__disc {
    font-size: 0.8rem;
    vertical-align: middle;
  }
}

.bg-telephone-pl {
  background: #FFF9E6;
}
@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-netbank_flow {
  display: flex;
  max-width: 960px;
  margin: auto;

  .title-spat4-02 {
    &.title_netbank {
      margin-left: -10px !important;

      &:before {
        display: none;
      }
    }
  }

  .contents750 {
    background: #fff;
    border-radius: 8px;
    padding: 20px 10px 30px;
    width: 770px;

    > p {
      font-size: 1.3rem;
    }

    p {
      &.al-center {
        padding: 0 8px;
      }

      &.tx-small {
        font-size: 1.1rem;
        margin-top: 13px;
        padding: 0 8px;
        line-height: 1.9rem;
      }

      img {
        margin: auto;
      }
    }
  }

  .title-spat4-01 {
    padding: 16px;
  }

  .tb00 {
    max-width: 750px;
    margin: 13px auto 21px;

    td {
      padding: 0 5px 10px;
      text-align: center;

      img {
        width: 110px;
        height: auto;
        display: inline-block;
      }
    }
  }
}

@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-jyougai_release_list {
	margin: 0 auto;
	padding: 16px 16px 40px !important;
	display: flex;
	max-width: 992px;
	box-sizing: border-box;

	#contents730 {
		width: 770px;
		padding: 15px 20px 20px !important;
		background-color: #fff;
		border-radius: 8px;
	}

	#Map {
		img {
			width: 390px;
			position: relative;
			top: 2px;
		}

		a {
			// .p-jyougai_release_list #Map a img .
			img {
				width: auto;
				height: 16px;
				top: 0;
			}
		}
	}

	#contents770 {
		table {
			th {
				font-size: 1.2rem;
				line-height: 1.5rem;

				&:nth-child(1) {
					width: 1%;
					min-width: 183px;
				}

				&:nth-child(2) {
					width: 1%;
					min-width: 203px;
				}

				&:nth-child(3) {
					width: 1%;
					min-width: 344px;
				}
			}

			td {
				font-size: 1.3rem;

				a {
					color: v.$color-link;
				}
			}

			u {
				text-decoration: none;
			}
		}
	}

	.clr25 {
		margin: 0;
		padding: 0;
		clear: both;
		font-size: 0;
		line-height: 24px;
		height: 24px;
	}
}

#fcl-data {
	// position: relative;

	// li {
	// 	top: 0;
	// 	right: 0;
	// 	position: absolute;
	// 	opacity: 0;
	// 	pointer-events: none;
	// 	transition: opacity 0.5s ease-in-out;
	// 	z-index: 0;

	// 	&.active {
	// 		z-index: 1;
	// 		opacity: 1;
	// 		pointer-events: all;
	// 	}
	// }

	// .box-fcl {
	// 	width: 335px;
	// }
}

@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-tate_uma_shosai {
  &1 {
    padding: 16px 0 0;
    @include r.sp {
      padding-top: 8px;
    }
    .nk23_c-title01 {
      margin-bottom: 16px;
      @include r.sp {
        margin-bottom: 8px;
      }
    }
  }
  &2 {
    padding-bottom: 40px;
    &__txt {
      font-size: 1.4rem;
      line-height: 20px;
      padding: 8px 0;
      text-align: right;
      display: block;
    }
    @include r.sp {
      margin-top: 8px;
    }
  }
  .nk23_c-table11__table {
    tr {
      &.is-tx_tohaba {
        td {
          word-break: keep-all;
          white-space: nowrap;
        }
      }
    }
  }
}

@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
    size: A4 landscape;
  }
  .nk23_c-tab1__navi {
    display: none;
  }
  .nk23_c-tab1__box__top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
  }
  .nk23_c-tab1__box__bottom {
    display: none;
  }
  .nk23_c-table11__btnG {
    display: none;
  }
  .nk23_c-table11__tbcontent.is-style1 {
    padding-top: 0;
    border-radius: 0;
    width: auto;
  }
  .nk23_c-table11__inner {
    padding: 0;
  }
  .scroll-content {
    transform: none !important;
  }
  .nk23_c-link.is-help {
    display: none;
  }
  .nk23_c-table11 {
    margin-top: 0;
    &__table {
      border-radius: 0;
      font-size: 1rem;

      th,
      td {
        //width: 100%;
        font-size: 0.8rem !important;
        padding: 2px 1px !important;
        border: 1.5px solid v.$color19;
        letter-spacing: -0.15rem !important;
      }
      td {
        width: 60px !important;
				min-width: 60px !important;
				max-width: 60px !important;
      }
      th {
        &:last-child {
          width: 40px !important;
					min-width: 40px !important;
					max-width: 40px !important;
        }
      }
    }
    tr {
      &.is-waku {
        th {
          &:first-child {
            display: none;
          }
        }
      }
      &.is-uma,
      &.is-tx_tohaba,
      &.is-tx_tohaba,
      &.is-kishu,
      &.is-seirei,
      &.is-odds,
      &.is-weight,
      &.is-zenso2,
      &.is-zenso3,
      &.is-zenso4,
      &.is-zenso5 {
        th {
          &:first-child {
            display: none;
          }
        }
      }
      &.is-time,
      &.is-numsec,
      &.is-zenso1,
      &.is-numsec {
        th {
          &:first-child,
          &:nth-of-type(2) {
            display: none;
          }
        }
        td {
          &:nth-of-type(18),
          &:nth-of-type(19) {
            display: none;
          }
        }
      }
			&.is-numsec {
				td {
					.is-numsec__item {
						font-size: 0.8rem !important;
						min-width: 12px !important;
						margin: 0 -.5px !important;
					}
				}
			}
    }
  }
  .nk23_c-table11__table th .is-link,
  .nk23_c-table11__table td .is-link {
    font-size: 1rem;
  }
  .nk23_c-table11__table .is-time th:last-child,
  .nk23_c-table11__table .is-numsec th:last-child,
  .nk23_c-table11__table .is-zenso1 th:last-child {
    //width: 1% !important;
  }
  .nk23_c-table11__table .is-time th,
  .nk23_c-table11__table .is-numsec th,
  .nk23_c-table11__table .is-zenso1 th {
    //width: 3% !important;
  }
}

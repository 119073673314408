@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-hikaku_uma {
	&__sc1 {
		padding: 16px 0 40px;

		@include r.sp {
			padding-top: 8px;
		}
	}

	.nk23_c-tab2 {
		margin-top: 16px;

		&__content {
			.nk23_c-tab2__content {
				padding-top: 0;
			}
		}

		&__subnavi__item {
			@include r.sp {
				margin-bottom: 16px;
			}
		}
	}

	.nk23_c-table13 {
		&__topfixed {
			.nk23_c-table13 {
				&__table {
					thead {
						tr {
							&:nth-child(2) {
								td {
									@include r.sp {
										background-color: v.$color29;
										color: #fff;
									}
								}
							}
						}
					}
				}
			}
		}

		&__icon {
			@include r.sp {
				top: 74px;
			}
		}

		&__table {
			thead {
				.large {
					font-size: 1.6rem;
				}
			}
		}
	}

	.nk23_c-title01 {
		margin-bottom: 16px;
	}

	.nk23_c-filter2 {
		margin-bottom: 0;
		border-radius: 8px 8px 0 0;
		@include r.sp {
			margin-bottom: 8px;
			border-radius: 8px;
		}
	}
}

@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-about_jyusyosiki_qa {
	margin: 0 auto;
	padding: 16px 16px 40px !important;
	display: flex;
	max-width: 992px;
	box-sizing: border-box;

	#contents750 {
		background: #fff;
		padding: 20px 10px;
		border-radius: 0 0 8px 8px;

		a {
			&:last-child {
				img {
					border-radius: 8px;
				}
			}
		}
	}

	.h2_spat4loto {
		position: relative;

		a {
			display: block;
		}

		img#btn_header_entry {
			position: absolute;
			bottom: 10px;
			right: 10px;
		}
	}

	#table_qanda {
		font-size: 1.3rem;

		th {
			&.th_question {
				width: 36px;
			}

			&.th_answer {
				padding: 10px 0 20px 0;
			}
		}

		td {
			a {
				color: v.$color-link;

				&:hover {
					text-decoration: underline;
				}
			}

			strong {
				font-weight: normal;
			}

			&.td_question {
				margin: 0px;
				padding: 0px 0px 0px 10px;
				font-weight: bold;
				color: #004ea2;
				background: #dfe8f7;
				border-style: none;
				border-radius: 0 8px 8px 0;
			}

			&.td_answer {
				padding: 10px 0 20px 10px;
			}
		}
	}

	.dotline {
		border-top: 1px solid #c9c9c9;
		height: 24px;
	}

	#btn_prev {
		margin-bottom: 15px;
		height: 54px;
	}
}

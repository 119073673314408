@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-uma_info {
	@include r.pc {
		padding-bottom: 80px;
	}

	.val-bottom {
		a {
			color: v.$color-link;

			@include r.hover {
				text-decoration: underline;
			}
		}
	}

	.mT5 {
		padding-top: 8px;
		min-height: 36px;
	}

	.mB5 {
		padding-bottom: 8px;
	}

	.btn-style-reset {
		margin: 0;
		display: inherit;
	}

	#horse-blood {
		.tb04 {
			td {
				vertical-align: top;
				padding: 4px 0;

				&:nth-child(1) {
					width: 30px;
				}

				&:nth-child(2) {
					width: 38px;
				}
			}
		}
	}

	#tl-prof {
		font-size: 1.6rem;
	}

	#horse-info {
		td {
			@include r.pc {
				&:first-child {
					width: 90px;
				}

				&:nth-child(2) {
					width: 124px;
				}
			}

			&.info3 {
				@include r.sp {
					display: none;
				}
			}
		}
	}

	.twoColEq_L,
	.twoColEq_R {
		.tb01 {
			tr {
				th,
				td {
					@include r.sp {
						word-break: keep-all;
						white-space: nowrap;
					}

					@include r.max-w(375px) {
						font-size: 1.2rem;
					}
				}
			}
		}
	}

	#disp-chg-sp {
		.tb01 {
			thead {
				th {
					&:nth-child(4) {
						width: 175px !important;
					}
				}
			}
			tbody {
				tr {
					td {
						.race-name a {
							overflow: hidden;
							max-width: max-content;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
						}
					}
				}
			}
		}
	}

	#disp-chg-pc {
		.tb01 {
			tbody {
				tr {
					td {
						&:nth-child(5),
						&:last-child {
							text-align: right;
						}

						.rirekiRacename a {
							overflow: hidden;
							max-width: max-content;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
						}

						@include r.sp {
							word-break: keep-all;
							white-space: nowrap;
						}
					}
				}
			}
		}
	}

	@media print {
		.nk23_c-table25__content {
			min-width: auto;
		}

		#boxProf-horse {
			border-radius: 0;
			border-left: none;
			border-right: none;
			border-bottom: none;
		}

		#contents-nw .twoColEq_R {
			width: 45%;
		}

		.content-table {
			padding: 0;
		}

		#horse-blood .tb04 td:nth-child(1) {
			width: 36px !important;
		}

		#horse-blood .tb04 td:nth-child(2) {
			width: 45px !important;
		}

		h3.tl-h3 {
			padding: 0;
		}
	}
}

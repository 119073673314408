@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-race_detail_search_result {
	@include r.sp {
		padding-top: 8px;
	}

	.detail_search {
		&_text {
			@include r.sp {
				margin-top: 0;
				font-weight: 700;
			}
		}

		&_content {
			@include r.sp {
				margin-bottom: 10px;
				border-radius: 0 0 8px 8px;
				background-color: #fff;
			}
		}
	}

	#contents950 {
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
	}

	.searchform_area {
		@include r.sp {
			padding: 16px 8px 32px;
		}
	}

	.distance {
		@include r.sp {
			margin-bottom: 16px !important;
		}
	}

	.detail_search_note {
		@include r.sp {
			font-size: 1.3rem;
			line-height: 1.8rem;
		}
	}

	.list_inline {
		li {
			&:first-child {
				.button01 {
					@include r.sp {
						margin-left: 12px;
						margin-right: 4px;
					}
				}
			}
		}

		label {
			@include r.sp {
				margin-right: 4px;
				margin-bottom: 16px;
				display: flex;
				align-items: center;
			}

			input[type='checkbox'] {
				@include r.sp {
					top: 0 !important;
					margin-right: 4px !important;
				}
			}
		}

		li.w15px {
			@include r.sp {
				width: max-content !important;
				font-size: 1.2rem;
				display: flex;
				align-items: center;
			}
		}

		&.fs-1rem {
			li {
				@include r.sp {
					width: 60px;
					margin-right: 4px;
				}
			}

			label {
				@include r.sp {
					font-size: 1rem;
				}
			}
		}

		&.range {
			li {
				@include r.sp {
					margin: 0 4px 4px 0;
					width: 144px;
				}
			}
		}

		&.nk23_u-inputtels {
			li {
				@include r.sp {
					margin-right: 8px;
					margin-bottom: 0;
				}
			}
		}

		&.padding_less {
			@include r.sp {
				margin-bottom: 2px !important;
			}
		}
	}

	.range,
	.class,
	.grade,
	.baba,
	.keibajyou {
		@include r.sp {
			margin-bottom: 8px !important;
		}

		h3 {
			@include r.sp {
				line-height: 1;
			}
		}
	}

	.uma,
	.term {
		@include r.sp {
			margin-bottom: 16px !important;
		}

		h3 {
			@include r.sp {
				line-height: 1;
			}
		}
	}

	.range {
		@include r.sp {
			margin-bottom: 24px !important;
		}
	}

	.class {
		@include r.sp {
			margin-bottom: 8px !important;
		}
	}

	#contents {
		@include r.sp {
			padding: 16px 8px 40px;
		}
	}

	.pagination {
		@include r.sp {
			margin-bottom: 10px;
		}
	}

	.nk23_c-box3 {
		margin-top: 8px;
	}

	.nk23_u-btns {
		margin: 16px 0 4px;

		@include r.sp {
			margin-bottom: 0;
		}
	}

	.nk23_c-text01 {
		&:not(:last-child) {
			margin-bottom: 8px;
		}

		&.is-style5 {
			margin: 0;
		}
	}

	.round-cnr,
	form {
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
	}

	.round-cnr {
		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}

	.title01 {
		@include r.sp {
			text-align: center;
			margin-bottom: 16px;
		}
	}

	.tb01 {
		@include r.pc {
			overflow: hidden;
			border-radius: 8px;
		}

		@include r.sp {
			min-width: 1200px !important;
			table-layout: unset !important;
		}

		& + .round-cnr {
			margin-top: 24px;
		}

		th {
			width: 1%;
			min-width: 90px;
		}

		td {
			text-align: left;
		}

		tr {
			&:first-child {
				th,
				td {
					border-top: none;
				}
			}

			&:last-child {
				th {
					border-bottom: none;
				}
			}
		}

		&.race {
			th {
				&:nth-child(1) {
					width: 1%;
					min-width: 82px;
				}

				&:nth-child(2) {
					width: 1%;
					min-width: 51px;
					@include r.pad {
						min-width: 56px;
					}
				}

				&:nth-child(3) {
					width: 1%;
					min-width: 30px;
				}

				&:nth-child(4) {
					width: 1%;
					min-width: 169px;
				}

				&:nth-child(5) {
					width: 1%;
					min-width: 49px;
				}

				&:nth-child(6) {
					width: 1%;
					min-width: 40px;
				}

				&:nth-child(7) {
					width: 1%;
					min-width: 25px;
				}

				&:nth-child(8) {
					width: 1%;
					min-width: 43px;
					@include r.pad {
						min-width: 48px;
					}
				}

				&:nth-child(9) {
					width: 1%;
					min-width: 51px;
					@include r.pad {
						min-width: 56px;
					}
				}

				&:nth-child(10) {
					width: 1%;
					min-width: 90px;
				}

				// .p-race_detail_search_result .tb01.stripe th:nth-child(10) .
				&:nth-child(10) {
					width: 1%;
					min-width: 84px;
					@include r.pad {
						min-width: 88px;
					}
				}

				&:nth-child(11) {
					width: 1%;
					min-width: 151px;
				}

				&:nth-child(12) {
					width: 1%;
					min-width: 69px;
				}

				&:nth-child(13) {
					width: 1%;
					min-width: 53px;
				}

				&:nth-child(14) {
					width: 1%;
					min-width: 90px;
				}
			}

			td {
				&:nth-child(2) {
					text-align: center !important;
				}
			}

			.rirekiRacename,
			a {
				max-width: 160px;
				display: block;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: left;
				white-space: nowrap;
				img {
					margin: 0 auto;
				}
			}
		}

		&.stripe {
			th {
				&:nth-child(1) {
					width: 1%;
					min-width: 82px;
				}

				&:nth-child(2) {
					width: 1%;
					min-width: 51px;
					@include r.pad {
						min-width: 56px;
					}
				}

				&:nth-child(3) {
					width: 1%;
					min-width: 30px;
				}

				&:nth-child(4) {
					width: 1%;
					min-width: 169px;
				}

				&:nth-child(5) {
					width: 1%;
					min-width: 49px;
				}

				&:nth-child(6) {
					width: 1%;
					min-width: 40px;
				}

				&:nth-child(7) {
					width: 1%;
					min-width: 25px;
				}

				&:nth-child(8) {
					width: 1%;
					min-width: 43px;
					@include r.pad {
						min-width: 48px;
					}
				}

				&:nth-child(9) {
					width: 1%;
					min-width: 51px;
					@include r.pad {
						min-width: 56px;
					}
				}

				&:nth-child(10) {
					width: 1%;
					min-width: 90px;
				}

				// .p-race_detail_search_result .tb01.stripe th:nth-child(10) .
				&:nth-child(10) {
					width: 1%;
					min-width: 84px;
					@include r.pad {
						min-width: 88px;
					}
				}

				&:nth-child(11) {
					width: 1%;
					min-width: 151px;
				}

				&:nth-child(12) {
					width: 1%;
					min-width: 69px;
				}

				&:nth-child(13) {
					width: 1%;
					min-width: 53px;
				}

				&:nth-child(14) {
					width: 1%;
					min-width: 90px;
				}
			}

			td {
				&:nth-child(2) {
					text-align: center !important;
				}
			}

			.rirekiRacename,
			a {
				max-width: 160px;
				display: block;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: left;
				white-space: nowrap;
				img {
					margin: 0 auto;
				}
			}
		}
	}

	.bg-2line-btm {
		font-size: 1.3rem;

		.tx-bold {
			font-size: 1.8rem;
			line-height: 2.7rem;
			color: #333;
		}
	}

	.round-cnr {
		padding: 4px 8px;
		font-size: 1.3rem;
		border-radius: 8px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		@include r.sp {
			font-size: 1.2rem;
		}

		.bl-left,
		.bl-right {
			float: none;
		}

		.bl-right {
			margin-left: auto;
		}
	}

	.detail_search_note {
		@include r.sp {
			font-size: 1.3rem;
		}

		span {
			@include r.sp {
				font-size: 1.2rem;
			}
		}
	}

	@media print {
		.tb01 {
			border: 1.5px solid v.$color19;
			&.detail_search_cdn {
				margin-bottom: 16px;
			}
			&.w100pr {
				th,
				td {
					font-size: 1.1rem;
					border: 1.5px solid v.$color19;
				}
			}
			&.stripe th:nth-child(11) {
				min-width: 80px;
			}
		}
		#contents950 {
			max-width: 100%;
		}
		.nk23_u-btns {
			display: none;
		}
	}
}

@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-kis_grade {
	padding: 16px 0 40px;

	@include r.sp {
		padding: 16px 8px 40px;
	}

	&__note {
		margin-top: 16px;
		@include r.sp {
			border-top: 1px solid #221f1f;
			margin-top: 0;
			padding-top: 16px;
		}
	}
}

@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-other_release_calendar {
  display: flex;
  max-width: 960px;
  margin: auto;

  .tb-sell-fcl {
    border-radius: 8px 8px 0 0;

    tbody {
      th {
        &:nth-child(1) {
          min-width: 110px;
        }

        &:nth-child(2) {
          min-width: 335px;
        }

        &:nth-child(3) {
          min-width: 75px;
        }
      }

      td {
        padding: 4px 5px 5px;

        a {
          vertical-align: middle;
        }

        &:nth-child(4),
        &:nth-child(5) {
          a {
            img {
              width: 70px;
              height: 20px;
            }
          }
        }
      }
    }

    tr {
      &:first-child {
        th {
          &:first-child {
            border-top-left-radius: 8px;
          }

          &:last-child {
            border-top-right-radius: 8px;
          }

        }
      }

      &:last-child {

        td {
          &:first-child {
            border-bottom-left-radius: 8px;
          }

          &:last-child {
            border-bottom-right-radius: 8px;
          }
        }
      }
    }
  }

  &__vari1 {
    #contents730 {
      .tx-gray01 {
        margin-bottom: 24px;
      }
    }
  }
}
@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-program {
  &1 {
    padding: 20px 0 40px;

    @include r.sp {
      padding-top: 10px;
    }
    .nk23_c-title01 {
      margin-bottom: 20px;

      @include r.sp {
        margin-bottom: 10px;
      }
    }
    .nk23_c-text01 {
      padding-left: 1em;
      text-indent: -1em;
      font-size: 1.2rem;
      line-height: 1.9rem;
      margin-top: 8px;
    }
    .space {
      width: 100%;
    }
    .nk23_c-block01__col:first-child {
      .nk23_c-block01__list {
        @include r.pc {
          border-right: 1px solid #d2d2d2;
        }
      }
    }
    .nk23_c-block01__col:last-child {
      .nk23_c-block01__list__item {
        &:last-child {
          @include r.sp {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            border-bottom: none;
          }
        }
      }
    }
  }
}

@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-hikaku_cho {
	&__sc1 {
		padding: 16px 0 40px;

		@include r.sp {
			padding-top: 8px;
		}
	}

	.nk23_c-tab2 {
		margin-top: 16px;

		&__content {
			.nk23_c-tab2__content {
				padding-top: 0;
			}
		}

		&__subnavi__item {
			@include r.sp {
				margin-bottom: 16px;
			}
		}
	}

	.nk23_c-table13__icon {
		@include r.sp {
			top: 74px;
		}
	}

	.nk23_c-title01 {
		margin-bottom: 16px;
	}

	.nk23_c-filter2 {
		margin-bottom: 0;
		border-radius: 8px 8px 0 0;

		@include r.sp {
			margin-bottom: 8px;
			border-radius: 8px;
		}
	}

	.nk23_c-table13 {
		.text-left {
			&.no-paading {
				padding: 0;
			}
		}

		.thead-clone .nk23_c-table13__color {
			@include r.sp {
				gap: 4px;
			}
		}

		.thead-clone .nk23_c-table13__color__box {
			display: none !important;
		}

		.thead-clone th:nth-child(4) {
			@include r.sp {
				.nk23_c-table13__gtext {
					font-size: 1.2rem;

					span {
						font-size: 1.4rem;
					}
				}
			}
		}

		&__cells {
			padding: 0 !important;
			height: 0;

			.nk23_c-table13__cell {
				& + .nk23_c-table13__cell {
					border-top: 1px solid #C9C9CA;
				}
			}

			.nk23_c-table13__gtext {
				line-height: 1.7rem;
			}
		}

		&__cell {
			padding: 4px 4px;
			// height: 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;

			& + .nk23_c-table13__cell {
				border-top: 1px solid #C9C9CA;
			}
		}

		&__cell + &__cell {
			border-top: 1px solid #c9c9c9;
		}

		&__text3 {
			font-size: 1rem;
			line-height: 1.4rem;
		}
	}

}

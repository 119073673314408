@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-about_top {
  display: flex;
  max-width: 960px;
  margin: auto;

  #contents730 {
    width: 770px;
    background: #fff;
    padding: 15px 20px 20px !important;
    border-radius: 8px;
    font-size: 1.3rem;
    line-height: 2.1rem;
  }

  .qatop {
    h2 {
      margin-bottom: 15px;
    }

    h3 {
      font-size: 1.8rem;
      line-height: 1.6;
      color: v.$color0;
      font-weight: bold;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 1.3rem;
      line-height: 1.6;
      color: v.$color0;
      font-weight: bold;
      margin-bottom: 12px;

      &:nth-child(12) {
        margin-top: 14px;
      }
    }

    .faq {
      margin-bottom: 37px;

      li {
        width: calc(50% - 5px);

        img {
          width: 100%;
        }

        // .p-about_top .qatop .faq li a .
        a {
          transition: none;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }

    a.box {
      width: calc(50% - 5px);
      min-height: 144px;
      background: v.$colorw;
      border: 1px solid v.$color19;
      border-radius: 8px;
      box-shadow: 0 2px 0 v.$color19;
      padding: 19px 9px 20px;
      transition: none;

      &:hover {
        opacity: 0.6;
      }

      h4 {
        font-size: 1.8rem;
        line-height: 1.45;
        color: v.$color0;
        font-weight: bold;
        margin-bottom: 18px;
      }

      hr {
        height: 1px;
        background-color: v.$color19;
        border: none;
        margin: 5px 0;
        padding: 0;
      }

      p {
        font-size: 1.3rem !important;
        line-height: 1.6;
        color: v.$color0;
      }
    }

    div.box {
      width: calc(50% - 5px);

      ul {
        li {
          &:not(:last-child) {
            margin-bottom: 7px;
          }

          a {
            display: block;
            background: v.$colorw;
            border: 1px solid v.$color19;
            border-radius: 8px;
            box-shadow: 0 2px 0 v.$color19;
            padding: 5px 10px 3px;
            font-size: 1.3rem;
            line-height: 1.6;
            color: v.$color0;
            font-weight: bold;
            transition: none;

            &:hover {
              opacity: 0.6;
            }
          }
        }
      }
    }

    .link {
      margin: -1px 0 33px;
    }
  }

  .mB20 {
    margin-bottom: 20px;
  }

  .link {
    li {
      margin-bottom: -2px;

      a {
        font-size: 1.3rem;
        line-height: 1.2;
        color: v.$color-link;

        &:after {
          content: "";
          width: 12px;
          height: 12px;
          background: url("/info/shared/images/blank.gif") no-repeat center top 1px / 12px 11px;
          display: inline-block;
          margin-left: 6px;
        }

        @include r.hover {
          text-decoration: underline;
        }
      }
    }
  }

  dl {
    font-size: 1.3rem;
    line-height: 1.45;
    color: v.$color0;
    margin-left: 25px;

    dd {
      margin-left: 14px;

      a {
        &.link {
          font-size: 1.3rem;
          line-height: 1.2;
          color: #2461ee;
          margin: 0 !important;

          &:after {
            content: "";
            width: 12px;
            height: 12px;
            background: url(/info/shared/images/blank.gif) no-repeat center top 1px/12px 11px;
            display: inline-block;
            margin-left: 6px;
          }
          @include r.hover {
            text-decoration: underline;
          }
        }
      }
    }

    &:last-child {
      dt {
        margin-left: 14px;
      }
    }

    .link {
      margin: 0 0 0 14px !important;
    }
  }
}

@use "sass:map";
@use "variable" as v;
@use "path" as p;

@forward "../foundation/font";

$breakpoints: (
	"smless": (
		max-width: 640px
	),
	"smover": (
		min-width: 641px
	),
	"mdless": (
		max-width: 767px
	),
	"mdover": (
		min-width: 768px
	),
	"lgless": (
		max-width: 1024px
	),
	"lgover": (
		min-width: 1025px
	),
	"xlless": (
		max-width: 1280px
	),
	"xlover": (
		min-width: 1281px
	),
	"xxlless": (
		max-width: 1440px
	),
	"xxlover": (
		min-width: 1441px
	),
	"maxless": (
		max-width: 1920px
	),
	"maxover": (
		min-width: 1921px
	)
) !default;

@mixin mq($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media screen and #{inspect(map-get($breakpoints, $breakpoint))} {
			@content;
		}
	} @else {
		@error "指定されたブレイクポイントはありません -> #{map-keys($breakpoints)}";
	}
}

@mixin mincyo() {
	font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝",
		serif;
}

@mixin hover {
	@include mq(mdover) {
		&:hover {
			@content;
		}
	}
}

@mixin pc {
	@media screen and (min-width: 768px),
		screen and (min-width: 768px) and (orientation: portrait),
		screen and (orientation: landscape) {
		@content;
	}
}

@mixin pad {
	@include max-w(1024px) {
		@content;
	}
}

@mixin sp {
	@media screen and (max-width: 767px) and (orientation: portrait) {
		@content;
	}
}

@mixin min-w($breakpoint) {
	@media screen and (min-width: #{$breakpoint}) {
		@content;
	}
}

@mixin max-w($breakpoint) {
	@media screen and (max-width: #{$breakpoint}) {
		@content;
	}
}

@mixin w374 {
	@media screen and (max-width: 374px) {
		@content;
	}
}

@mixin clear {
	*zoom: 1;

	&:after {
		display: block;
		content: "";
		clear: both;
	}
}

@mixin notojp {
	font-family: "Noto Sans JP", sans-serif;
}

@mixin noto-mono {
	font-family: "Noto Sans Mono", "Noto Sans JP", sans-serif;
}

@mixin not-a {
	&:not(a) {
		transition: 0.3s ease-in-out;
		cursor: pointer;

		@include hover {
			opacity: 0.8;
		}
	}
}

@mixin shadow3 {
	box-shadow: 0 2px 4px rgba($color: #000, $alpha: 0.1);
}

@mixin shadow2 {
	@include sp {
		box-shadow: 0 1px 0 v.$color23;
	}
}

@mixin shadow1 {
	box-shadow: 0 1px 4px rgba($color: v.$color0, $alpha: 0.1);

	@include sp {
		box-shadow: 0 1px 4px rgba($color: #000, $alpha: 0.1);
	}
}

@mixin shadow($color) {
	box-shadow: 0 2px 0 $color;
}

@mixin shadow-disable($color) {
	box-shadow: 0 2px 0 rgba($color: $color, $alpha: 0.5);
}

@mixin border1 {
	@include sp {
		border: 1px solid v.$color23;
	}
}

@mixin border($color) {
	border: 1px solid $color;
}

@mixin border-disable($color) {
	border: 1px solid rgba($color: $color, $alpha: 0.5);
}

@mixin table($w, $firstw, $lastw, $wsp, $firstwsp, $lastwsp) {
	@include pc {
		width: #{$w}+ px;
	}

	th {
		width: calc((#{$firstw} / #{$w}) * 100%);

		@include sp {
			width: calc((#{$firstwsp} / #{$wsp}) * 100%);
		}

		&:last-child {
			width: calc((#{$lastw} / #{$w}) * 100%);

			@include sp {
				width: calc((#{$lastwsp} / #{$wsp}) * 100%);
			}
		}
	}
}

%btn {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: max-content;
	font-size: 1.4rem;
	line-height: 2rem;
	color: v.$color0;
	padding-left: 4px;
	padding-right: 4px;
	max-width: 100%;
	cursor: pointer;
	transition: none;

	&:not(.is-active) {
		@include hover {
			box-shadow: none;
			opacity: 1;
			position: relative;
			top: 2px;
		}
	}

	@include hover {
		opacity: 1 !important;
		text-decoration: none !important;
	}
}

@mixin btn01($colorborder, $borderactive, $bgcolor, $bgcoloractive) {
	@extend %btn;
	border-radius: 999px;
	width: 92px;
	min-height: 35px;
	@include border($colorborder);
	@include shadow($borderactive);
	background-color: $bgcolor;
	position: relative;

	@include pc {
		padding-bottom: 1px;
	}

	&.is-active {
		background-color: $bgcoloractive;
		border-color: $colorborder;
		color: #fff;
		box-shadow: none;
		font-weight: 700;
		top: 2px;
	}

	&.is-disable {
		@include shadow-disable($colorborder);
		@include border-disable($colorborder);
		pointer-events: none;

		.nk23_c-btn01__text {
			opacity: 0.25;
		}
	}

	&.is-small {
		width: 56px;
		min-height: 28px;
		font-size: 1.2rem;
		line-height: 1.8rem;
		background-color: #fff;

		&.is-style1 {
			&:not(.is-active) {
				background-color: #fff;
				color: v.$color0;
			}
		}

		&.is-active,
		&.is-hover {
			top: 2px;
			box-shadow: none;
		}

		&.is-active {
			background-color: $bgcoloractive;
		}
	}

	&.is-style2 {
		box-shadow: none;
		width: 74px;
		min-height: 36px;
		transition: 0.3s ease-in-out;

		@include hover {
			top: 0;
			color: rgba($color: v.$color0, $alpha: 0.25);
		}

		&.is-hover,
		&.is-active {
			top: 0;
		}

		&.is-hover {
			color: rgba($color: v.$color0, $alpha: 0.25);
		}

		&.is-active {
			border-color: v.$color0;
			color: v.$color0;
			background-color: #fff;
		}
	}

	&.is-style3 {
		max-width: 100%;
		width: auto;
		font-size: 1.3rem;
		min-height: 21px;
		padding: 0 25px;

		@include sp {
			max-width: 145px;
			min-height: 27px;
			font-size: 1.4rem;
			padding: 0 5px;
		}
	}
}

@mixin btn02($colorborder, $borderactive, $bgcolor, $bgcoloractive) {
	@extend %btn;
	border-radius: 999px;
	width: 134px;
	@include border($colorborder);
	@include shadow($colorborder);
	background-color: $bgcolor;
	font-weight: 700;
	position: relative;

	@include pc {
		min-height: 35px;
		padding-bottom: 1px;
	}

	&:not(.is-style1) {
		@include sp {
			padding: 4px 5px 2px;
			font-size: 1.2rem;
			line-height: 1.8rem;
			width: 87px;
			border-radius: 8px;
		}

		.nk23_c-btn02__text {
			@include sp {
				flex-direction: column;
				align-items: center;
			}
		}
	}

	&__text {
		display: flex;
		align-items: center;
		color: v.$color0;
		white-space: nowrap;

		@include sp {
			&.is-small {
				font-size: 1.2rem;
			}
		}

		&.is-color13 {
			color: v.$color13 !important;
		}
	}

	&__icon {
		width: 20px;

		@include pc {
			margin-right: 4px;
		}

		@include sp {
			width: 23px;
			margin-bottom: 1px;
		}

		&.is-active {
			display: none;
		}
	}

	&.is-color3 {
		.nk23_c-btn02 {
			&__text {
				color: v.$color20;
			}
		}
	}

	&.is-active {
		background-color: $bgcoloractive;
		border-color: $borderactive;
		box-shadow: none;
		font-weight: 700;
		top: 2px;

		.nk23_c-btn02 {
			&__icon {
				display: none;

				&.is-active {
					display: block;
				}
			}

			&__text {
				color: #fff;
			}
		}
	}

	&.is-hover {
		top: 2px;
		box-shadow: none;
	}

	&.is-disable {
		@include shadow-disable($colorborder);
		@include border-disable($colorborder);
		pointer-events: none;

		.nk23_c-btn02__text {
			opacity: 0.25;
			color: v.$color0;
		}

		&.is-color3 {
			.nk23_c-btn02__text {
				color: v.$color20;
			}
		}
	}

	&.is-style1 {
		width: 126px;
		min-height: 35px;

		@include pc {
			min-width: 102px;
		}

		@include sp {
			font-size: 1.2rem;
			width: 103px;
			min-height: 28px;
		}

		.nk23_c-btn02__icon {
			width: 18px;

			@include sp {
				margin-right: 4px;
			}
		}

		&.is-color1 {
			border: v.$color2;
			box-shadow: 0 2px 0 v.$color2;
			background-color: v.$urawa-color;
			color: #fff;

			&:after {
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				background-image: url(p.$path + "images/common/icon/icon_searchcup.svg");
				background-repeat: no-repeat;
				background-size: 46px auto;
				background-position: right 0 center;
				top: 0;
				left: -2px;
				opacity: 0.25;
			}

			.nk23_c-btn02__text {
				color: #fff;
			}

			&.no-bg {
				&:after {
					content: none;
				}
			}
		}
	}
}

@mixin btn03($color1, $color2) {
	@extend %btn;
	border-radius: 999px;
	width: 125px;
	min-height: 35px;
	@include border($color1);
	@include shadow($color1);
	background-color: $color2;
	font-weight: 700;
	color: #fff;
	font-size: 1.6rem;
	line-height: 1.5;
	position: relative;

	@include pc {
		padding-bottom: 1px;
	}

	&__text {
		position: relative;
		z-index: 1;
	}

	&:after {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		background-image: url(p.$path + "images/common/icon/icon_searchcup.svg");
		background-repeat: no-repeat;
		background-size: 46px auto;
		background-position: right 0 center;
		top: 0;
		left: -2px;
		opacity: 0.25;
	}

	&.is-icon01 {
		&:after {
			background-image: url(p.$path + "images/common/icon/icon_findrank-white.svg");
		}
	}

	&.is-style1 {
		width: 100%;
		max-width: 375px;

		&::after {
			display: none;
		}

		&.is-hover {
			box-shadow: none !important;
			top: auto;
		}

		.nk23_c-btn03__text {
			min-height: 100%;
			position: relative;
			display: inline-flex;
			align-items: center;
			padding: 0 30px;

			&:after {
				content: "";
				width: 40px;
				height: 100%;
				position: absolute;
				background-image: url(p.$path + "images/common/icon/icon_searchcup.svg");
				background-repeat: no-repeat;
				background-size: 40px auto;
				background-position: right 0 center;
				top: 0;
				right: 0;
				opacity: 0.25;
			}
		}
	}
}

@mixin btn05($colorborder, $borderactive, $bgcolor, $bgcoloractive) {
	@extend %btn;
	position: relative;
	padding-left: 16px;
	padding-right: 16px;
	min-height: 28px;
	font-size: 1.2rem;
	line-height: 1.8rem;
	border-radius: 999px;
	min-width: 56px;
	max-width: max-content;
	@include border($colorborder);
	@include shadow($borderactive);
	background-color: $bgcolor;
	padding-bottom: 1px;

	@include sp {
		width: auto;
		min-width: 56px;
	}

	&__text {
		display: flex;
		align-items: center;
	}

	&__icon {
		width: 11px;
		margin-left: 4px;

		&plus {
			top: -6px;
			left: -6px;
			position: absolute;

			img {
				width: 18px;

				&.is-active {
					display: none;
				}
			}
		}
	}

	&.is-hover {
		top: 2px;
		box-shadow: none;
	}

	&.is-active {
		background-color: $bgcoloractive;
		border-color: $colorborder;
		color: #fff;
		box-shadow: none;
		font-weight: 700;
		top: 2px;

		.nk23_c-btn05__iconplus {
			img {
				display: none;

				&.is-active {
					display: block;
				}
			}
		}
	}

	&.is-disable {
		@include shadow-disable($colorborder);
		@include border-disable($colorborder);
		pointer-events: none;

		.nk23_c-btn05__text {
			opacity: 0.25;
			color: v.$color0;
		}
	}
}

@mixin formcontrol {
	&__label {
		padding-left: 24px;
		position: relative;
		color: v.$color0;
		display: inline-block;
		cursor: pointer;
		font-size: 1.4rem;
		line-height: 2.2rem;
		min-height: 16px;

		@include sp {
			min-height: 24px;
			padding-left: 32px;
		}
	}

	input {
		top: 3px;
		width: 16px;
		height: 16px;
		border-radius: 4px;
		border: 1px solid v.$color0;
		position: absolute;
		left: 0;
		display: inline-block;
		background-color: #fff;
		pointer-events: none;
		transition: 0.3s ease-in-out;
		background-size: 10px auto;
		background-position: center top 4px;
		background-repeat: no-repeat;

		@include sp {
			width: 24px;
			height: 24px;
			top: -1px;
			background-size: 14px auto;
			background-position: center top 6px;
		}

		&:checked {
			background-color: v.$color0;
			background-image: url(p.$path + "images/common/icon/icon_checked-white.svg");
		}
	}

	&.is-style1 {
		.nk23_c-checkbox__label {
			min-height: 32px;
			padding-left: 32px;
		}

		input[type="checkbox"] {
			width: 32px;
			height: 32px;
			border: 1px solid v.$color32;
			background-size: 20px auto;
			background-position: center top 7px;

			// .nk23_c-checkbox.is-style1 input[type=checkbox]:checked .
			&:checked {
				background: v.$color32 url(p.$path + "images/common/icon/icon_checked-large.svg") no-repeat center top 7px /
					20px auto;
			}
		}
	}

	&.is-disable {
		pointer-events: none;

		input {
			opacity: 0.25;

			&[type="text"] {
				opacity: 1;
				pointer-events: none;
				background-color: v.$color19;
			}
		}
	}

	&.is-color1 {
		input {
			border-color: v.$color32;

			&:checked {
				background-color: v.$color32;
			}
		}
	}
}

@mixin swiper {
	.swiper {
		@include pc {
			overflow: visible;
		}

		&-wrapper {
			@include sp {
				width: max-content;
			}
		}

		&-slide {
			@include pc {
				height: auto;
			}
		}

		&-button {
			&-next,
			&-prev {
				z-index: 2;

				@include pc {
					display: none;
				}

				@include sp {
					margin-top: 0;
					width: 23px;
					height: 23px;
					border-radius: 50%;
					@include border1;
					@include shadow2;
					transform: translateY(-50%);
					position: absolute;
				}

				&:after {
					display: none;
				}
			}

			&-prev {
				@include sp {
					left: 4px;
					background: #fff url(p.$path + "images/common/icon/icon_left.svg") no-repeat center left 7px / 6px auto;
				}
			}

			&-next {
				@include sp {
					right: 4px;
					background: #fff url(p.$path + "images/common/icon/icon_right.svg") no-repeat center right 7px / 6px auto;
				}
			}
		}
	}
}

@mixin swiper1 {
	.swiper {
		overflow: visible;

		&-wrapper {
			width: max-content;
		}

		&-slide {
			height: auto;
		}

		&-button {
			&-next,
			&-prev {
				z-index: 2;
				width: 35px;
				height: 35px;
				margin-top: 0;
				border-radius: 50%;
				@include border(v.$color19);
				@include shadow(v.$color19);
				transform: translateY(-50%);
				position: absolute;

				@include sp {
					width: 23px;
					height: 23px;
				}

				&:after {
					display: none;
				}
			}

			&-prev {
				left: 16px;
				background: #fff url(p.$path + "images/common/icon/icon_left.svg") no-repeat center left 12px / 9px auto;

				@include sp {
					left: 4px;
					background: #fff url(p.$path + "images/common/icon/icon_left.svg") no-repeat center left 7px / 6px auto;
				}
			}

			&-next {
				right: 16px;
				background: #fff url(p.$path + "images/common/icon/icon_right.svg") no-repeat center right 12px / 9px auto;

				@include sp {
					right: 4px;
					background: #fff url(p.$path + "images/common/icon/icon_right.svg") no-repeat center right 7px / 6px auto;
				}
			}
		}
	}
}

@mixin hascircle {
	position: relative;

	&:after {
		content: "";
		width: 29px;
		height: 29px;
		border-radius: 50%;
		border: 2px solid v.$color54;
		z-index: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

@mixin tablestyle {
	position: relative;

	[class^="nk23_l-container"] {
		@include sp {
			padding: 0;
		}
	}

	&__wrap {
		position: relative;
	}

	&__inner {
		padding-bottom: 16px;
		font-size: 1.4rem;
		line-height: 2.3rem;
		width: 100%;
		overflow-y: hidden;
		overflow-x: auto;

		@include sp {
			padding-bottom: 8px;
			font-size: 1.3rem;
		}
	}

	&__icon {
		@keyframes hideop {
			0% {
				opacity: 1;
			}

			100% {
				opacity: 0;
				display:none;
			}
		}

		@include sp {
			top: calc(50% - 4px);
			position: absolute;
			transform: translate(-50%, -50%);
			left: 50%;
			z-index: 2;
			pointer-events: none;
			//transition:
			//	opacity 0.3s ease-in-out,
			//	visibility 0.3s ease-in-out;
			opacity: 0;
			//visibility: hidden;
			display: none;
		}

		&.is-hide {
			animation: hideop 0.3s linear 0s;
			animation-fill-mode: forwards;
		}

		&.is-show {
			opacity: 1;
			//visibility: visible;
			display: block;
		}
	}

	&__grtop {
		background-color: #fff;
		padding: 12px 16px;

		@include pc {
			border-radius: 8px 8px 0 0;
		}

		@include sp {
			padding: 8px 16px;
		}

		.nk23_c-table01__title {
			padding: 0;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			&:not(:only-child) {
				margin-bottom: 8px;
			}

			.is-small {
				margin-left: 8px;
				font-size: 1.2rem;
				line-height: 1.9rem;
				font-weight: 400;
			}
		}

		& + .nk23_c-table01__wrap {
			.nk23_c-table01__table {
				@include pc {
					border-radius: 0 0 8px 8px;
				}
			}
		}

		.nk23_c-text01 {
			margin-bottom: 0 !important;
		}
	}

	&__table {
		overflow: hidden;

		@include pc {
			border-radius: 8px;
		}

		@include sp {
			min-width: 437px;
		}

		&.is-full {
			width: 100%;
		}
	}

	td.nk23_u-left {
		text-align: left !important;

		&:nth-child(1),
		&:nth-child(2) {
			text-align: left !important;
		}
	}

	[class^="nk23_u-bg-color"] {
		color: #fff;
	}

	[class^="nk23_u-bg-color54"] {
		color: #e63434;
	}

	&__text {
		&.is-large {
			font-weight: 700;
			font-size: 1.6rem;

			@include pc {
				padding: 10px 0;
			}
		}
	}

	.scroll {
		&bar-thumb {
			background-color: v.$color19;
			transition: none !important;

			@include sp {
				height: 4px;
			}
		}

		&bar-track {
			border-radius: 20px;

			&-x {
				opacity: 1;
				background-color: #fff;

				@include sp {
					height: 4px;
					overflow: hidden;
				}
			}
		}
	}

	// Custom table has datatable and scrollbar
	.datatable-scroll {
		padding-bottom: 0;
		overflow-y: hidden;
		overflow-x: auto;

		.dataTables_wrapper {
			bottom: 0;
			right: 0;
			position: absolute;

			@include sp {
				margin-right: 8px;
			}
		}

		// .nk23_c-table01 .datatable-scroll .scrollbar-track-x .
		.scrollbar-track-x {
			bottom: 38px;

			@include sp {
				bottom: 34px;
			}
		}
	}

	tr {
		&.is-text-right {
			td {
				text-align: right !important;
			}
		}
	}

	&.is-style6 {
		thead {
			font-size: 1.3rem;
			line-height: 2.1rem;

			@include w374 {
				font-size: 1.2rem;
			}
		}

		// .nk23_c-table01.is-style6 .nk23_c-table01__table .
		.nk23_c-table01__table {
			@include sp {
				min-width: 100%;
			}
		}
	}

	&.is-style7 {
		.nk23_c-table01__table {
			table-layout: unset;
		}

		thead {
			// .nk23_c-table01.is-style7 thead td .
			td {
				background-color: v.$color34;
				color: v.$color0;
				text-align: center;
			}

			th {
				&:first-child {
					width: 0.1%;
					min-width: 208px;
					border-left: none;

					@include sp {
						width: 0.001%;
						min-width: 89px;
					}
				}

				&:nth-child(2) {
					@include sp {
						width: 0.001%;
						min-width: 77px;
					}
				}

				// .nk23_c-table01.is-style7 thead th:nth-child(3) .
				&:nth-child(3) {
					width: 50%;
				}

				&:last-child {
					width: 0.1%;
					min-width: 127px;
					border-right: none;

					@include sp {
						width: 0.001%;
						min-width: 53px;
					}
				}

				&:nth-last-child(2),
				&:nth-last-child(3) {
					@include pc {
						width: 0.1%;
						min-width: 104px;
					}
				}

				// .nk23_c-table01.is-style7 thead th:nth-last-child(2) .
				&:nth-last-child(2) {
					@include sp {
						width: 0.1%;
						min-width: 53px;
						border-right: none;
					}
				}
			}
		}

		tbody {
			td {
				text-align: right !important;

				&:first-child {
					text-align: left !important;
				}
			}
		}

		tr {
			&.is-active {
				position: relative;

				td {
					&:first-child {
						// .nk23_c-table01.is-style7 tis-active td:first-child:after .
						&:after {
							// content: '';
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							pointer-events: none;
							border: 2px solid v.$color0;
						}
					}
				}
			}

			&:last-child {
				&.is-active {
					td {
						&:first-child {
							&:after {
								@include pc {
									border-radius: 0 0 8px 8px;
								}
							}
						}
					}
				}

				.is-after {
					@include pc {
						border-radius: 0 0 8px 8px;
					}
				}
			}
		}

		.nk23_c-btn01 {
			width: 100%;
			min-height: 28px;
			margin-bottom: 2px;
			padding-top: 0;
			font-size: 1.2rem;

			&.is-active {
				top: 1px;
				color: #fff;
				background-color: v.$color0;
				border-color: v.$color0;
				font-weight: 700;
			}
		}

		// .nk23_c-table01.is-style7 .is-after .
		.is-after {
			position: absolute;
			top: -10px;
			left: -8px;
			pointer-events: none;
			z-index: 2;
			border: 2px solid v.$color0;
			display: block;

			@include sp {
				left: -4px;
			}
		}

		.has-after {
			position: relative;
		}
	}

	&.is-style8 {
		[class^="nk23_u-bg-"] {
			color: inherit;
		}

		.nk23_c-table01__table {
			table-layout: unset;
		}

		thead {
			th {
				&:first-child {
					width: 0.1%;
					min-width: 66px;
					border-left: none;
				}

				&:nth-child(2),
				&:nth-child(11),
				&:nth-last-child(2) {
					width: 0.1%;
					min-width: 51px;
				}

				&:nth-child(3) {
					width: 0.1%;
					min-width: 25px;
				}

				&:nth-child(4) {
					width: 0.1%;
					min-width: 61px;
				}

				&:nth-child(10) {
					width: 0.1%;
					min-width: 47px;
				}

				&:nth-child(12) {
					width: 0.1%;
					min-width: 43px;
				}

				&:nth-child(13) {
					width: 0.1%;
					min-width: 39px;
				}

				// .nk23_c-table01.is-style8 thead th:nth-child(8) .
				&:nth-child(8) {
					width: 50%;

					@include sp {
						min-width: 302px;
					}
				}

				&:last-child {
					width: 0.1%;
					min-width: 65px;
					border-right: none;
				}

				&:nth-child(5),
				&:nth-child(6),
				&:nth-child(7),
				&:nth-child(9),
				&:nth-last-child(3),
				&:nth-last-child(4) {
					width: 0.1%;
					min-width: 37px;
				}
			}
		}

		th {
			padding: 4px 2px;
		}

		tbody {
			td {
				padding: 4px;
			}
		}

		td {
			text-align: center !important;
			line-height: 2;

			&.is-text-left {
				text-align: left !important;
			}

			&.is-text-right {
				text-align: right !important;
			}

			&[class^="nk23_u-bg-"] {
				color: #fff;
			}

			&.nk23_u-bg-white {
				color: v.$color0;
			}

			a {
				line-height: 2rem;
			}
		}
	}

	&.is-style9 {
		.nk23_c-table01__table {
			min-width: 960px;

			@include sp {
				min-width: 753px;
			}
		}

		th,
		td {
			&:not([class*="text-"]) {
				text-align: center;
			}

			&.is-text-left {
				text-align: left;
			}

			&.is-text-right {
				text-align: right;
			}
		}

		thead {
			th {
				&:first-child {
					width: calc((300 / 960) * 100%);

					@include sp {
						width: calc((178 / 960) * 100%);
					}
				}

				&:nth-child(2) {
					width: calc((140 / 960) * 100%);

					@include sp {
						width: calc((120 / 960) * 100%);
					}
				}

				&:nth-child(3) {
					width: calc((67 / 960) * 100%);

					@include sp {
						width: calc((48 / 960) * 100%);
					}
				}

				&:nth-child(4) {
					width: calc((57 / 960) * 100%);

					@include sp {
						width: calc((38 / 960) * 100%);
					}
				}

				&:nth-child(5) {
					width: calc((89 / 960) * 100%);

					@include sp {
						width: calc((65 / 960) * 100%);
					}
				}

				&:nth-child(6) {
					width: calc((79 / 960) * 100%);

					@include sp {
						width: calc((55 / 960) * 100%);
					}
				}

				&:nth-child(7) {
					width: calc((114 / 960) * 100%);

					@include sp {
						width: calc((75 / 960) * 100%);
					}
				}

				&:last-child {
					width: 12%;

					@include sp {
						width: 7.8%;
					}
				}
			}
		}

		tbody {
			td {
				&:first-child {
					text-align: left !important;
				}

				&[class^="nk23_u-bg-"] {
					color: v.$color0 !important;

					strong {
						color: #fff !important;
					}
				}

				&:nth-child(6),
				&:nth-child(7) {
					padding: 4px 14px 4px 8px;

					@include sp {
						padding: 4px;
					}
				}
			}
		}

		.nk23_c-btn01 {
			width: 111px;
			margin: auto auto 2px;
		}
	}

	&.is-style10 {
		.nk23_c-table01__table {
			table-layout: unset;
			min-width: 960px;

			@include sp {
				min-width: 410px;
			}
		}

		th,
		td {
			&.is-text-ver {
				writing-mode: vertical-rl;
				text-align: center;
			}
		}

		th {
			width: 23%;

			&:nth-child(1) {
				border-right: none;
				width: 0.1%;
				min-width: 30px;
			}

			&:nth-child(2) {
				border-left: none;
				width: 0.1%;
				min-width: 95px;

				@include sp {
					min-width: 82px;
				}
			}

			&:nth-last-child(2) {
				@include sp {
					width: 23%;
					min-width: 91px;
				}
			}

			&:last-child {
				@include sp {
					width: 0.01%;
					min-width: 35px;
				}
			}
		}

		&.is-text-ver {
			writing-mode: vertical-rl;
			text-align: center;
		}

		td {
			text-align: right !important;

			@include pc {
				padding-top: 8px;
				padding-bottom: 8px;
			}

			&:nth-child(1),
			&:nth-child(2),
			&:last-child {
				text-align: right !important;

				&.is-center {
					text-align: center !important;
				}
			}

			&.is-center {
				text-align: center !important;
			}

			&[class^="nk23_u-bg-"] {
				color: v.$color0;

				@include pc {
					font-weight: 700;
				}
			}

			&:first-child {
				text-align: center !important;
			}
		}

		.is-num {
			min-width: 25.5px;
		}
	}

	&.is-style11 {
		.nk23_c-table01__table {
			table-layout: unset;
			min-width: 960px;

			@include sp {
				min-width: 410px;
				width: 100%;
			}
		}

		th {
			width: 20%;

			@include sp {
				min-width: 84px;
			}

			&:first-child {
				@include sp {
					min-width: 127px;
				}
			}

			&:last-child {
				@include sp {
					width: 1%;
					min-width: 35px;
				}
			}

			&:nth-last-child(2) {
				@include sp {
					min-width: 92px;
				}
			}
		}

		td {
			text-align: right !important;

			@include pc {
				padding-top: 8px;
				padding-bottom: 8px;
			}

			&:nth-child(2),
			&:last-child {
				text-align: right !important;
			}

			&:first-child {
				text-align: left !important;
			}
		}

		.is-num {
			min-width: 12px;
		}
	}

	&.is-style13 {
		.nk23_c-table01 {
			&__table {
				min-width: 960px;

				@include sp {
					min-width: 765px;
					border-radius: 8px;
				}
			}
		}

		thead {
			th {
				width: calc(100% / 9);
			}
		}

		th,
		td {
			text-align: center !important;
		}

		td {
			@include pc {
				padding: 8px;
			}
		}
	}

	&.is-style14 {
		.nk23_c-table01 {
			&__table {
				min-width: 960px;

				@include sp {
					min-width: 682px;
				}
			}
		}

		thead {
			th {
				padding-left: 4px;
				padding-right: 4px;

				// .nk23_c-table01.is-style14 thead th:nth-child(1) .
				&:nth-child(1) {
					width: 1%;
					min-width: 319px;

					@include sp {
						min-width: 245px;
					}
				}

				&:nth-child(2) {
					width: 1%;
					min-width: 53px;

					@include sp {
						min-width: 46px;
					}
				}

				&:nth-child(3) {
					width: 1%;
					min-width: 45px;

					@include sp {
						min-width: 38px;
					}
				}

				// .nk23_c-table01.is-style14 thead th:nth-child(4) .
				&:nth-child(4) {
					width: 1%;
					min-width: 78px;

					@include sp {
						min-width: 65px;
					}
				}

				&:nth-child(5) {
					width: 1%;
					min-width: 64px;

					@include sp {
						min-width: 54px;
					}
				}

				&:nth-child(6) {
					width: 1%;
					min-width: 80px;

					@include sp {
						min-width: 68px;
					}
				}

				// .nk23_c-table01.is-style14 thead th:nth-child(7) .
				&:nth-child(7) {
					width: 1%;
					min-width: 92px;

					@include sp {
						min-width: 75px;
					}
				}

				&:nth-child(8) {
					width: 1%;
					min-width: 108px;

					@include sp {
						min-width: 76px;
					}
				}

				&:nth-child(9) {
					width: 1%;
					min-width: 117px;
				}
			}
		}

		tbody {
			td {
				text-align: center;

				&:nth-child(1) {
					min-width: 62px;
					width: 1%;
					text-align: left !important;
				}

				&:nth-child(2) {
					text-align: left !important;
				}

				&.border-right-0 {
					border-right: none;
				}

				&.border-left-0 {
					border-left: none;
				}
			}
		}

		.nk23_c-btn01 {
			width: 100%;
			margin-bottom: 2px;
		}
	}

	&.is-style15 {
		.nk23_c-table01 {
			&__table {
				min-width: 1200px;

				@include sp {
					min-width: 856px;
				}
			}
		}

		.nk23_u-text-vertical {
			letter-spacing: 5px;
			vertical-align: middle;
			padding-top: 5px;
		}

		th,
		td {
			padding: 8px 4px;
		}

		thead {
			th {
				&:nth-child(1) {
					width: 1%;
					min-width: 20px;

					@include sp {
						min-width: 20px;
					}
				}

				&:nth-child(2) {
					width: 1%;
					min-width: 63px;

					@include sp {
						min-width: 63px;
					}
				}

				&:nth-child(3) {
					width: 1%;
					min-width: 38px;

					@include sp {
						min-width: 38px;
					}
				}

				&:nth-child(4) {
					width: 1%;
					min-width: 20px;

					@include sp {
						min-width: 20px;
					}
				}

				// .nk23_c-table01.is-style15 thead th:nth-child(5) .
				&:nth-child(5) {
					width: 1%;
					min-width: 175px;

					@include sp {
						min-width: 175px;
					}
				}

				&:nth-child(6) {
					width: 1%;
					min-width: 38px;

					@include sp {
						min-width: 38px;
					}
				}

				// .nk23_c-table01.is-style15 thead th:nth-child(7) .
				&:nth-child(7) {
					width: 1%;
					min-width: 32px;

					@include sp {
						min-width: 32px;
					}
				}

				// .nk23_c-table01.is-style15 thead th:nth-child(8) .
				&:nth-child(8) {
					width: 1%;
					min-width: 176px;

					@include sp {
						min-width: 176px;
					}
				}

				&:nth-child(9) {
					width: 1%;
					min-width: 176px;

					@include sp {
						min-width: 176px;
					}
				}

				// .nk23_c-table01.is-style15 thead th:nth-child(10) .
				&:nth-child(10) {
					width: 1%;
					min-width: 52px;

					@include sp {
						min-width: 52px;
					}
				}

				&:nth-child(11) {
					width: 1%;
					min-width: 116px;

					@include sp {
						min-width: 116px;
					}
				}

				&:nth-child(12) {
					width: 1%;
					min-width: 113px;

					@include sp {
						min-width: 113px;
					}
				}

				&:nth-child(13) {
					width: 1%;
					min-width: 113px;

					@include sp {
						min-width: 113px;
					}
				}

				&:nth-child(14) {
					width: 1%;
					min-width: 50px;

					@include sp {
						min-width: 50px;
					}
				}
			}
		}

		tbody {
			td {
				text-align: left !important;

				&.is-center {
					text-align: center !important;
				}

				&.is-right {
					text-align: right !important;
				}

				&.is-left {
					text-align: left !important;
				}

				&:nth-child(1) {
					text-align: center !important;
				}
			}
		}
	}

	.is-num {
		display: inline-block;

		@include pc {
			margin-left: 8px;
		}
	}
}

@mixin tablestyle02 {
	font-size: 1.4rem;
	line-height: 1.36;

	@include sp {
		min-width: 100%;
	}

	&__table {
		width: 100%;
		background-color: #fff;
	}

	&__wrap {
		@include pc {
			border-radius: 0 0 8px 8px;
			overflow: hidden;
		}
	}

	th,
	td {
		border: 1px solid v.$color23;
		padding: 4px 8px;
		text-align: center;

		@include sp {
			padding: 4px;
		}

		&.is-right {
			text-align: right;
		}

		&.is-left {
			text-align: left;
		}

		&.is-left-sp {
			@include pc {
				text-align: left;
			}
		}

		&.border-x-none {
			border-right: none;
			border-left: none;
		}

		&:first-child {
			border-left: none;
		}

		&:last-child {
			border-right: none;
		}
	}

	th {
		@include sp {
			padding: 4px 2px;
		}
	}

	&__title {
		padding: 12px 16px;
		background-color: #fff;
		font-size: 1.6rem;
		line-height: 1.5;
		font-weight: 700;
		width: 100%;

		@include pc {
			border-radius: 8px 8px 0 0;
		}

		@include sp {
			padding: 7.5px 16px;
		}
	}

	[class^="nk23_u-bg-color"] {
		color: #fff;
	}
}

@mixin tablestyle3 {
	font-size: 1.4rem;
	line-height: 2.1rem;

	&__table {
		border-collapse: collapse;
		width: 100%;

		@include pc {
			border-radius: 8px;
			overflow: hidden;
		}
	}

	thead {
		background-color: v.$color29;
		font-weight: 700;
		color: #fff;

		th {
			border-top: none;

			&:first-child {
				border-left: none;
			}

			&:last-child {
				border-right: none;
			}
		}
	}

	tbody {
		background-color: #fff;

		tr {
			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}

		td {
			&:first-child {
				border-left: none;
			}

			&:last-child {
				border-right: none;
			}
		}
	}

	th,
	td {
		padding: 4px 4px;
		text-align: center;
		vertical-align: middle;
		border: 1px solid v.$color19;

		@include sp {
			padding: 4px;
		}

		&[class^="nk23_u-bg-"] {
			color: #fff;
		}
	}

	strong,
	b {
		font-weight: 700;
	}

	.is-text-left {
		text-align: left !important;
	}
}

@mixin tablestyle4 {
	&__table {
		table-layout: unset;
		border-collapse: separate;
		border-spacing: 0;
	}

	&__inner {
		width: 100%;
		overflow-y: hidden;
		overflow-x: auto;
	}

	@include sp {
		overflow: hidden;
		position: relative;
	}

	th,
	td {
		padding: 4px 8px;
		text-align: center;
		vertical-align: middle;
		font-size: 1.4rem;
		line-height: 2.3rem;

		@include pc {
			padding: 4px;
		}

		@include sp {
			padding: 4px;
		}

		&:last-child {
			border-right: none;
		}

		&:first-child {
			border-left: none;
		}

		&[class^="nk23_u-bg-"] {
			color: #fff;
		}
	}

	th {
		border-top: 1px solid v.$color19;
		border-right: 1px solid v.$color19;
		background-color: #fff;

		&:first-child {
			@include sp {
				position: sticky;
				z-index: 1;
				left: 0;
			}
		}

		&.is-active {
			border-top-color: v.$color0;
			border-right: 2px solid v.$color0;
			border-left: 2px solid v.$color0;

			& ~ th:first-child {
				border-right: none;
			}
		}
	}

	td {
		border-top: 1px solid v.$color19;
		border-right: 1px solid v.$color19;

		&:not([class^="nk23_u-bg-"]) {
			background-color: #fff;
		}

		&:first-child {
			@include sp {
				position: sticky;
				z-index: 1;
				left: 0;
			}
		}

		&.is-active {
			border-left: 2px solid v.$color0;
			border-right: 2px solid v.$color0;

			& ~ td:first-child {
				border-right: none;
			}
		}
	}

	tr {
		&:first-child {
			td {
				&.is-active {
					border-top-color: v.$color0;
				}
			}
		}

		&:last-child {
			td {
				&.is-active {
					border-bottom: 2px solid v.$color0;
				}

				&:first-child {
					@include pc {
						border-bottom-left-radius: 8px;
					}
				}

				&:last-child {
					@include pc {
						border-bottom-right-radius: 8px;
					}
				}
			}
		}
	}

	&__top {
		background-color: #fff;
		padding: 16px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		@include pc {
			border-radius: 8px 8px 0 0;
		}

		@include sp {
			padding: 8px 8px 8px 16px;
		}
	}

	.custom-scrollbar {
		margin-left: auto;
		margin-right: auto;
		width: calc(100% - 16px);

		&-x {
			background-color: v.$color19;
		}
	}
}

@mixin reset {
	font-family: "Noto Sans JP", sans-serif;
	font-size: 62.5%;
	-webkit-text-size-adjust: 100%;
	overscroll-behavior: none;
	@media screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
		font-size: 55%;
		.p-pc-only {
			font-size: 62.5%;
		}
	}

	&.smartphone {
		@media screen and (orientation: landscape) {
			overflow-x: hidden;
		}
	}
	&.p-pc-only {
		font-size: 62.5%;
	}

	* {
		padding: 0;
		margin: 0;
	}

	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	@include pc {
		min-width: 1248px;
	}

	li {
		list-style-type: none;
	}

	img {
		margin: 0;
		padding: 0;
		border: 0;
		max-width: 100%;
		height: auto;
		width: auto;
		vertical-align: middle;
	}

	a {
		display: inline-block;
		transition: 0.3s ease-in-out;
		text-decoration: none;
		color: inherit;
		font-size: inherit;
		line-height: inherit;
		box-sizing: border-box;
	}

	a:hover {
		opacity: 1;
		filter: alpha(opacity=100);
		-ms-filter: "alpha(opacity=100)";
	}

	button,
	input,
	select,
	textarea {
		padding: 0;
		border: 0;
	}

	button:not(:disabled):not([aria-disabled="true"]) {
		cursor: pointer;
	}

	select,
	input,
	textarea {
		box-sizing: border-box;
		border: none;
		background-color: #fff;
		font-size: inherit;
		line-height: inherit;
		color: inherit;
		margin: 0;
		padding: 0;
		box-shadow: none;
		outline: none;
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;
	}

	button {
		cursor: pointer;
	}

	input {
		width: 100%;
		color: #221f1f;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&[type="number"] {
			-moz-appearance: textfield;
		}

		&::-webkit-input-placeholder {
			color: #c9c9c9;
		}

		&:-ms-input-placeholder {
			color: #c9c9c9;
		}

		&::placeholder {
			color: #c9c9c9;
		}

		&::-ms-clear {
			display: none;
		}

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 100px white inset !important;
		}

		&:hover,
		&:focus {
			border: none;
			box-shadow: none;
			outline: none;
		}
	}

	input[type="date"]::-webkit-inner-spin-button,
	input[type="date"]::-webkit-calendar-picker-indicator {
		display: none;
		-webkit-appearance: none;
	}

	button,
	input,
	optgroup,
	select,
	textarea {
		font-family: inherit;
		/* 1 */
		font-size: 100%;
		/* 1 */
		line-height: inherit;
		/* 1 */
		color: inherit;
		/* 1 */
		margin: 0;
		/* 2 */
		padding: 0;
		/* 3 */
	}

	button,
	[type="button"],
	[type="reset"],
	[type="submit"] {
		-webkit-appearance: button;
		/* 1 */
		background-color: transparent;
		/* 2 */
		background-image: none;
		/* 2 */
	}

	input::-webkit-input-placeholder,
	textarea::-webkit-input-placeholder {
		opacity: 1;
		/* 1 */
		color: #9ca3af;
		/* 2 */
	}

	input:-ms-input-placeholder,
	textarea:-ms-input-placeholder {
		opacity: 1;
		/* 1 */
		color: #9ca3af;
		/* 2 */
	}

	input::placeholder,
	textarea::placeholder {
		opacity: 1;
		/* 1 */
		color: #9ca3af;
		/* 2 */
	}

	.pc {
		@media screen and (max-width: 767px) and (orientation: portrait) {
			display: none !important;
		}
	}

	.sp {
		@media screen and (min-width: 768px),
			screen and (min-width: 768px) and (orientation: portrait),
			screen and (orientation: landscape) {
			display: none !important;
		}
	}

	.display_pc {
		@media screen and (max-width: 767px) and (orientation: portrait) {
			display: none !important;
		}
	}

	.display_sp {
		@media screen and (min-width: 768px),
			screen and (min-width: 768px) and (orientation: portrait),
			screen and (orientation: landscape) {
			display: none !important;
		}
	}

	%container {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding: 0 16px;

		@include sp {
			padding: 0 8px;

			&.is-sp-full {
				padding: 0;
			}
		}

		[class^="nk23_l-container"] {
			padding: 0;
		}
	}

	.nk23_l-container {
		max-width: 992px;
		@extend %container;
	}

	.nk23_l-container1 {
		max-width: 1232px;
		@extend %container;
	}

	.nk23_l-container2 {
		max-width: 672px;
		@extend %container;

		@include sp {
			max-width: 100%;
		}
	}
}

@mixin easeInOutCubic {
	transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
}

@mixin easeInOutQuart {
	transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
}

html.p-pc-only {
	font-size: 62.5%;
}

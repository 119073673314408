@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-kanriba_list {
  padding: 16px 0 40px;

  #race-header550-2 {
    @include r.sp {
      margin: 8px 8px 16px;
    }
  }

  .tl-main {
    img {
      @include r.sp {
        max-width: 220px;
      }
    }
  }

  h3.tl-h3 {
    padding: 8px 16px;
  }

  &.nk23_c-kba_list p:nth-child(4) {
    margin-bottom: 11px;

    @include r.sp {
      padding: 0 8px 0;
    }
  }

  .tx-small {
    @include r.sp {
      padding: 0 8px;
    }
  }

  .tb01 {
    margin-bottom: 16px;
    overflow: hidden;

    th {
      @include r.sp {
        white-space: nowrap;
      }
      @include r.pc {
        &:first-child {
          width: 48px;
        }
        &:last-child {
          width: 80px;
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: 50px;
        }
        &:nth-child(5),
        &:nth-child(6) {
          width: 55px;
        }
      }
    }

    td {
      padding: 8px;
      @include r.sp {
        padding: 4px;
      }

      &:nth-child(5),
      &:nth-child(6) {
        text-align: right;
      }
      &:not(:nth-child(4)) {
        word-break: keep-all;
      }
    }
  }

  .nk23_c-text02 {
    margin-top: 11px;
  }
}

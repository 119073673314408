@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-others {
	margin: 0 auto;
	padding: 16px 16px 40px !important;
	display: flex;
	max-width: 992px;
	box-sizing: border-box;

	#contents730 {
		width: 770px;
		padding: 15px 20px 20px !important;
		background-color: #fff;
		border-radius: 8px;
		font-size: 1.3rem;
		line-height: 2.1rem;
	}

	// .p-others .box-Q .
	.box-Q {
		margin-left: 20px;
		width: calc(100% - 40px);
	}

	.link-pagetop {
		margin-top: 12px;
	}

	.box-A {
		padding-left: 20px;
		padding-right: 20px;

		table {
			margin-left: 54px;
			margin-bottom: 5px;
			width: calc(100% - 54px);
		}

		td {
			img {
				margin-bottom: 18px;
			}
		}

		tr {
			&:last-child {
				td {
					img {
						margin-bottom: 0;
					}
				}
			}
		}

		.val-top {
			// .p-others .box-A .val-top .mL10 .
			.mL10 {
				margin-bottom: 18px;
			}
		}
	}

	#ls-qa-other {
		margin: 36px 0 0 20px;
	}

	#qa01 {
		margin-bottom: 33px;
		display: block;
		height: 0;
	}

	.tb-tajyo {
		td {
			font-size: 1.3rem;
			line-height: 1.8rem;

			&.val-top {
				vertical-align: top;
			}

			&:first-child {
				width: 220px;

				img {
					min-width: 220px;
				}
			}

			&:last-child {
				padding-left: 18px;
			}
		}
	}
}

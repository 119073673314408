@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-telephone_flow {
  display: flex;
  max-width: 960px;
  margin: auto;

  .tab-navigation.telephone {
    margin: 16px -4px 23px;
  }

  .contents750 {
    background: #fff;
    padding: 20px 10px 30px;
    border-radius: 8px;
    min-width: 770px;

    .title-spat4-02 {
      padding-left: 10px !important;
      margin: 0 0 0 -10px !important;
      &:before {
        background: none;
        display: none;
      }
      &.title_about {
        margin: 20px 0 !important;

        &:first-child {
          margin-top: 0 !important;
        }
      }
    }

    > p {
      font-size: 1.3rem;
      &.tx-small {
        font-size: 1.1rem;
      }
      &.m-16 {
        margin: 15px 0 11px;
      }
      img {
        margin: 0 auto;
      }
    }
    .box-freedial {
      margin-bottom: 20px !important;
      margin-top: 11px !important;
      p {
        &:first-child {
          margin-bottom: 13px;
          img {
            margin: auto;
            max-width: 288px;
          }
        }
      }
    }

    .box-freedial + p a {
      border-radius: 8px;
      overflow: hidden;
      width: max-content;
    }
  }

  .title-spat4-01 {
    color: #fff;
    padding: 16px;
    margin-bottom: 16px;
  }
}

@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-uma_detail_search_result {
	&.nk23-c_uma_detail_search_result {
		.detail_search {
			&_text {
				@include r.sp {
					margin-top: 0;
					font-weight: 700;
				}
			}

			&_content {
				@include r.sp {
					margin-bottom: 8px;
					border-radius: 0 0 8px 8px;
					background-color: #fff;
				}
			}
		}

		#contents {
			max-width: 960px;
		}

		.tb01 {
			&.detail_search_result {
				@include r.sp {
					border-collapse: separate;
					border-spacing: 0;
				}

				tr {
					&:last-child {
						td {
							@include r.sp {
								border-bottom: 1px solid v.$color19;
							}
						}
					}
				}

				th,
				td {
					@include r.sp {
						border-right: none;
					}
				}

				th {
					&:nth-child(1) {
						@include r.sp {
							width: 1%;
							min-width: 163px;
						}
					}

					&:nth-child(2),
					&:nth-child(3) {
						@include r.sp {
							width: 1%;
							min-width: 48px;
						}
					}
				}

				td {
					@include r.sp {
						border-right: none;
						border-bottom: none;
					}

					&.is-small {
						@include r.sp {
							border-top: none;
							width: 0.1%;
							min-width: 10px;

							@supports (-webkit-overflow-scrolling: touch) {
								width: 20px;
								min-width: unset;
								/* CSS specific to iOS devices */
							}
						}
					}
				}
			}
		}
	}

	.detail_search_result_title.title02 {
		@include r.sp {
			margin-top: 0;
			border-bottom: 1px solid v.$color19 !important;
		}
	}

	.scroll_div {
		@include r.sp {
			position: relative;
		}

		&__table {
			@include r.sp {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				z-index: 1;
			}
		}

		&__wrap {
			@include r.sp {
				max-height: 324px;
				overflow-x: hidden;
				overflow-y: auto;
			}
		}
	}
}

@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-blood {

  &__sc1 {
    padding: 16px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }

    .nk23_c-tab2 {
      margin-top: 16px;

      &__content {
        .nk23_c-tab2__content {
          padding-top: 0;
        }
      }
    }

    .nk23_c-title01,
    .nk23_c-filter,
    .nk23_c-tab7 {
      margin-bottom: 16px;
    }

    .nk23_c-filter1 {
      @include r.sp {
        margin: 8px 0 16px;
      }
    }

    .nk23_c-table14 {
      .is-w32 {
        width: 32px !important;
      }

      .is-bold {
        font-weight: bold;
      }

      .is-nowrap {
        min-width: 66px;
        white-space: nowrap;
      }
    }
  }

}

@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-tourokuba_info {
	&1 {
		padding: 16px 0 0;

		@include r.sp {
			padding-top: 8px;
		}

		.nk23_c-title01 {
			margin-bottom: 16px;

			@include r.sp {
				margin-bottom: 8px;
			}
		}

		.nk23_c-text01 {
			text-align: right;
			padding: 16px 0 0;

			@include r.sp {
				padding: 8px 0 0;
			}
		}
		.nk23_c-text02--style1 {
			padding-bottom: 40px;
		}
	}

	&2 {
		margin-top: 16px;

		&__txt {
			font-size: 1.4rem;
			line-height: 20px;
			padding: 8px 0;
			text-align: right;
			display: block;
		}

		@include r.sp {
			margin-top: 8px;
		}

		.nk23_c-banner2__txt {
			font-size: 1.4rem;
			line-height: 1;

			@include r.sp {
				font-size: 1.2rem;
			}
		}

		.nk23_c-table22.js-scroll-datatable .nk23_c-table22__table .is-col11 span.js-modal1-btn {
			cursor: pointer;
		}
		.nk23_u-text10,
		.nk23_c-table23__txt3 {
			font-size: 1.1rem !important;
		}

		.nk23_u-text12 {
			overflow: hidden;
    	text-overflow: ellipsis;
    	white-space: nowrap;
		}
	}

	&3 {
		padding-bottom: 40px;
		margin-top: 16px;

		.nk23_c-card01 {
			&__head {
				display: flex;
				padding: 0 16px;
				align-items: center;
				min-height: 48px;

				@include r.sp {
					padding: 0 8px;
					min-height: 40px;
				}
			}
		}
	}

	.nk23_c-listcheck {
		&.is-style1 {
			.nk23_c-listcheck__item {
				&:last-child {
					width: 12.5%;

					@include r.sp {
						width: calc(50% - 4px);
					}

					@media screen and (max-width: 767px) and (min-width: 569px) {
						width: calc(33.3333% - 4px);
					}

					@media screen and (max-width: 568px) and (min-width: 415px) {
						width: calc(50% - 4px);
					}
				}
			}
		}
	}

	.nk23_c-chart3 {
		height: 100%;
		@include r.sp {
			max-height: 89px;
		}
		&__inner {
			height: 100%;
		}
	}
	.nk23_c-table22 {
		&.is-table-v6 {
			.nk23_c-table22__table {
				thead {
					th {
						height: 125px;
					}
				}
			}
			.scroll-content {
				height: auto;
			}
		}
	}
	.nk23_c-table22__tbcontent__bottom {
		tbody {
			tr {
				td {
					&:not(:nth-of-type(1), :nth-of-type(2)) {
						visibility: hidden;
					}
				}
			}
		}
	}
}

@media print {
	@page {
		size: A4 portrait;
	}
	.p-tourokuba_info1 {
		padding-top: 8px;
		.nk23_c-title01 {
			display: none;
		}
		.nk23_c-tab1__imgtext {
			padding: 0 5px;
		}
		.nk23_c-tab1__img {
			margin-bottom: 0;
		}
		.nk23_c-tab1__texts__gr {
			margin-top: 0;
		}
		.nk23_c-card01--style1 {
			margin-top: 0;
			display: flex;
			.nk23_c-card01__head {
				border: none;
				min-height: 30px;
				padding-left: 0;
			}
			.nk23_c-card01__body {
				padding: 4px 4px 0;
			}
		}
		.nk23_c-notice--style2 {
			margin-bottom: 8px;
			padding: 0;
		}
		.nk23_c-text01 {
			text-align: right;
    	padding: 0;
    	font-size: 8pt;
		}
	}
	.p-tourokuba_info2 {
		margin-top: 4px;
		table th,
		table td {
			padding: 1px 2px !important;
		}
		.nk23_c-table22__Grouptxt:not(:last-child) {
			margin-bottom: 4px;
		}
		.nk23_c-table22__table td .is-link.nk23_u-text16 {
			padding: 2px 0;
		}
	}
	.nk23_c-block06__content {
		display: none !important;

		&[data-tab2-content="tab2"] {
			display: block !important;
		}
	}
	.nk23_c-tab1__navi {
		display: none !important;
	}

	.nk23_c-tab1__box__top {
		display: flex !important;
		justify-content: space-between !important;
	}

	.nk23_c-tab1__box__bottom {
		display: none !important;
	}

	.nk23_c-banner2 {
		display: none !important;
	}

	.nk23_c-table22__tbcontent {
		padding-top: 0 !important;
		border-radius: 0 !important;
		max-width: 1092px;
		overflow: hidden;
	}

	.nk23_c-table22__inner {
		padding: 0 !important;
	}

	.nk23_c-table22 {
		margin-top: 0 !important;

		&__table {
			border-radius: 0 !important;
			font-size: 1.1rem !important;

			.nk23_u-text16 {
				font-size: 1.2rem !important;
			}

			.nk23_u-text19 {
				margin-right: 0.5px;
				font-size: 1.05rem !important;
				white-space: nowrap !important;
				letter-spacing: -0.2rem !important;
			}

			td.is-grtxt .is-link {
				max-width: 117.5px !important;
				min-width: 0 !important;
				font-size: 1.2rem !important;
				letter-spacing: -0.12rem !important;
			}
		}

		&__table {
			thead {
				position: static !important;
				height: auto !important;

				tr {
					position: static !important;
					height: auto !important;

					th {
						width: auto !important;
						display: table-cell !important;
						max-width: 117.5px !important;
						min-width: 0 !important;
						height: auto !important;
						min-height: 0 !important;
						word-break: break-all !important;
						word-wrap: break-word !important;
						position: static !important;
						letter-spacing: -0.12rem !important;
					}

					.is-col05,
					.is-col06,
					.is-col07,
					.is-col08,
					.is-col09,
					.is-col10,
					.is-col11,
					.is-col12 {
						display: none !important;
					}

					&:first-child {
						th {
							&:nth-of-type(20),
							&:nth-of-type(21),
							&:nth-of-type(22),
							&:nth-of-type(23),
							&:nth-of-type(24),
							&:nth-of-type(25),
							&:nth-of-type(26),
							&:nth-of-type(27),
							&:nth-of-type(28),
							&:nth-of-type(29),
							&:nth-of-type(30),
							&:nth-of-type(31),
							&:nth-of-type(32),
							&:nth-of-type(33) {
								display: none !important;
							}
						}
					}

					&:nth-of-type(2) {
						th {
							&:nth-of-type(9),
							&:nth-of-type(10),
							&:nth-of-type(11),
							&:nth-of-type(12),
							&:nth-of-type(13),
							&:nth-of-type(14),
							&:nth-of-type(15),
							&:nth-of-type(16),
							&:nth-of-type(17) {
								display: none !important;
							}
						}
					}

					.is-col16,
					.is-col17,
					.is-col18,
					.is-col19,
					.is-col20 {
						min-width: 125px !important;
					}
				}
			}

			tbody {
				background: #fff !important;

				td {
					width: auto !important;
					max-width: 117.5px !important;
					min-width: 0 !important;
					height: auto !important;
					min-height: 0 !important;
					display: table-cell !important;
					word-break: break-all !important;
					word-wrap: break-word !important;
					position: inherit !important;
					letter-spacing: -0.12rem !important;

					&.is-grnum {
						letter-spacing: -1px !important;
						word-spacing: -1px !important;
					}
				}

				tr {
					&:nth-of-type(2n + 1) {
						td {
							&:nth-of-type(5),
							&:nth-of-type(6),
							&:nth-of-type(7),
							&:nth-of-type(8),
							&:nth-of-type(9),
							&:nth-of-type(10),
							&:nth-of-type(11),
							&:nth-of-type(12),
							&:nth-of-type(24),
							&:nth-of-type(25),
							&:nth-of-type(26),
							&:nth-of-type(27),
							&:nth-of-type(28),
							&:nth-of-type(29),
							&:nth-of-type(30),
							&:nth-of-type(31),
							&:nth-of-type(32),
							&:nth-of-type(33),
							&:nth-of-type(34),
							&:nth-of-type(35),
							&:nth-of-type(36),
							&:nth-of-type(37) {
								display: none !important;
							}
						}
					}

					&:nth-of-type(2n) {
						td {
							&:nth-of-type(4),
							&:nth-of-type(5),
							&:nth-of-type(6),
							&:nth-of-type(7),
							&:nth-of-type(8),
							&:nth-of-type(9),
							&:nth-of-type(10),
							&:nth-of-type(11),
							&:nth-of-type(23),
							&:nth-of-type(24),
							&:nth-of-type(25),
							&:nth-of-type(26),
							&:nth-of-type(27),
							&:nth-of-type(28),
							&:nth-of-type(29),
							&:nth-of-type(30),
							&:nth-of-type(31),
							&:nth-of-type(32),
							&:nth-of-type(33),
							&:nth-of-type(34),
							&:nth-of-type(35),
							&:nth-of-type(36) {
								display: none !important;
							}
						}
					}
				}
			}
		}
	}

	.nk23_c-table23 {
		width: 100% !important;

		&__table {
			border-radius: 0 !important;

			th,
			td {
				border: 1px solid v.$color19 !important;

				&:first-child {
					border-left: 1px solid v.$color19 !important;
				}

				&:last-child {
					display: none !important;
				}
			}
		}
	}

	.p-tourokuba_info3 {
		display: none !important;
	}

	.nk23_c-btn02 {
		display: none !important;
	}

	.p-tourokuba_info2 .nk23_c-table23__txt3,
	.p-tourokuba_info2 .nk23_u-text12,
	.p-tourokuba_info2 .nk23_u-text10 {
		font-size: 1rem !important;
	}
	.nk23_c-table22__table td.is-grnum [class^="nk23_u-color"] {
		width: 13px;
	}
}

body.ios_old {
	.p-tourokuba_info
		.nk23_c-banner2__right
		.nk23_c-banner2__switch
		.nk23_c-switch1__navi.js-tab-lines
		.nk23_c-switch1.js-switch1
		li:nth-of-type(2) {
		display: none;
	}
}

@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-bangumi_menu {
  padding: 16px 0 40px;

  @include r.sp {
    padding: 16px 8px 40px;
  }

  .contents950,
  .contents770 {
    max-width: 640px;
    margin: auto;
  }

  .contents770 {
    p {
      &:first-child {
        font-size: 1.3rem;
      }
    }
  }
}
@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-leading_cho {
  .nk23_c-table24__icon {
    @include r.sp {
      top: 200px;
    }
  }

  .nk23_c-table24 {
    @include r.sp {
      margin-left: 0;
      margin-right: 0;
    }

    tbody {
      td {
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          text-align: right;
        }
        @include r.sp {
          &:last-child {
            padding: 4px 8px;
          }
        }
      }
    }

    &__table {
      @include r.sp {
        min-width: 606px;
      }

      thead {
        th {
          @include r.sp {
            width: auto;
            min-width: auto;
          }
        }
      }
    }
  }

  &1 {
    padding: 20px 0 40px;

    .nk23_c-title01 {
      margin-bottom: 20px;
    }

    .nk23_c-block18 {
      &:only-child {
        @include r.sp {
          margin-bottom: 0;
        }
      }

      &.is-style1 {
        @include r.sp {
          width: 100%;
          max-width: calc(100% - 16px);
          margin-left: 8px;
        }
      }

      & + .nk23_c-text01 {
        @include r.sp {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }

    .nk23_l-container {
      @include r.sp {
        padding: 0;
      }
    }
  }
}

@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-expiration_date {
	display: flex;
	max-width: 992px;
	margin: 16px auto 40px;
	padding: 0 16px !important;
	box-sizing: border-box;

	h2 {
		margin-bottom: 16px;
		text-align: center;

		img {
			display: inline-block;
		}
	}

	.nk23_c-text01 {
		display: inline-block !important;
		margin-bottom: 0 !important;
	}

	#contents770a {
		border-radius: 8px;
	}

	#reView {
		display: inline-block;
		width: 110px;
		margin-right: 4px;
	}

	.bl-left {
		display: flex;
		align-items: center;

		img {
			height: 14px;
		}

		select {
			margin-left: 4px;
			border: 1px solid v.$color19;
			padding: 0 16px 0 4px;
			border-radius: 4px;
			font-size: 1.3rem;
			background: url(p.$path + 'images/common/icon/icon_down.svg') no-repeat
				center right 4px / 10px auto;
		}

		// .p-expiration_date .bl-left ~ .nk23_c-text01.is-style3 /
		& ~ .nk23_c-text01 {
			position: relative;
			top: -6px;
			font-size: 1.3rem !important;
			line-height: 2.2rem;
		}
	}

	.tb-sell-fcl {
		margin-top: 16px;
		margin-bottom: 0;
		border-collapse: collapse;
	}

	#noneMsg {
		margin-top: 16px;
	}
}

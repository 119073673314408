@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-support_guide {
  display: flex;
  max-width: 960px;
  margin: auto;
  .title-spat4-01 {
    color: #fff;
    padding: 16px;
    margin-bottom: 20px !important;
  }

  .contents750 {
    background: #fff;
    padding: 30px 10px;
    border-radius: 8px;
    min-width: 770px;
    .mB30 {
      &:last-child {
        margin-bottom: 0 !important;
      }
      &:nth-of-type(5) {
        margin-bottom: 0 !important;
      }
      &:nth-of-type(6) {
        margin-top: 41px !important;
      }
      &:nth-of-type(7) {
        margin-top: 41px !important;
      }
    }
    .title-spat4-02 {
      padding-left: -10px !important;
      margin: 0 0 16px -10px !important;
      &:before {
        background: none;
        content: none;
      }
      &.tx-mid {
        font-size: 1.6rem;
      }
    }
    .title-spat4-03 {
      margin-top: 22px;
    }

    p {
      font-size: 1.3rem;
    }
    .login {
      a {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
        font-weight: normal;
        line-height: 1rem;
        color: v.$color32;
        background-color: #fff !important;
        border: 1px solid v.$color32;
        width: 395px;
        min-height: 35px;
        box-shadow: 0 2px v.$color32;
        border-radius: 999px;
        transition: none;
        background-image: none;

        @include r.hover {
          box-shadow: none;
          transform: translateY(2px);
          opacity: 1;
          color: v.$color32;
        }
      }
    }
    .mB10 {
      margin-bottom: 10px;
    }
    .tx-mid {
      img {
        margin: 0 auto;
      }
      .bg-day,
      .bg-nighter {
        width: 360px;
        font-size: 1.5rem;
        margin-top: 2px;
        .mB10 {
          margin-bottom: 14px;
          img {
            max-width: 160px;
          }
        }
        strong {
          margin-bottom: -17px;
          display: block;
        }
      }
      .bg-day {
        background: #f59331;
      }
      .bg-nighter {
        background: #004ea2;
      }
    }
    ul {
      margin: 0 13px;
      li {
        margin-bottom: -3px;
        &.mark-pdf {
          min-height: 32px;
          margin-bottom: 0;
          a {
            margin-top: 0;
          }
        }
        &::before {
          content: "・";
          position: relative;
          display: inline-block;
          width: 13px;
          height: 19px;
          text-align: center;
          line-height: 19px;
          color: #221f1f;
          font-size: 1.3rem;
        }
        a {
          color: v.$color-link;
          font-size: 1.3rem;
          line-height: 1.5;
          @include r.hover {
            text-decoration: underline;
          }
        }
      }
      &.list00 {
        display: flex;
        margin: 0 26px;
        li {
          width: calc(50% - 5px);

          &::before {
            content: none;
          }
          &:last-child {
            margin-left: 10px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
    .line-dotted {
      background: none;
      border-top: 1px solid #c9c9c9;
      margin-top: 43px;
      padding-top: 32px;
    }
    .box2 {
      width: calc(50% - 5px);
      display: flex;
      justify-content: flex-end;
      @include r.hover {
        opacity: 0.6;
      }

      > img {
        @include r.hover {
          opacity: 1;
        }
      }
      &:nth-child(2n) {
        justify-content: flex-start;
      }
      img {
        width: 370px;
      }
    }
  }
}

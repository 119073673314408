@use "../../settings/variable"as v;
@use "../../settings/mixin"as r;
@use "../../settings/_path"as p;

.p-shiken_menu {
  &1 {
    padding: 20px 0 40px;

    .nk23_c-text01 {
      margin: 20px 0 16px;
    }
  }
}
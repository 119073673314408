@use "../../settings/variable"as v;
@use "../../settings/mixin"as r;
@use "../../settings/_path"as p;

.p-jyougai_release_guide {
  display: flex;
  max-width: 960px;
  margin: auto;

  &__img {
    margin-bottom: 15px;
  }

  .tab-select-txt2 {
    ul {
      li {
        width: calc((100%/6) - 4px);

        a {
          padding: 0;
        }
      }
    }
  }

  &__date {
    margin-top: 13px;
    display: block;
  }


}
@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-contact_input {
  display: flex;
  max-width: 960px;
  margin: auto;

  #contents730 {
    background: #fff;
    padding: 15px 20px 7px !important;
    border-radius: 8px;
    font-size: 1.3rem;
    line-height: 2.1rem;

    h2 {
      margin-bottom: 16px;
    }
    h4 {
      margin-bottom: 8px;
      margin-top: 12px;
      font-weight: bold;
    }
    table.tb00-i {
      border-collapse: collapse;
      border-spacing: 0;
      margin-top: -1px;
      margin-bottom: 8px;
      width: 100%;
      tr {
        background: #f7f7f7;
        &:nth-child(2n) {
          background: #fff;
        }
        &:first-child {
          input {
            width: 242px;
          }
        }
        &:nth-child(2) {
          input {
            width: 480px;
          }
        }
        &:nth-child(3) {
          input {
            width: 207px;
          }
        }
      }
      td,
      th {
        text-align: left;
        font-size: 1.3rem;
        padding: 5px 2px;
        input {
          border: 1px solid v.$color19;
          border-radius: 8px;
          background: #fff;
          padding: 0 5px;
          height: 19px;
          line-height: 19px;
          font-size: 1.3rem;
          box-sizing: border-box;
          appearance: none;
          -webkit-appearance: none;
        }
        @media (hover: none) {
          input {
            font-size: 1.2rem;
          }
        }
        textarea {
          border: 1px solid v.$color19;
          border-radius: 8px;
          background: #fff;
          padding: 2px 5px;
          width: 334px;
          height: 152px;
          font-size: 1.3rem;
          display: block;
          resize: none;
        }
      }
      th {
        min-width: 163px;
        .tx-red01 {
          margin-top: 0;
        }
      }
    }
    p {
      font-size: 1.3rem;
      line-height: 2.1rem;
      margin-bottom: 13px;
      &.al-center {
        text-align: center;
        display: block;
        a {
          img {
            width: 121px;
            margin-bottom: -6px;
            margin-top: 5px;
          }
        }
      }
    }
    a {
      color: v.$color-link;
      @include r.hover {
        text-decoration: underline;
      }
    }
    .tx-red01 {
      color: #e63434;
      margin-top: -2px;
    }
  }
}

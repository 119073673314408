@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-race_trend {
  &1 {
    padding: 16px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }

    .nk23_c-title01 {
      margin-bottom: 16px;

      @include r.sp {
        margin-bottom: 8px;
      }

      &.is-style2 {
        @include r.pc {
          margin-bottom: 8px;
        }
      }
    }

    .nk23_c-slide02 {
      &__content {
        display: block;
      }

      &__text {
        margin-bottom: 8px;

        @include r.sp {
          margin-bottom: 4px;
        }
      }
    }

    .nk23_c-tab2__subnavi__item {
      &:last-child {
        margin-left: 0;
      }
    }
  }
  .nk23_c-block16 {
    @include r.sp {
      padding: 0 8px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;

      @include r.sp {
        margin-bottom: 8px;
      }
    }
  }
}

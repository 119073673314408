@use "../../settings/variable"as v;
@use "../../settings/mixin"as r;
@use "../../settings/_path"as p;

.p-spat4_calendar {
  display: flex;
  max-width: 960px;
  margin: auto;

  &.c-release {
    #contents770b {
      h2 {
        margin-bottom: 15px;
      }

      #contents730 {
        .tx-gray01 {
          margin-bottom: 8px;
        }

        .bl-right {
          margin-bottom: 0;
        }

        .tb-cal2 {
          .t-name {
            >span {
              display: block;
              padding-left: 6px;
            }
          }

          th {
            padding: 2px 1px 2px 0px;
          }

          td {
            padding-bottom: 1.6px;
          }
        }
      }
    }
  }

  .tab-select-txt2 {
    position: relative;
    border-bottom: 1px solid #0d3180;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &:after {
      display: none;
    }

    ul {
      margin: 5px -2px 0;

      li {
        a {
          &:hover {
            background: #0d3180;
            border-top: 1px solid #0d3180;
            border-left: 1px solid #0d3180;
            border-right: 1px solid #0d3180;
          }
        }

        &#crt-tab-txt2 {
          a {
            background: #0d3180;
            border-top: 1px solid #0d3180;
            border-left: 1px solid #0d3180;
            border-right: 1px solid #0d3180;
          }
        }
      }
    }

    .nk23_c-btn01 {
      min-height: 22px;
      position: relative;
      margin-right: 7px;

      @include r.sp {
        display: none;
      }
    }
  }


}

.smartphone {
  .p-spat4_calendar {
    .nk23_c-btn01 {
      display: none;
    }

    table.display_pc {
      display: none !important;
    }

    table.display_sp {
      display: table !important;
    }
  }
}

@media print {
  .p-spat4_calendar {
    max-width: none;
  }

  .c-release #contents770b {
    padding: 0 !important;
  }

  .box-rireki {
    max-width: 50%;
  }

  .nk23_c-btn01 {
    display: none;
  }
}
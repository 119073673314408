@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;


.p-guide_urawa {
  display: flex;
  max-width: 992px;
  margin: 16px auto 40px;
  padding: 0 16px !important;
  box-sizing: border-box;

  @include r.sp {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding: 0 8px !important;
  }

  #tl-urawa {
    background-position: bottom;
    height: 139px;
    background-size: 100% 104px;

    @include r.sp {
      background: none;
      height: auto;
    }

    h2 {
      @include r.sp {
        background: url(/info/urawakeiba/images/bg-tl-head-sp.jpg) no-repeat;
        height: 21vw;
        background-position: bottom;
        background-size: cover;
      }
    }
  }
}
@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-odds {
	&1 {
		padding: 20px 0 40px;

		@include r.sp {
			padding-top: 10px;
		}
	}

	.nk23_c-title01 {
		margin-bottom: 20px;

		@include r.sp {
			margin-bottom: 10px;
		}
	}

	.nk23_c-table02 {
		&__inner {
			padding-bottom: 0;
		}

		&__icon {
			@include r.sp {
				top: 160px;
			}
		}
	}

	.nk23_c-table02 td,
	.nk23_c-table02 th {
		padding: 8px;

		@include r.sp {
			padding: 4px;
		}
	}
	.nk23_c-table02 td {
		@include r.sp {
			padding: 4px 8px;
		}
	}

	.nk23_c-table03 {
		margin-top: 16px;
	}

	.nk23_c-table02__title,
	.nk23_c-table04__title,
	.nk23_c-table05__title,
	.nk23_c-table06__title {
		padding: 16px;

		@include r.sp {
			padding: 12px 16px;
		}
	}

	.nk23_c-table04 td {
		padding: 8px;

		@include r.sp {
			padding: 4px;
			&:last-child {
				padding: 4px 8px;
			}
		}
	}

	.nk23_c-table05__title {
		&.rounded {
			border-radius: 8px;

			@include r.sp {
				border-radius: 0;
			}
		}

		&.is-border1 {
			@include r.sp {
				border-bottom: 1px solid v.$color23;
			}
		}
	}

	.nk23_c-table05 td,
	.nk23_c-table05 th {
		padding: 8px;

		@include r.sp {
			padding: 4px 8px;
		}
	}

	.nk23_c-table06 td,
	.nk23_c-table06 th {
		padding: 8px;

		@include r.sp {
			padding: 4px;
		}
	}
	.nk23_c-table06 td {
		@include r.sp {
			padding: 4px 8px;
		}
	}
	.nk23_c-table06 th {
		word-break: keep-all;
		white-space: nowrap;
	}

	.nk23_c-tab2 {
		@include r.sp {
			margin-top: 16px;
		}
	}

	.nk23_c-tab3 {
		&__content {
			padding-top: 0;

			@include r.sp {
				margin-top: 6px;
			}
		}
	}

	.nk23_c-block13 {
		@include r.sp {
			padding: 0;
			position: relative;
		}

		&:after {
			// content: '';
			background: rgba($color: #221f1f, $alpha: 0.75);
			width: 100%;
			height: calc(100% + 40px);
			position: absolute;
			left: 0;
			top: 100px;
			opacity: 0;
			z-index: -1;
		}

		&.is-open {
			&:after {
				opacity: 1;
				z-index: 10;
			}

			.nk23_c-table04 {
				&__table {
					border-radius: 0 0 8px 8px;
					overflow: hidden;
				}
			}
		}

		.nk23__accordion {
			@include r.sp {
				width: 100%;
				margin: 0;
			}
		}
	}

	.mt16 {
		margin-top: 16px;
	}

	.nk23_c-title02 {
		font-size: 1.6rem;
		font-weight: 700;
		background-color: #fff;
		border-radius: 8px;
		padding: 12px 16px;
		margin-bottom: 16px;

		@include r.sp {
			border-radius: 0;
			margin-bottom: 0;
			border-bottom: 1px solid v.$color23;
			padding: 12px 16px;
		}
	}

	.nk23_c-text01 {
		font-weight: 700;
		line-height: 1.4;
		padding: 16px 0 0 16px;

		@include r.sp {
			padding: 8px 0 0 16px;
		}
	}

	.nk23_c-text02 {
		line-height: 2.6rem;
		padding: 0 32px;

		&.is-style1 {
			padding: 0 24px;
		}

		& > p {
			display: inline-block;
			text-align: left;
		}
	}

	@media print {
		.nk23_c-table02__table,
		.nk23_c-table03__table,
		.nk23_c-table06__table {
			border: 1px solid v.$color19;
		}
		.nk23_c-table02__title,
		.nk23_c-table06__title {
			padding-left: 0;
		}
	}

	.nk23_c-tab1__img {
		@include r.pc {
			height: 83px;
		}

		img {
			width: auto;

			@include r.sp {
				width: 100%;
			}
		}
	}
}

.page-odds {
	.nk23_c-footer {
		z-index: 11;
	}
}

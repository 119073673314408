@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-tourokuba {
	max-width: 960px;
	margin: 0 auto;

	@include r.sp {
		padding: 16px 8px 40px !important;
	}

	.icon-race {
		width: 36px;
	}

	#bangumi-header {
		background: #fff;
		border-radius: 8px;
		padding: 8px;
		margin-bottom: 8px;

		.bl-right {
			@include r.max-w(530px) {
				margin-top: 8px;
				width: 100%;
				float: none;
				display: flex;
				justify-content: center;
			}
		}

		h2 {
			font-size: 1.6rem;
			font-weight: 700;

			.tx-xsmall {
				font-size: 1.2rem;
				font-weight: normal;
			}

			.mR10 {
				margin-right: 8px;
				width: 36px;

				&.fnb {
          width: 28px;
          padding: 0;
        }
			}

			.bl-left {
				font-size: 1.6rem;

				.tx-small {
					font-size: 1.4rem;
					font-weight: normal;
				}
			}

			.clr {
				display: none;
			}

			#nav-days {
				ul {
					display: flex;
					margin-right: 79px;

					@include r.max-w(530px) {
						width: 100%;
						justify-content: center;
						margin-right: 0;
					}

					li {
						a {
							width: 63px;
							height: 22px;
							line-height: 20px;
							display: inline-block;
							background: #ffffff;
							border-radius: 24px;
							border: 1px solid v.$color19;
							box-shadow: 0 2px 0 v.$color19;
							font-size: 12px;
							font-weight: normal;
							text-align: center;
							margin-left: 2px;
							transition: 0.3s ease-in-out;
							position: relative;
							@include r.hover {
								box-shadow: none;
								top: 1px;
							}

							&#nav-day1 {
								background: #000000;
								color: #ffffff;
								font-weight: bold;
								box-shadow: none;
								height: 24px;
								line-height: 22px;
								@include r.hover {
									top: 0;
								}
							}
						}
					}
				}
			}
		}

		.tx-time {
			margin: 0 0 8px 0;
		}
	}

	#contents170 {
		float: left;
		padding-left: 8px;
	}

	#contents770 {
		float: right;

		.tb02 {
			border-radius: 8px;
			overflow: hidden;

			&:not(:last-child) {
				margin-bottom: 16px;
			}

			td {
				font-size: 1.3rem;

				@include r.pc {
					padding: 3px 4px;
					line-height: 1.616;
				}
			}

			tr {
				th {
					background: #ffffff;
					color: v.$color0;
					font-weight: normal;
					border-top: none;
					padding-top: 8px;

					img {
						display: inline-block;
						margin-top: -5px;
					}
				}

				th,
				td {
					text-align: left;
				}
			}
		}

		p {
			font-size: 1.3rem;
		}
	}

	@media print {
		#bangumi-header h2 #nav-days ul li a {
			text-indent: 0;
			color: v.$color0;
		}
		#contents770 {
			float: none;
			width: 100%;
		}
		#contents770 .tb02 tr th img {
			margin-top: 0;
		}
		#contents770 .tb02 {
			border: 1.5px solid v.$color19;
			overflow: inherit;

			th,
			td {
				padding: 4px !important;
				border: 1.5px solid v.$color19;
			}
		}
	}
}

@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-netbank_guide {
  margin: 0 auto;
  padding: 16px 16px 40px !important;
  display: flex;
  max-width: 992px;
  box-sizing: border-box;

  .contents750 {
    width: 770px;

    .contents750 {
      padding: 0;
    }
  }

  // .p-netbank_guide ul.tab-navigation .
  ul.tab-navigation {
    margin-bottom: 22px;
  }

  .title-spat4-01 {
    margin-bottom: 16px;
  }

  .tb00 {
    max-width: 750px;

    td {
      padding: 4px 7.5px;

      img {
        width: 110px;
        height: auto;
      }
    }
  }
}
@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-about_jyusyosiki_buy {
	margin: 0 auto;
	padding: 16px 16px 40px !important;
	display: flex;
	max-width: 992px;
	box-sizing: border-box;

	p {
		a {
			color: v.$color-link;

			@include r.hover {
				text-decoration: underline;
			}
		}
	}

	.graybox {
		margin-top: 14px;
		padding: 10px 11px 14px;
		background-color: #f2f2f2;
		border-radius: 8px;
		width: 100%;
		font-size: 1.3rem;
		line-height: 2rem;

		// .p-about_jyusyosiki_buy .graybox+.h3_spat4loto .
		& + .h3_spat4loto {
			margin-top: 25px;
		}
	}

	.h2_spat4loto {
		position: relative;

		a {
			right: 10px;
			top: 7px;
			z-index: 1;
			position: absolute;
		}
	}

	#contents750 {
		padding: 20px 10px;
		background-color: #fff;
		border-radius: 0 0 8px 8px;
		display: flex;
		flex-wrap: wrap;
		font-size: 1.3rem;
		line-height: 1.2;
	}

	// .p-about_jyusyosiki_triple .read .
	.read {
		margin: 16px 0 13px;
		font-size: 2.2rem;
		line-height: 3rem;
		color: v.$color64;
		font-weight: 700;
		width: 100%;

		& + p {
			margin-top: 2px;
		}
	}

	.al-center {
		margin-bottom: 2px;
		width: 100%;

		img {
			display: inline-block;
		}

		// .p-about_jyusyosiki_buy .al-center+.graybox .
		& + .graybox {
			margin-top: 40px;
		}
	}

	.grbtn {
		display: flex;
		margin: 0 -5px;

		a {
			width: calc(50% - 10px);
			margin: 0 5px;
		}
	}

	.dotline {
		margin: 25px 0 22px;
		width: 100%;
		height: 1px;
		border-top: 1px solid v.$color19;

		& + a {
			border-radius: 8px;
			overflow: hidden;
		}

		&:nth-last-child(4) {
			margin: 51px 0 22px;
		}

		&:nth-last-child(2) {
			margin: 22px 0;
		}
	}
}

@use "../../settings/variable"as v;
@use "../../settings/mixin"as r;
@use "../../settings/_path"as p;

.p-jyusyosiki_rankings {
  padding: 8px 0 16px 40px;

  [data-tab-content] {
    padding-top: 16px;
  }

  .contents950 {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }

  .tl-main {
    margin-bottom: 16px;
    text-align: center;

    img {
      display: inline-block;
    }
  }

  .mT30 {
    font-size: 1.3rem;
    line-height: 2.2rem;
  }

  .tx-large {
    display: block;
    font-size: 1.8rem;

    &+.tx-large {
      margin: 16px 0;
    }
  }

  .nk23_c-listlink {
    margin: 16px 0;
  }

  .tb01 {
    margin: 8px 0 16px 0;

    @include r.pc {
      border-radius: 8px;
      overflow: hidden;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .hitban {
      display: inline-block;
    }

    td {
      img {
        display: inline-block;
      }
    }

    thead {
      th {
        font-size: 1.3rem;

        &:first-child {
          // width: 1%;
          // min-width: 85px;
        }

        &:nth-child(2) {
          // width: 1%;
          // min-width: 52px;
        }

        &:nth-child(3) {
          // width: 1%;
          // min-width: 110px;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          // width: 1%;
          // min-width: 120px;
        }

        &:nth-child(8),
        &:nth-child(9) {
          // width: 1%;
          // min-width: 175px;
        }

        &:nth-child(8) {
          // width: 1%;
          // min-width: 62px;
        }
      }
    }

    tbody {
      td {
        font-size: 1.3rem;
        height: 52px;

        &:not([class*="bg-"]) {
          background-color: #fff;
        }

        &:first-child {
          // width: 1%;
          // min-width: 40px;
        }
      }
    }
  }

  #box-search {
    margin-bottom: 8px;
  }
}
@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-about_jyusyosiki_howmuch {
	margin: 0 auto;
	padding: 16px 16px 40px !important;
	display: flex;
	max-width: 992px;
	box-sizing: border-box;

	p {
		& + .h3_spat4loto {
			margin-top: 27px;
		}
	}

	.h2_spat4loto {
		position: relative;

		a {
			right: 10px;
			top: 7px;
			z-index: 1;
			position: absolute;
		}
	}

	#contents750 {
		padding: 20px 10px;
		background-color: #fff;
		border-radius: 0 0 8px 8px;
		display: flex;
		flex-wrap: wrap;
		font-size: 1.3rem;
		line-height: 2rem;

		table {
			margin-bottom: 30px;
			border-collapse: separate;
			border-spacing: 0;
			width: 100%;

			td {
				padding: 2px;
				border: 1px solid v.$color19;

				&:first-child {
					border-right: 0;
				}

				&:nth-child(1) {
					background-color: #f7f7f7;
					width: 118px;
					font-weight: 700;
					text-align: center;
				}
			}

			tr {
				&:first-child {
					td {
						&:first-child {
							border-top-left-radius: 8px;
						}

						&:last-child {
							border-top-right-radius: 8px;
						}
					}
				}

				&:last-child {
					td {
						&:first-child {
							border-bottom-left-radius: 8px;
						}

						&:last-child {
							border-bottom-right-radius: 8px;
						}
					}
				}

				&:not(:last-child) {
					td {
						border-bottom: none;
					}
				}
			}
		}
	}

	// .p-about_jyusyosiki_triple .read .
	.read {
		margin: 16px 0 13px;
		font-size: 2.2rem;
		line-height: 3rem;
		color: v.$color64;
		font-weight: 700;

		// .p-about_jyusyosiki_howmuch .read .tx-12px .
		.tx-12px {
			margin-bottom: -2px;
			font-size: 1.2rem;
			line-height: 2rem;
			color: v.$color0;
			display: block;
		}

		& + p {
			// .p-about_jyusyosiki_howmuch .read+p+a .
			& + a {
				margin-top: 12px;
			}
		}
	}

	.al-center {
		margin: 12px 0 15px;
		width: 100%;

		img {
			display: inline-block;
		}
	}

	.text_blue {
		color: v.$color64;
	}

	.grbtn {
		display: flex;
		margin: 0 -5px;

		a {
			width: calc(50% - 10px);
			margin: 0 5px;
		}

		& + .dotline {
			margin: 22px 0;
		}
	}

	.dotline {
		margin: 33px 0 22px;
		width: 100%;
		height: 1px;
		border-top: 1px solid v.$color19;

		& + a {
			border-radius: 8px;
			overflow: hidden;
		}
	}
}

@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-cho_grade {
  padding: 16px 0 40px;

  @include r.sp {
    padding: 16px 8px 40px;
  }
  #race-header550-2 {
    padding: 16px 8px;
  }
}

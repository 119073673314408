@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-kis_detail_search_result {
  #contents950 {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }

  .display_sp {
    #contents {
      h2.title02 {
        &:nth-child(4) {
          @include r.sp {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-course_result {
  &1 {
    padding: 16px 0 24px;

    @include r.sp {
      padding: 8px 0 32px;
    }
  }

  .nk23_c-text01 {
    &:last-child {
      margin-bottom: 16px;

      @include r.sp {
        margin-bottom: 8px;
      }
    }

    & + .nk23_c-table01 {
      margin-top: 8px;
    }

    & + .nk23_c-text02 {
      margin-bottom: 16px;

      @include r.sp {
        margin-bottom: 8px;
      }
    }
  }

  .nk23_c-text02 {
    &:only-child {
      margin-bottom: 16px;

      @include r.sp {
        margin-bottom: 8px;
      }
    }
  }

  .nk23_c-title01 {
    margin-bottom: 16px;

    @include r.sp {
      margin-bottom: 8px;
    }
  }

  .nk23_c-table01 {
    & + .nk23_c-listbtn {
      @include r.sp {
        margin-top: 16px;
      }
    }

    td[class^="nk23_u-bg-"] {
      font-weight: bold;
    }
    td {
      @include r.sp {
        &:not(:nth-of-type(1)) {
          padding: 4px 8px;
        }
      }
    }
    &__table {
      tbody {
        tr {
          &:nth-of-type(1) {
            td {
              @include r.sp {
                &:nth-of-type(2) {
                  padding: 4px;
                }
              }
            }
          }
        }
      }
    }

    #syubobaList1,
    #kisyuList1 {
      td {
        &:nth-of-type(2) {
          padding: 4px 8px;
        }
      }
    }
  }

  .nk23_c-link {
    margin-bottom: 8px;
  }

  .nk23_c-listbtn {
    margin-bottom: 10px;
  }
}

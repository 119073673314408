@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-about_spat4 {
	display: flex;
	max-width: 960px;
	margin: auto;

	#contents730 {
		width: 770px;
		background: #fff;
		padding: 15px 20px 20px !important;
		border-radius: 8px;
		font-size: 1.3rem;
		line-height: 2.1rem;
	}

	.ls-qa {
		margin: 36px 0 2px 20px;
	}

	.box-Q {
		margin-left: 20px;
		width: calc(100% - 40px);
	}

	.box-A {
		padding-left: 20px;
		padding-right: 20px;
	}

	.box690 {
		margin-top: 19px;
	}

	.tb00 {
		width: 75%;
		margin-top: 0;
		td {
			word-break: keep-all;
			white-space: nowrap;
		}

		a {
			color: #2461ee;
			@include r.hover {
				text-decoration: underline;
			}
		}
	}
}

@use "../../settings/variable"as v;
@use "../../settings/mixin"as r;
@use "../../settings/_path"as p;

.p-grace_waku {
  &__sc1 {
    padding-bottom: 40px;

    .nk23_c-title01 {
      margin: 16px 0;
    }

    .nk23_c-tab2 {
      margin-top: 16px;
      margin-bottom: 0;
    }

    .nk23_c-tab2__content .nk23_c-tab2__content {
      padding-top: 0;
    }

    .nk23_c-navi1__item.is-active .nk23_c-navi1__link.is-color1 {
      color: #221F1F;
    }

    .nk23_c-table16 {
      &.is-style1 {
        .nk23_c-table16__table {
          @include r.sp {
            margin-top: 0;
          }
        }
      }
    }

    .nk23_c-tab2__subnavi.is-style1 {
      @include r.sp {
        padding: 0 8px;
      }
    }
  }
}

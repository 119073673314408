@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-hibetu_kijyo {
  &1 {
    padding: 16px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }

    .nk23_c-title01 {
      margin-bottom: 16px;
    }
  }
}

@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-result {
  &1 {
    padding: 16px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }
  }

  .nk23_c-title01 {
    margin-bottom: 16px;
    font-size: 2.4rem;

    @include r.sp {
      margin-bottom: 8px;
      font-size: 2rem;
    }
  }

  .nk23_c-table01 {
    @include r.sp {
      th {
        &:first-child {
          border-left: 0;
        }
      }
    }

    &--style2 {
      @include r.sp {
        margin: 8px -8px 0;

        .nk23_c-table01__inner {
          padding-bottom: 0;
        }
      }
    }

    &--style3 {
      @include r.sp {
        margin: 0 -8px;
        padding-top: 8px;
      }

      .nk23_c-table01__inner {
        padding-bottom: 0;
        .nk23_c-table01__table {
          tbody {
            th {
              padding: 8px 4px;
              width: 1%;
              min-width: 38px;
              background-color: v.$color34;
              color: v.$color0;
              font-weight: bold;
              &.colspan-3 {
                background: #fff;
                border-bottom: none;
                min-width: 123px;
                font-weight: normal;
              }
            }
          }
        }
      }
    }

    &--style4 {
      .nk23_c-table01__inner {
        font-size: 1.3rem;
      }

      .nk23_c-table01__table td {
        padding: 8px 2px;

        @include r.sp {
          padding: 8px 4px;
        }
      }

      .nk23_c-table01__table th {
        padding: 4px 2px;
        white-space: nowrap;

        &:nth-of-type(4) {
          width: auto;
        }

        @include r.sp {
          padding: 4px;
        }
      }

      .nk23_c-table01__txt {
        padding: 16px 8px 8px;
        font-size: 1.4rem;
        line-height: 20px;
        display: flex;
        align-items: center;

        @include r.sp {
          padding: 16px 8px 8px;
        }

        &__icon {
          margin-left: 4px;
          margin-right: 16px;
          max-height: 20px;
        }
      }

      @include r.sp {
        margin: 0 -8px 16px;
      }
    }

    .colspan-3 {
      min-width: 126px;
      @include r.sp {
        border-bottom: 1px solid v.$color19;
      }
    }
  }

  .nk23_c-card01 {
    margin-top: 16px;

    @include r.sp {
      margin-top: 8px;
    }
  }

  .nk23_c-notice {
    margin-bottom: 8px;
  }

  .nk23_u-m0-sp {
    @include r.sp {
      margin-top: 8px !important;
      margin-bottom: 0 !important;
    }
  }

  .nk23_c-text01 {
    @include r.pc {
      margin-top: -8px;
      margin-bottom: 16px;
    }
  }
}

@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-race_trend_legs {

  &__sc1 {
    padding: 20px 0 40px 0;
    @include r.sp {
      padding: 10px 0 40px 0;
    }

    .nk23_c-title01 {
      margin-bottom: 20px;
      @include r.sp {
        margin-bottom: 10px;
      }

      @include r.sp {
        margin-bottom: 8px;
      }
    }
  }

}

@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-race_trend_kis {
  &1 {
    padding: 16px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }
  }

  .nk23_c-slide02 {
    margin-top: 16px;

    @include r.sp {
      margin-top: 8px;
    }
  }

  .nk23_c-box1 {
    margin-top: 16px;

    @include r.sp {
      margin-top: 8px;
    }
  }

  .nk23_c-tab2 {
    margin-top: 16px;
  }

  .nk23_c-table15 {
    &__wrap {
      table {
        td {
          padding: 8px;

          @include r.sp {
            padding: 4px;
          }
        }
      }
    }
  }
}
$base-color: #ebebeb;

$color-link: #2461ee;
$color-link-visited: #7b24ed;
$color-gray: #919599;
$colorb: #000000;
$colorw: #ffffff;
$color0: #221f1f;
$color1: #0b318f;
$color2: #b8c5e6;
$color3: #f2f4f9;
$color4: #3a993a;
$color5: #a3cca3;
$color6: #f2faf2;
$color7: #cc2a29;
$color8: #e6a1a1;
$color9: #faf2f2;
$color10: #338f8f;
$color11: #add9d9;
$color12: #f2fafa;
$color13: #e63434;
$color14: #3570e6;
$color15: #ffd83e;
$color16: #4ba642;
$color17: #f59331;
$color18: #eb6ad5;
$color19: #c9c9c9;
$color20: #f27723;
$color21: #cb6200;
$color22: #c4c4c4;
$color23: #d2d2d2;
$color24: #ccad14;
$color25: #f2ce18;
$color26: #0050a8;
$color27: #003285;
$color28: #f2f2f2;
$color29: #404040;
$color30: #1861f2;
$color31: #0d3180;
$color32: #004ea2;
$color33: #e63123;
$color34: #f7f7f7;
$color35: #b31b1b;
$color36: #ededed;
$color37: #e62222;
$color38: #add9ad;
$color39: #0a208b;
$color40: #e4e4e4;
$color41: #f5f5f5;
$color42: #d9e6ff;
$color43: #ffcccc;
$color44: #ccffcc;
$color45: #ccffff;
$color46: #c7c6c6;
$color47: #ffecb3;
$color48: #ffd966;
$color49: #ffbb00;
$color50: #f2d1ce;
$color51: #dfe8f7;
$color52: #fff9e6;
$color53: #40b324;
$color54: #e67573;
$color55: #e6e6e6;
$color56: #b935e6;
$color57: #ff0000;
$color58: #d9d9d9;
$color59: #f2ce38;
$color60: #8d1bb3;
$color61: #33991a;
$color62: #1b4db3;
$color63: #bf9f1d;
$color64: #004ea2;
$bg-yellow: #ffd83e;
$bg-green: #4ba642;
$bg-orange: #f59331;
$bg-peach: #eb6ad5;

$urawa-color: #0b318f;
$urawa-color-light: #607bbf;
$urawa-border: #b8c5e6;
$urawa-shadow: #b8c5e6;

$funabashi-color: #3a993a;
$funabashi-color-light: #64a664;
$funabashi-border: #add9ad;
$funabashi-shadow: #add9ad;

$ooi-color: #cc2a29;
$ooi-color-light: #cc5c5c;
$ooi-border: #e6a1a1;
$ooi-shadow: #e6a1a1;

$kawasaki-color: #338f8f;
$kawasaki-color-light: #53a6a6;
$kawasaki-border: #add9d9;
$kawasaki-shadow: #add9d9;

@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-download_kis {
  display: flex;
  max-width: 960px;
  margin: auto;

  #contents740 {
    width: 770px;
    background: #fff;
    padding: 15px 17px 27px !important;
    border-radius: 8px;
    font-size: 1.3rem;
    line-height: 2.1rem;

    h2 {
      margin-bottom: 16px;
    }
    h4 {
      margin-bottom: 12px;
      margin-top: 12px;
      font-weight: bold;
    }
    table {
      border-collapse: separate;
      border-radius: 8px;
      overflow: hidden;
      border-spacing: 0;
      margin-top: 33px;
      margin-bottom: 12px;
      td {
        text-align: left;
        border-left: 1px solid #c9c9c9;
        border-bottom: 1px solid #c9c9c9;
        font-size: 1.3rem;
        padding: 6px;
        vertical-align: top;
        p {
          margin: 0;
          font-weight: bold;
          margin-top: -7px;
        }
      }
      tr {
        td {
          &:last-child {
            border-right: 1px solid #c9c9c9;
            height: 97px;
          }
        }
        &:first-child {
          td {
            border-top: 1px solid #c9c9c9;
            &:first-child {
              border-radius: 8px 0 0 8px;
            }
            &:last-child {
              border-radius: 0 8px 0 0;
            }
          }
        }
        &:last-child {
          td {
            &:last-child {
              border-radius: 0 0 8px 0;
            }
          }
        }
      }
    }
    p {
      font-size: 1.3rem;
      line-height: 2.1rem;
      margin-bottom: 13px;
      &.bd-btm-ccc {
        border-bottom: 1px solid #c9c9c9;
        margin-bottom: 0;
      }
      img.img-pdf {
        max-width: 112px;
        margin-bottom: -5px;
      }
    }
    a {
      color: v.$color-link;
      @include r.hover {
        text-decoration: underline;
      }
    }
  }
}

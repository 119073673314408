@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-support_manual {
	margin: 0 auto;
	padding: 16px 16px 40px !important;
	display: flex;
	max-width: 992px;
	box-sizing: border-box;

	.contents750 {
		width: 770px;

		.contents750 {
			padding: 0;
		}
	}

	ul.tab-navigation {
		margin-top: 16px;
	}

	.title-spat4-01 {
		margin-bottom: 20px !important;
	}

	.title-spat4-02 {
		margin-bottom: 20px !important;
	}

	.mark-pdf {
		margin-bottom: 15px !important;

		&:last-child {
			margin-bottom: 0 !important;
		}
	}

	.nk23_c-box6 {
		padding-bottom: 30px;
	}
}

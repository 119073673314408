// about_us
@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-about_us {
  margin: 0 auto;
  padding: 16px 16px 40px !important;
  display: flex;
  max-width: 992px;
  box-sizing: border-box;

  #breadcrumbs {
    margin: 0 0 34px 0 !important;
  }

	#contents770 {
		#breadcrumbs {
			margin: 0 0 34px 0 !important;
		}
	}

  #contents740 {
    width: 100%;

    a {
      @include r.hover {
        opacity: 0.5;
      }
    }

    // .p-about_us figure .
    figure {
      margin: 33px 0 16px;
    }

    // .p-about_us h3 .
    h3 {
      margin-bottom: 14px;
      clear: both;
    }

  }


  [class^='box-info'] {
    width: 175px;
    display: flex;
    flex-direction: column;

    // .p-about_us [class^=box-info]:not(:last-child) .
    &:not(:last-child) {
      margin-right: 10px;
    }

    a {
      &:first-child {
        height: 99px;
        overflow: hidden;
        border: 1px solid v.$color19;
        border-bottom: 0;
        border-radius: 8px 8px 0 0;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          object-position: top center;
        }
      }

			&:nth-child(2) {
				@include r.hover {
					opacity: 1 !important;
				}
			}
    }
  }

  .box-grinfo {
    margin: 0 -5px;
    display: flex;
    align-items: flex-start;
    clear: both;

    [class^='box-info'] {
      width: 50%;
      padding: 0 5px;
      margin-right: 0 !important;
    }

    a {
      height: max-content !important;
      border-radius: 8px !important;
      border: none !important;

      img {
        width: auto !important;
        height: auto !important;
        object-fit: unset !important;
      }

      @include r.hover {
        opacity: 1 !important;
      }
    }

    .box-info365_L2 {
      margin-left: 5px;
      display: inline-block;
      margin-right: 0 !important;
      width: 177px !important;

      // .p-about_us .box-grinfo .box-info365_L2 ul .
      ul {
        margin-top: -1px;
        padding: 0 1px;
      }
    }

    .box-info365_R2 {
      margin-left: 10px;
      margin-right: 0 !important;
      width: calc(100% - 185px);
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      font-size: 1.3rem;
      line-height: 2.1rem;

      a {
        width: 100px !important;
        display: flex;
        margin-bottom: 10px;

        &:not(:nth-child(5)) {
          margin-right: 10px;
        }

        img {
          width: 100% !important;
          height: 31px !important;
          object-fit: unset !important;
        }
      }

      p {
        margin-top: -1px;
        margin-bottom: 0 !important;

        a {
          width: 100% !important;

          @include r.hover {
            opacity: 1 !important;
          }
        }
      }
    }

    .box-info365_R2,
    .box-info365_L2 {
      padding: 0 !important;

      a {
        overflow: visible !important;
        border-radius: 0 !important;

        @include r.hover {
          opacity: 0.5 !important;
        }
      }

      .box-info-btm {
        a {
          @include r.hover {
            opacity: 1 !important;
          }
        }
      }
    }

    // .p-about_us .box-grinfo+.bl-left.mB15 .
    &+.bl-left.mB15 {
      margin-top: 33px;
      width: 175px;
    }
  }

  .box-info-e {
    margin-bottom: 40px;

    &+h3 {
      margin-top: 10px;
    }
  }

  .bl-left.mB15 {
    margin-top: -3px;
    margin-bottom: 15px;
    width: 50%;

    &+.bl-right.mB15 {
      margin-top: -3px;
    }
  }

  .bl-right.mB15 {
    width: 50%;

    // .p-about_us .bl-right.mB15:nth-last-child(2) .
    &:nth-last-child(2) {
      width: calc(100% - 185px);
      margin: 33px 0 0 !important;
    }

    &:nth-child(12) {
      padding-left: 6px;
    }
  }

  .box-info-btm {
    display: flex;
    flex-direction: row;
    margin-right: 0 !important;
    width: 100% !important;

    a {
      border: none !important;
      height: max-content !important;
      font-size: 1.3rem;
      line-height: 2.1rem;

      @include r.hover {
        opacity: 1 !important;
      }
    }

    // .p-about_us .box-info-btm ul .
    ul {
      padding: 6px 6px 0;

      li {
        margin-bottom: 1px;
      }
    }

    .bl-left {
      width: 48%;
    }
  }

  .box-info365_R {

    // .p-about_us .box-info365_R ul .
    ul {
      padding: 4px 0 0;
    }

    a {
      @include r.hover {
        opacity: 0.5 !important;
      }
    }

    .box-info-btm {
      a {
        @include r.hover {
          opacity: 1 !important;
        }
      }
    }
  }

  .box-info365_L {
    a {
      @include r.hover {
        opacity: 0.5 !important;
      }
    }

    .box-info-btm {
      a {
        @include r.hover {
          opacity: 1 !important;
        }
      }
    }
  }

  #contents770a {
    padding: 15px 15px 18px !important;
    border-radius: 8px;
    margin-top: 16px;
  }

  .bl-left.mR10 {
    ul {
      padding: 4px 1px 0;
    }

    &+.bl-left {
      ul {
        padding: 4px 1px 0;
      }
    }
  }
}

@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-leading_sokuhou_cho {
  max-width: 960px;
  margin: auto;
  font-size: 1.3rem;
  width: 100% !important;

  .tb01 {
    border-radius: 8px;
    overflow: hidden;

    tr {
      th {
        border-top: 0;
      }

      th,
      td {
        font-size: 1.3rem;
      }
    }
  }

  .nk23_c-text01 {
    margin-top: 8px;
  }

  #box-search {
    margin-top: 0;
    border-radius: 0 0 8px 8px;

    .bl-left {
      small {
        font-size: 1.3rem;
      }
    }

    small {
      font-size: 1.3rem;
    }

    #order {
      min-width: 76px;
    }
  }
}

@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-izonsho {
  margin: 0 auto;
  padding: 16px 16px 40px !important;
  display: flex;
  max-width: 992px;
  box-sizing: border-box;

  #contents730 {
    width: 770px;
    padding: 15px 20px 19px !important;
    background-color: #fff;
    border-radius: 8px;
    font-size: 1.3rem;
    line-height: 2.1rem;

    p {
      font-size: 1.3rem;

      &:nth-child(2) {
        margin-bottom: 20px;
      }
    }

    a {
      margin-top: 20px;
      color: v.$color-link;

      @include r.hover {
        text-decoration: underline;
      }
    }

    h2 {
      margin-bottom: 10px;
    }
  }
}
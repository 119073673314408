@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;
.p-calc_marks {
	//--pc------------------------
	.display_pc {
		#contents {
			display: flex;
			max-width: 992px;
			margin: 16px auto 40px;
			padding: 0 16px !important;
			box-sizing: border-box;
			@include r.sp {
				flex-wrap: wrap;
				flex-direction: column-reverse;
				padding: 0 8px !important;
			}
		}
		//--layout------------------------
		#contents770b {
			padding: 14px 20px 60px !important;
			background: #fff !important;
			border-radius: 8px;
		}
		#contents730 {
			padding-top: 0 !important;
		}
		#contents740 {
			margin: 0 auto;
		}
		.bg-eff5ec {
			background: none;
		}
		//--heading------------------------
		h2 {
			margin-bottom: 6px;
		}
		h3 {
			img {
				width: 500px;
			}
		}
		//--table------------------------
		table {
			&.tb01 {
				tr th {
					background: #404040 !important;
				}
			}
		}
		#contents730 {
			table {
				font-size: 1.3rem;
				border: 1px solid v.$color19;
				.bg-f6f {
					background-color: v.$color34;
				}
				th,
				td {
					line-height: 1.4;
					img {
						margin: 0 auto;
					}
				}
			}
			//1つめのテーブル
			h2 + h3 + table {
				margin-bottom: 35px;
				font-size: 1.3rem;
				&:nth-of-type(1) {
					tr th:first-child,
					tr td:first-child {
						width: 151px;
					}
				}
			}
			//２つめのテーブル
			h2 + h3 + table + h3 + table {
				margin-bottom: 35px;
				tr th:first-child,
				tr td:first-child {
					width: 125px;
				}
			}
			//３つめのテーブル
			h2 + h3 + table + h3 + table + h3 + table {
				margin-bottom: 30px;
				tr th:first-child,
				tr td:first-child {
					width: 125px;
				}
			}
			.clearfix {
				&:first-of-type {
					margin-bottom: 35px;
				}
				.bg-e8e {
					color: #fff;
					background-color: #404040 !important;
					td {
						font-weight: 700;
					}
				}
				.w300px {
					//4つめのテーブル
					table {
						thead {
							td {
								height: 40px;
							}
						}
						th,
						td {
							padding: 2px 4px;
						}
						tr th:first-child,
						tr td:first-child {
							width: auto;
						}
						tr td:first-child {
							width: 40px;
							font-weight: 700;
						}
						tr td:nth-child(2) {
							width: 40px;
						}
						tr td:nth-child(3) {
							width: 40px;
						}
						tr td:nth-child(4) {
							width: 51px;
						}
						tr td:nth-child(5) {
							width: 51px;
						}
						tr td:nth-child(6) {
							width: 51px;
						}
						tr td:nth-child(7) {
						}
					}
				}
				.w200px {
					//5つめのテーブル
					table {
						table {
							tr th:first-child,
							tr td:first-child {
								width: 120px;
							}
						}
					}
				}
				.w100px {
					table {
						th,
						td {
							padding: 2px 8px;
						}
					}
					#ent_tbl_n1,
					#ent_tbl_n2,
					#ent_tbl_n3 {
						.nk23_c-checkbox__label {
							width: auto;
							font-size: 1.2rem;
							color: #fff;
						}
					}
				}
			}
			// width
			.mR50 {
				margin-right: 50px;
			}
			.pT55 {
				padding-top: 55px;
			}
			.w60pr {
				width: 60%;
			}
			.h30px {
				height: 30px;
			}
			.h45px {
				height: 45px;
			}
			.w100px {
				width: 100px;
			}
			.w120px {
				width: 120px;
			}
			.w200px {
				width: 200px;
			}
			.w300px {
				width: 300px;
			}
			//table border radius
			table {
				border-collapse: separate;
				overflow: hidden;
				border-spacing: 0;
				border-radius: 8px;
				border: 1px solid v.$color19;
				th,
				td {
					border-right: none !important;
					border-bottom: none !important;
					border-right: 1px solid v.$color19;
					border-bottom: 1px solid v.$color19;
					&:last-child {
						border-right: none;
					}
				}
				tr {
					&:first-child {
						th,
						td {
							border-top: none !important;
						}
					}
					&:last-child {
						th,
						td {
							border-bottom: none;
						}
					}
				}
			}
		}
	}
	//sp pc common----------------------------------
	select {
		padding: 0 15px 0 4px;
		width: 76px;
		height: 19px;
		border-radius: 4px;
		border: 1px solid v.$color19;
		text-align: center;
		line-height: 1;
		font-size: 1.3rem;
		background-image: none !important;
		background: #fff url("/assets/images/common/icon/icon_down.svg") no-repeat center right 4px / 10px auto !important;
		color: v.$color0;
		@include r.sp {
			width: 100%;
			height: 35px;
			font-size: 1.4rem;
			border-radius: 8px;
			text-align: left;
			padding: 0 19px 0 10px;
			background: #fff url(/assets/images/common/icon/icon_down.svg) no-repeat center right 6px/10px auto !important;
		}
	}
	#triple-s-r1,
	#triple-s-r2,
	#triple-s-r3,
	#triple-s-r1_sp,
	#triple-s-r2_sp,
	#triple-s-r3_sp {
		margin-top: 0;
		width: 52px;
		height: 17px;
		@include r.sp {
			font-size: 1.9rem;
			width: 100%;
			height: 42px;
		}
	}
	//トリプルの下の文字
	.h55px {
		font-size: 1.3rem;
		margin-bottom: 15px;
	}
	//form parts------------
	.nk23_c-checkbox input,
	.nk23_c-checkbox2 input {
		position: static;
	}
	.nk23_c-checkbox__label {
		padding-left: 0 !important;
		display: block !important;
		width: 13px;
		height: 13px;
		min-height: 13px;
		margin: 0 auto;
		position: static;
		line-height: 0;
		@include r.sp {
			width: 20px;
			height: 20px;
			min-height: 20px;
		}
		input {
			width: 13px;
			height: 13px;
			background-size: 8px auto;
			&:checked {
				background-size: 8px auto;
				background-position: center top 3px;
				@include r.sp {
					background-size: 10px auto;
					background-position: center top 6px;
				}
			}
			@include r.sp {
				width: 20px;
				height: 20px;
				background-size: 10px auto;
				background-position: center top 6px;
			}
		}
	}
	.nk23_c-checkbox2__label {
		position: static;
		padding-left: 0 !important;
		line-height: 0;
		@include r.sp {
			width: 30px !important;
		}
		input {
			width: 24px !important;
			height: 24px !important;
			background-size: 15px auto;
			&:checked {
				background-size: 15px auto;
				background-position: center top 5px;
			}
			@include r.sp {
				width: 30px !important;
				height: 30px !important;
				background-size: 15px auto !important;
				background-position: center top 8px !important;
			}
		}
	}
	//数字入りのチェックボックス
	#ent_tbl_f1,
	#ent_tbl_f2,
	#ent_tbl_f3,
	#ent_tbl_f1_sp,
	#ent_tbl_f2_sp,
	#ent_tbl_f3_sp {
		.nk23_c-checkbox2 {
			&__label {
				width: 24px;
				padding-left: 0;
				display: block;
				margin: 0 auto;
				@include r.sp {
					width: 30px;
				}
			}
			input {
				top: 0;
				font-size: 1.3rem;
				font-weight: 700;
				line-height: 2.4;
				@include r.sp {
					font-size: 1.4rem;
				}
				&::before {
					margin-top: -2px;
					margin-left: -1px;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					vertical-align: middle;
					width: 24px;
					height: 24px;
					@include r.sp {
						margin-top: -1px;
						width: 30px;
						height: 30px;
					}
				}
				&[value="1"] {
					&::before {
						content: "1";
					}
				}
				&[value="2"] {
					&::before {
						content: "2";
					}
				}
				&[value="3"] {
					&::before {
						content: "3";
					}
				}
				&[value="4"] {
					&::before {
						content: "4";
					}
				}
				&[value="5"] {
					&::before {
						content: "5";
					}
				}
				&[value="6"] {
					&::before {
						content: "6";
					}
				}
				&[value="7"] {
					&::before {
						content: "7";
					}
				}
				&[value="8"] {
					&::before {
						content: "8";
					}
				}
				&[value="9"] {
					&::before {
						content: "9";
					}
				}
				&[value="10"] {
					&::before {
						content: "10";
					}
				}
				&[value="11"] {
					&::before {
						content: "11";
					}
				}
				&[value="12"] {
					&::before {
						content: "12";
					}
				}
				&[value="13"] {
					&::before {
						content: "13";
					}
				}
				&[value="14"] {
					&::before {
						content: "14";
					}
				}
				&[value="15"] {
					&::before {
						content: "15";
					}
				}
				&[value="16"] {
					&::before {
						content: "16";
					}
				}
				&:checked {
					background-image: none;
					color: #fff;
				}
			}
		}
	}
	//----フォーメーション
	#ent_tbl_n1,
	#ent_tbl_n2,
	#ent_tbl_n3,
	#ent_tbl_n1_sp,
	#ent_tbl_n2_sp,
	#ent_tbl_n3_sp {
		.nk23_c-checkbox__label {
			padding-left: 0 !important;
			color: #fff;
			font-size: 1.3rem;
			width: auto;
			@include r.sp {
				width: 20px !important;
				height: 30px;
				min-height: 37px;
				line-height: 1;
			}
		}
		th {
			width: 54px;
			@include r.sp {
				width: auto;
			}
		}
	}
	//--sp----------------------------
	.display_sp {
		//--layout------------------------
		#nav-info2 {
			padding: 0 8px !important;
		}
		article {
			margin-bottom: 40px;
			.w30px {
				width: 30px;
			}
			.mB10 {
				margin-bottom: 10px;
			}
			.h65px {
				height: 65px;
			}
			//--title-------------------------------
			.title01 {
				margin-top: 11px;
				margin-bottom: 9px;
				font-size: 1.8rem;
				text-align: center;
			}
			//--navi-------------------------------
			.list_btn_square {
				width: 100%;
				margin-bottom: 11px;
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				margin-left: -2px;
				transition: none;
				height: 59px;
				li {
					flex-basis: 25%;
					&:not(:last-child) {
						margin-right: 2px;
					}
					a {
						height: 51px;
						font-size: 1.4rem;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
						border-top-left-radius: 8px;
						border-top-right-radius: 8px;
						background-color: #fff;
						transition:
							color 0.3s ease,
							background-color 0.3s ease;
					}
					&.crt {
						a {
							height: 59px;
							color: #fff;
							background-color: #221f1f;
						}
					}
				}
			}
			//--table--------------
			table {
				table-layout: fixed;
				&.sticky_area {
					margin-bottom: 10px;
					tr.head_a {
						th {
							font-size: 1.4rem;
						}
					}
					tr:not(.head_a) td {
						font-size: 1.2rem;
						text-align: center;
					}
				}
				thead {
					tr:nth-child(2) th {
						background: #f7f7f7;
						color: #221f1f;
					}
				}
				tr {
					td {
						text-align: right;
						background-color: #fff;
						&:first-child {
							font-weight: 700;
						}
					}
					.bg_333 {
						background-color: #404040;
						color: #fff;
						text-align: center;
					}
					.bg_f6f {
						background-color: #f7f7f7;
					}
				}
			}
			//--ボックス-------------------
			#change_b {
				table {
					th {
						height: 64px;
					}
					td {
						padding: 4px 10px 4px 3px;
						height: 30px;
					}
				}
			}
			//--流し-------------------
			#change_n {
				table {
					td {
						padding: 4px 10px 4px 3px;
						height: 30px;
					}
				}
			}
			//--フォーメーション-------------------
			#change_f {
				table {
					.head_a th {
						background-color: #fff;
						color: #221f1f;
					}
					th:first-child {
						width: 30px;
					}
					td {
						img {
							margin: 0 auto;
						}
					}
					.h50px {
						height: 50px;
						font-weight: 700;
						color: #fff;
						text-align: center;
					}
					.bg_1 {
						background: #fff;
						color: v.$color0;
					}
					.bg_2 {
						background: v.$color0;
					}
					.bg_3 {
						background: v.$color13;
					}
					.bg_4 {
						background: v.$color14;
					}
					.bg_5 {
						background: v.$bg-yellow;
					}
					.bg_6 {
						background: v.$bg-green;
					}
					.bg_7 {
						background: v.$bg-orange;
					}
					.bg_8 {
						background: v.$bg-peach;
					}
				}
			}
			//--トリプル馬単-------------------
			#change_t {
				#ent_tbl_b2_sp td {
					text-align: center;
				}
				#ent_tbl_n1_sp,
				#ent_tbl_n2_sp,
				#ent_tbl_n3_sp {
					table {
						thead {
							th:last-child {
								.nk23_c-checkbox {
									display: flex;
								}
							}
						}
					}
				}
				//select
				#ent_tbl_n2_sp,
				#ent_tbl_n3_sp {
					td {
						text-align: center;
					}
					.nk23_c-checkbox__label {
						padding-left: 0 !important;
						color: #fff;
						font-size: 1.3rem;
						width: auto;
					}
				}
				table {
					.head_a th {
						background-color: #fff;
						color: #221f1f;
					}
				}
				.tripleumatan_arae {
					table {
						td {
							height: 40px;
						}
					}
					display: flex;
					justify-content: space-between;
					margin-left: 28px;
					margin-right: 28px;
					gap: 10px;
					.w100px {
						width: 100px;
						min-width: 100px;
					}
				}
				.bl_left {
					//table border radius
					table {
						border-collapse: separate;
						overflow: hidden;
						border-spacing: 0;
						border-radius: 8px;
						border: 1px solid v.$color19;
						th,
						td {
							border-right: none !important;
							border-bottom: none !important;
							border-right: 1px solid v.$color19;
							border-bottom: 1px solid v.$color19;
							&:last-child {
								border-right: none;
							}
						}
						tr {
							&:first-child {
								th,
								td {
									border-top: none !important;
								}
							}
							&:last-child {
								th,
								td {
									border-bottom: none;
								}
							}
						}
					}
				}
			}
		}
		//--variation page------------
		.variation_title01 {
			margin-bottom: 40px;
			padding: 7px 7px 7px 16px;
			font-size: 1.8rem;
			background-color: v.$color0;
			color: #fff;
		}
	}
	//--print-------------
	@media print {
		.clearfix .bg-e8e {
			color: #fff !important;
		}
		.print_flex {
			display: flex;
		}
		.print_pT70 {
			padding-top: 70px;
		}
	}
}

@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-leading_sokuhou_kis {
  max-width: 960px;
  margin: auto;
  font-size: 1.3rem;

  .tb01 {
    border-radius: 8px;
    overflow: hidden;

    tr {
      th {
        border-top: 0;
        &:nth-of-type(2) {
          min-width: 200px;
        }
      }
    }
  }

  .nk23_c-text01 {
    margin-top: 8px;
  }

  #box-search {
    margin-top: 0;
    border-radius: 0 0 8px 8px;
    small {
      font-size: 1.3rem;
    }
  }
}

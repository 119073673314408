@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-odds_uma {
  &1 {
    padding: 16px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }
  }

  .nk23_c-tab2 {
    margin-top: 0;

    @include r.sp {
      margin-top: 16px;
    }

    &__content {
      .nk23_c-tab2__content {
        padding-top: 0;
      }
    }
  }

  .nk23_c-block13 {
    &:after {
      content: '';
      background: rgba($color: #221f1f, $alpha: 0.75);
      width: 100%;
      height: calc(100% + 40px);
      position: absolute;
      left: 0;
      top: 100px;
      opacity: 0;
      z-index: -1;
    }

    &.is-open {
      &:after {
        opacity: 1;
        z-index: 0;
      }
    }
  }

  .nk23_c-table03 {
    margin-top: 16px;
  }

  .nk23_c-title01 {
    margin-bottom: 16px;
  }

  .nk23_c-selectbox2__select {
    border: 1px solid#221F1F;
  }
}

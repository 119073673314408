// @use '../settings/_variable' as v;
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700;900&family=Noto+Sans+Mono:wght@300;400;500;600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: "Noto Sans JP";
  font-display: swap;
  src: url('/assets/fonts/noto_sans_jp/NotoSansJP-Regular.woff');
  font-weight: 400;
}

@font-face {
  font-family: "Noto Sans JP";
  font-display: swap;
  src: url('/assets/fonts/noto_sans_jp/NotoSansJP-Bold.woff');
  font-weight: bold;
}

@font-face {
  font-family: "Noto Sans JP";
  font-display: swap;
  src: url('/assets/fonts/noto_sans_jp/NotoSansJP-Bold.woff');
  font-weight: 700;
}

@font-face {
  font-family: "Noto Sans Mono";
  font-display: swap;
  src: url('/assets/fonts/noto_sans_mono/NotoSansMono-Regular.woff');
}

@font-face {
  font-family: "Noto Sans Mono";
  font-display: swap;
  src: url('/assets/fonts/noto_sans_mono/NotoSansMono-Bold.woff');
  font-weight: bold;
}

@font-face {
  font-family: "Noto Sans Mono";
  font-display: swap;
  src: url('/assets/fonts/noto_sans_mono/NotoSansMono-Bold.woff');
  font-weight: bold;
}

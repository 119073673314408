@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-course_menu {
	max-width: 960px;
	margin: 0 auto;

	.tl-main {
		text-align: center;
		display: flex;
		justify-content: center;
		margin-bottom: 16px;
	}

	.title01 {
		text-align: center;
		display: flex;
		justify-content: center;
		margin-bottom: 8px;
	}

	.mB15 {
		display: flex;
		margin: 0 -10px;

		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	.twoColEq_L,
	.twoColEq_R {
		width: calc(50% - 10px);
		margin: 0 10px;
		background: #ffffff;
		border-radius: 8px;
		overflow: hidden;
		border: 1px solid v.$color19;
		box-shadow: 0 2px 0 v.$color19;
	}

	.btn-course_info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 1.6rem;
		font-weight: bold;

		.bl-left {
			padding: 6px;
			&.mT5 {
				margin-top: 0;
			}
		}

		.bl-right {
			width: 197px;
			display: inline-block;
		}

		img.bl-left {
			padding: 0;
			width: 36px;
		}

		.fnb {
			padding: 2px !important;
		}

		&.clearfix {
			&::after {
				content: none;
			}
		}

		.pT20 {
			display: flex;
			align-items: center;

			.bl-left.mT5 {
				padding-left: 10px;
			}
		}
	}

	@include r.sp {
		padding: 16px 8px 40px;

		#contents {
			padding: 0 !important;
		}

		.list_jyologo {
			background: #ffffff;
			border-radius: 8px;

			li {
				position: relative;
				padding: 6px;
				// display: flex;
				// align-items: center;
				font-size: 1.6rem;
				font-weight: bold;

				&:not(:last-child) {
					border-bottom: 1px solid v.$color19;
				}

				a {
					position: relative;
					display: flex;
					align-items: center;
					width: 100%;

					&::before {
						content: "";
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 52px;
						height: 52px;
						border: 1px solid v.$color19;
						border-radius: 5px;
						margin-right: 5px;
					}

					&::after {
						width: 6px;
						height: 9px;
						right: 8px;
						top: calc(50% - 4px);
						background: url(p.$path + "images/common/icon/icon_arrow-right.svg") no-repeat center;
						background-size: 100%;
						content: "";
						position: absolute;
					}
				}

				&.urw {
					a {
						&::before {
							background: url(p.$path + "images/common/ico-urw.svg") no-repeat center / 40px;
						}
					}
				}

				&.fnb {
					a {
						&::before {
							background: url(p.$path + "images/common/ico-fnb.svg") no-repeat center / 34px;
						}
					}
				}

				&.tck {
					a {
						&::before {
							background: url(p.$path + "images/common/ico-tck.svg") no-repeat center / 40px;
						}
					}
				}

				&.kws {
					a {
						&::before {
							background: url(p.$path + "images/common/ico-kws.svg") no-repeat center / 40px;
						}
					}
				}
			}
		}
	}
}

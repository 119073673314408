@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-deme_umaban {
  padding: 16px 0 40px;
  max-width: 960px;
  margin: auto;
  font-size: 1.3rem;

  .tb01 {
    border-radius: 8px;
    overflow: hidden;

    tr {
      &.bg-ebf {
        td {
          font-weight: 700;
          background: #F7F7F7;
        }
      }

    }
  }
}
@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-shiken_list {
  &1 {
    padding: 20px 0 40px;

    .nk23_c-title01 {
      margin-bottom: 20px;
    }

    .nk23_c-link {
      margin: 16px 0;
    }

    .nk23_c-table24__icon {
      @include r.sp {
        top: 160px;
        transform: translate(-50%, 0);
      }
    }
    .nk23_c-table24 {
      min-width: auto;
      @include r.sp {
        min-width: auto;
        thead {
          th {
            min-width: auto;
          }
        }
      }
    }
  }
}

@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-grace_list {
  &1 {
    padding: 16px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }

    .nk23_c-filter4 {
      margin-bottom: 16px;
    }

    .nk23_c-title01 {
      margin-bottom: 16px;

      @include r.sp {
        margin-bottom: 8px;
      }
    }

    .nk23_c-table01__title {
      font-size: 2rem;

      @include r.sp {
        font-size: 1.6rem;
      }
    }

    .nk23_c-table01__table {
      td {
        padding: 4px;
      }
    }

    .nk23_c-table01__icon {
      @include r.sp {
        top: 160px;
        transform: translate(-50%, 0);
      }
    }
  }
}
@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-deme {
  #contents950 {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }

  .tb01 {
    td {
      &:not([class*='bg-']) {
        background-color: #fff;
      }
    }
  }

  .tl-main {
    text-align: center;

    img {
      display: inline-block;
    }
  }

  #box-search {
    margin-top: 16px;

    select {
      min-width: 53px;
      padding-right: 8px;
    }

    .bl-left {
      max-width: max-content;
    }
  }
}

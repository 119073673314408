@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-about_racetrack {
	margin: 0 auto;
	padding: 16px 16px 40px !important;
	display: flex;
	max-width: 992px;
	box-sizing: border-box;

	#contents770b {
		background: #ffffff !important;
		border-radius: 8px;
		padding: 15px 20px 20px !important;

		h2 {
			margin-bottom: 33px;
		}
	}

	#contents730 {
		padding-top: 0 !important;
	}

	#ls-qa-racecourse,
	.box-Q,
	.box-A {
		max-width: 690px;
		margin: auto !important;
	}

	.box-Q {
		margin-bottom: 15px !important;
	}

	.box-A {
		p + p {
			margin-left: 37px;
		}

		strong {
			font-weight: normal;
		}
	}

	#ls-qa-racecourse {
		li {
			display: flex;
		}
	}

	.link-pagetop {
		margin-top: 15px;
	}

	#qa05 {
		& + .box-Q {
			& + .box-A {
				p {
					&:last-child {
						display: flex !important;
						flex-wrap: nowrap;
						align-items: flex-start;
						white-space: nowrap;

						a {
							white-space: nowrap;
							word-break: keep-all;
							width: calc(100% - 155px);
						}
					}
				}
			}
		}
	}
}

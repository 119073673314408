@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

#wrapper {
	border-top: 0;

	.p-liveon {
		border-top: 0;
		background: transparent;
		padding: 16px 8px 40px;

		@include r.sp {
			padding: 16px 0 40px;
		}
	}
}

.p-liveon {
	.navLink {
		padding-bottom: 8px;
		padding-left: 4px;
		padding-right: 4px;
		margin: 8px 0;

		@include r.sp {
			margin: 16px 0 16px 8px;
			width: calc(100% - 16px);
		}

		li {
			width: calc(33.3333% - 8px);
			margin-left: 4px;
			margin-right: 4px;

			@include r.sp {
				width: calc(25% - 8px);
			}

			&.crt {
				//margin-bottom: 8px !important;
				transform: translateY(2px);

				a {
					padding: 2px 0 0;
				}

				span {
					color: #fff;
				}
			}
		}

		span {
			padding-top: 0 !important;
			color: v.$color0;
			background-position-y: -50px !important;
		}
	}

	.bl-left {
		a {
			@include r.hover {
				text-decoration: underline;
			}
		}
	}

	h3.tl-main {
		background: none;
		border-top: 0;
		margin-bottom: 16px;

		img {
			margin: auto;
			width: 180px;
		}
	}

	.nk23_c-text01 {
		margin-top: 16px;
		text-indent: 0;

		@include r.sp {
			margin: 16px 0;
			font-size: 1.6rem;
		}
	}

	#telop {
		background: none;

		@include r.sp {
			margin-bottom: 0;
		}

		#telopList {
			font-size: 2rem;
			color: #e63434;

			@include r.sp {
				font-size: 1.6rem;
			}

			ul {
				li {
					top: 0;
				}
			}
		}
	}

	.clearfix {
		@include r.sp {
			padding: 0 8px;
		}

		&.pT5 {
			@include r.sp {
				margin-top: 8px;
			}
		}
	}

	.ico-22 {
		color: #2461ee;
		font-size: 1.3rem;
		background: url(/shared/images/icon/ico-22.gif) no-repeat left center;
	}

	#new {
		padding-top: 4px;

		a {
			color: #2461ee;
		}
	}

	.qselect {
		@include r.sp {
			float: none;
			width: 100%;
		}

		label {
			font-size: 1.4rem;
			display: inline-block;

			@include r.sp {
				display: block;
				font-size: 1.6rem;
			}
		}

		select {
			width: auto;
			border: 1px solid #c9c9c9;
			border-radius: 4px;
			padding-right: 30px;
			font-size: 1.3rem;
			background: #fff url(p.$path + "images/common/icon/icon_down.svg") no-repeat center right 7px/10px auto !important;

			@include r.sp {
				width: 100%;
				border-radius: 8px;
				font-size: 1.6rem;
				padding: 8px;
			}
		}
	}

	#new {
		.tx-12px {
			@include r.sp {
				font-size: 1.6rem;
			}
		}
	}

	.line {
		margin-top: 16px;
	}

	#popup_close {
		border-radius: 999px;
		width: calc(100% - 16px);
		min-height: 35px;
		border: 1px solid #c9c9c9;
		box-shadow: 0 2px 0 #c9c9c9;
		background-color: #fff;
		position: relative;
		font-size: 1.4rem;
		font-weight: 700;
		color: #221f1f;
		margin: auto;
		@include r.sp {
			margin-top: 16px;
		}
	}
}

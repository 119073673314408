@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-horse_bunch_info {
  margin: 0 auto;
  padding: 16px 16px 40px !important;
  display: flex;
  max-width: 992px;
  box-sizing: border-box;

  #contents730 {
    width: 770px !important;
    padding: 15px 20px 20px !important;
    background-color: #fff;
    border-radius: 8px;

    h2 {
      margin-bottom: 16px;
    }

    h3 {
      font-size: 1.4rem;
      margin-top: 12px;
      margin-bottom: 13px;
    }

    p {
      font-size: 1.3rem;

    }

    .bl-right {
      &:nth-child(2) {
        p {
          margin-top: 0;
        }
      }

      &:nth-child(5) {
        p {
          margin-top: 12px;
        }
      }

      p {
        margin-top: 11px;
      }

    }

    .bl-right,
    .bl-left {
      @include r.clear;
    }

    .tb-sell-fcl {
      margin: 12px 0 38px;
      display: inline-table;

      &:last-child {
        margin: 13px 0 0;
      }

      th,
      td {
        padding: 3px 4px;
      }

      tbody {
        th {

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            width: auto;
            min-width: auto;
          }

          &:nth-child(3) {
            width: 84px;
          }

          &:first-child {
            border-top-left-radius: 8px;
          }

          &:last-child {
            border-top-right-radius: 8px;
          }
        }

        tr {
          &:last-child {
            td {
              &:first-child {
                border-bottom-left-radius: 8px;
              }

              &:last-child {
                border-bottom-right-radius: 8px;
              }
            }
          }
        }
      }
    }
  }
}
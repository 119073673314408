@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-contact_conp {
  display: flex;
  max-width: 960px;
  margin: auto;

  #contents730 {
    background: #fff;
    padding: 15px 20px 27px !important;
    border-radius: 8px;
    font-size: 1.3rem;
    line-height: 2.1rem;

    h2 {
      margin-bottom: 16px;
    }
    h3.tl-h3 {
      border-top: 1px solid v.$color19;
      border-bottom: 1px solid v.$color19;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 24px;
      border-radius: 0;
      padding: 0;
      margin-bottom: 8px;
      margin-top: 0;
    }
    h3 {
      margin-top: 37px;
      margin-bottom: 12px;
    }
    table.tb00-i {
      border-collapse: collapse;
      border-spacing: 0;
      margin-top: 33px;
      margin-bottom: 12px;
      width: 100%;
      tr {
        background: #f7f7f7;
        &:nth-child(2n) {
          background: #fff;
        }
        &:first-child {
          input {
            width: 242px;
          }
        }
        &:nth-child(2) {
          input {
            width: 480px;
          }
        }
        &:nth-child(3) {
          input {
            width: 207px;
          }
        }
      }
      td,
      th {
        text-align: left;
        font-size: 1.3rem;
        padding: 6px;
        input {
          border: 1px solid v.$color19;
          border-radius: 8px;
          background: #fff;
          padding: 2px 5px;
        }
        textarea {
          border: 1px solid v.$color19;
          border-radius: 8px;
          background: #fff;
          padding: 2px 5px;
          width: 334px;
          height: 152px;
        }
      }
    }
    p {
      font-size: 1.3rem;
      line-height: 2.1rem;
      margin-bottom: 13px;
      &.al-center {
        text-align: center;
        display: block;
        a {
          img {
            width: 121px;
          }
        }
      }
    }
    a {
      color: v.$color-link;
      @include r.hover {
        text-decoration: underline;
      }
    }
    .tx-red01 {
      color: #e63434;
    }
  }
}

@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-pattern_cho {
	&__sc1 {
		padding: 16px 0 40px;

		@include r.sp {
			padding-top: 8px;
		}

		.nk23_c-title01 {
			margin-bottom: 16px;
		}
	}

	.nk23_c-tab2 {
		margin-top: 16px;

		&__content {
			.nk23_c-tab2__content {
				padding-top: 0;
			}
		}

		&__subnavi__item {
			@include r.sp {
				margin-bottom: 16px;
			}
		}
	}

	.nk23_c-tab7 {
		margin-bottom: 8px;
		background-color: transparent;

		&__top {
			background-color: #ffffff;
		}

		&__navi {
			&__item {
				margin-left: 8px;
				margin-right: 8px;
			}
		}
	}

	.nk23_c-table13 {
		&__table {
			margin-bottom: 12px;
		}

		thead td {
			font-weight: normal;
		}
	}

	.nk23_c-table13__inner {
		padding-bottom: 0;

		.scrollbar-track-x,
		.scrollbar-thumb {
			height: 4px;
		}
	}

	.nk23_c-table13__icon {
		@include r.sp {
			top: 74px;
		}
	}

	.js-tab2 {
		.nk23_l-container1 {
			@include r.sp {
				padding: 0;
			}
		}
	}

	.nk23_c-filter2 {
		margin-bottom: 0;
		border-radius: 8px 8px 0 0;

		@include r.sp {
			margin-bottom: 8px;
			border-radius: 8px;
		}
	}

	.nk23_c-text01.is-style1 {
		margin-bottom: 8px;

		@include r.sp {
			margin: 0 8px 8px;
		}
	}

	.nk23_c-modal1 {
		.nk23_c-table13__inner {
			padding-bottom: 8px;
		}

		.nk23_c-link {
			padding-left: 0;

			@include r.sp {
				font-size: 1.4rem;
			}
		}

		.nk23_c-title01 {
			@include r.max-w(320px) {
				font-size: 1.6rem;
			}
		}

		.nk23_c-listcheck {
			@include r.pc {
				margin-bottom: 4px;
			}
		}
	}
}

@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-spat4_guide {
  margin: 0 auto;
  padding: 16px 16px 40px !important;
  display: flex;
  max-width: 992px;
  box-sizing: border-box;

  .nk23_c-box4 {
    margin: 16px 0;
  }

  .contents750 {
    width: 770px;

    .contents750 {
      padding: 0;
      width: 100%;
    }

    .tb00 {
      tr {
        td {
          img {
            height: 75px;
          }
        }
      }
    }
  }

  .nk23_c-list14 {
    margin-top: 16px;
  }

  .bg-spat4-01 {
    margin-top: 16px;
    background-image: none !important;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 10px;

    a {
      @include r.hover {
        border: none !important;
        background: none !important;
        opacity: 0.9;
      }

      img {
        @include r.hover {
          opacity: 1;
        }
      }
    }
  }

  .contents750 {
    &.pT20 {
      border-radius: 8px;
      padding: 20px 9px 30px;
      background-color: #fff;
    }
  }
}
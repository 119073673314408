@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-telephone_service {
	margin: 0 auto;
	padding: 16px 16px 40px !important;
	display: flex;
	max-width: 992px;
	box-sizing: border-box;
	img {
		&.w-121 {
			max-width: 121px;
		}
		&.w-316 {
			max-width: 316px;
		}
		&.w-621 {
			max-width: 621px;
		}
	}

	#contents740 {
		width: 100%;
	}
	#contents770b {
		background: #ffffff !important;
		padding: 15px !important;
		border-radius: 8px;
	}

	ul.tab-navigation {
		margin-top: 16px;
	}

	.title-spat4-01 {
		margin-bottom: 16px !important;
	}
	.bg-f2f {
		background: #f7f7f7;
		border-radius: 8px;
		padding: 21px 16px 13px;
		margin-top: 10px;
		font-size: 1.3rem;
		line-height: 21px;
		&:nth-of-type(2) {
			padding-bottom: 8px;
		}
		p {
			img {
				margin: 0 0 10px;
			}
		}
		.font-5 {
			font-size: 2.4rem;
			font-weight: bold;
			line-height: 38px;
		}
		.font-3 {
			font-size: 1.6rem;
			margin-bottom: 13px;
			display: inline-block;
		}
		span {
			strong {
				margin-right: 5px;
				margin-bottom: 2px;
				display: inline-block;
			}
		}
		.txt-note {
			color: #e63434;
			border: solid 1px #e63434;
			padding: 2px 10px;
			border-radius: 4px;
			word-break: keep-all;
			white-space: nowrap;
		}
	}
	.bd-ccc {
		border: 1px solid #c9c9c9;
	}
	p {
		img {
			margin-top: 16px;
			margin-bottom: 14px;
		}
	}
}

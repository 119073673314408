@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-race_detail_search {
  #contents950 {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .nk23_c-box3 {
    margin-top: 16px;
  }

  #tab-select,
  .al-center,
  form,
  p,
  .round-cnr {
    max-width: 960px;
    margin: 0 auto;
  }

  .round-cnr {
    padding: 4px 8px;
    font-size: 1.3rem;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include r.sp {
      font-size: 1.2rem;
    }

    .bl-left,
    .bl-right {
      float: none;
    }

    .bl-right {
      margin-left: auto;
    }
  }

  .bg-2line-btm {
    font-size: 1.3rem;

    .tx-bold {
      font-size: 1.8rem;
      line-height: 2.7rem;
      color: #333;
    }
  }

  .nk23_c-text01 {
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &.is-style5 {
      margin-bottom: 0;
    }
  }

  // .p-race_detail_search .nk23_u-btns .
  .nk23_u-btns {
    margin: 16px 0 4px;

    @include r.sp {
      margin-bottom: 0;
    }
  }

  // .p-race_detail_search .title01 .
  .title01 {
    @include r.sp {
      text-align: center;
      margin-bottom: 8px;
    }
  }

  .nk23_c-tab5 {
    &.is-style4 {
      .nk23_c-tab5__content {
        @include r.sp {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
  }

  .tb01 {
    @include r.pc {
      overflow: hidden;
      border-radius: 8px;
    }

    @include r.sp {
      min-width: 1200px !important;
      table-layout: unset !important;
    }

    & + .round-cnr {
      margin-top: 24px;
    }

    th {
      width: 1%;
      min-width: 90px;
    }

    td {
      text-align: left;
    }

    tr {
      &:first-child {
        th,
        td {
          border-top: none;
        }
      }

      &:last-child {
        th {
          border-bottom: none;
        }
      }
    }

    &.stripe {
      th {
        &:nth-child(1) {
          width: 1%;
          min-width: 82px;
        }

        &:nth-child(2) {
          width: 1%;
          min-width: 51px;
        }

        &:nth-child(3) {
          width: 1%;
          min-width: 30px;
        }

        &:nth-child(4) {
          width: 1%;
          min-width: 169px;
        }

        &:nth-child(5) {
          width: 1%;
          min-width: 49px;
        }

        &:nth-child(6) {
          width: 1%;
          min-width: 40px;
        }

        &:nth-child(7) {
          width: 1%;
          min-width: 25px;
        }

        &:nth-child(8) {
          width: 1%;
          min-width: 43px;
        }

        &:nth-child(9) {
          width: 1%;
          min-width: 51px;
        }

        &:nth-child(10) {
          width: 1%;
          min-width: 90px;
        }

        // .p-race_detail_search_result .tb01.stripe th:nth-child(10) .
        &:nth-child(10) {
          width: 1%;
          min-width: 84px;
        }

        &:nth-child(11) {
          width: 1%;
          min-width: 151px;
        }

        &:nth-child(12) {
          width: 1%;
          min-width: 69px;
        }

        &:nth-child(13) {
          width: 1%;
          min-width: 53px;
        }

        &:nth-child(14) {
          width: 1%;
          min-width: 90px;
        }
      }

      td {
        &:nth-child(2) {
          text-align: center !important;
        }

        &:nth-child(3) {
          text-align: left !important;
        }
      }

      .rirekiRacename,
      a {
        max-width: 160px;
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        white-space: nowrap;
        img {
          margin: 0 auto;
        }
      }
    }
  }

  .detail_search_note {
    @include r.sp {
      font-size: 1.3rem;
    }

    span {
      @include r.sp {
        font-size: 1.2rem;
      }
    }
  }
}

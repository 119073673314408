@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-my_index {
  padding: 16px 0 40px;

  .nk23_l-container {
    @include r.sp {
      padding: 0;
    }
  }

  .nk23_c-block06 {
    margin-bottom: 8px;

    @include r.sp {
      border-radius: 0;
    }

    &__wrap {
      font-size: 1.4rem;
      padding: 16px;
    }

    &--nobd {
      .nk23_c-block06__ttl {
        border-bottom: 0;
        padding: 12px 16px;
      }
    }

    &__ttl {
      @include r.sp {
        padding: 10px 16px 8px;
      }

      &__txt {
        padding-right: 0;
      }

      &__link {
        max-width: initial;
        margin-top: 4px;
      }
    }
  }

  .nk23_c-title01 {
    margin-bottom: 16px;
  }

  .nk23_c-table01 {
    &__inner {
      padding-bottom: 8px;
    }

    &--style3 {
      thead {
        th {
          color: #fff;
          background: #404040;

          &:nth-child(1) {
            @include r.sp {
              width: 31px;
            }
          }

          &:nth-child(2) {
            @include r.sp {
              width: 58%;
            }
          }
        }
      }

      td {
        text-align: center;

        &:nth-child(2) {
          text-align: left !important;
        }
      }

      .nk23_c-table01__top {
        @include r.sp {
          padding: 8px 16px 8px 16px;
        }
      }
    }

    &.col3 {
      th {
        &:nth-child(2) {
          min-width: 202px;

          @include r.sp {
            width: 75%;
            min-width: auto;
          }
        }
      }
    }

    &.is-style16 {
      .nk23_c-table01__top {
        border-radius: 8px;

        @include r.sp {
          border-radius: 0;
        }
      }
    }
  }

  .nk23_c-text01 {
    padding-top: 8px;

    @include r.sp {
      padding: 8px 8px 0 16px;
    }
  }

  &2 {
    margin-top: 16px;
    display: flex;
    gap: 16px;

    @include r.sp {
      flex-direction: column;
    }

    &__item {
      width: 33.33%;

      @include r.sp {
        width: 100%;
      }
    }
  }
}
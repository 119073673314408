@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-notice {
	padding: 9px 0 40px !important;

	h2 {
		font-weight: bold;
		font-size: 1.8rem;
	}

	#contents770 {
		width: 100%;
		max-width: 960px;
		margin-left: auto !important;
		margin-right: auto !important;
		float: none !important;

		.bg-fff {
			border-radius: 8px;
			padding: 13px 15px 33px;
			font-size: 1.3rem;
			line-height: 2.2rem;

			.w375px {
				max-width: 397px;
			}
		}
	}
}

@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-news_kiji {
  max-width: 992px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @include r.sp {
    padding: 8px 8px 0;
  }

  .tl-main {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }

  .title01 {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  }

  .tx-small {
    font-size: 1.1rem;
    line-height: 17px;
  }

  .tx-large {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 22px;

    img {
      height: 15px;
      display: inline-block;
      margin-right: 5px;
    }
  }

  .bg-fff {
    background: #ffffff;
    border-radius: 8px;
    padding: 16px;
    margin-top: 5px;
    font-size: 1.3rem;
    line-height: 22px;
  }

  #contents770 {
    width: 100%;
    margin-left: 0 !important;
  }

  .bl-left {
    float: none;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  @include r.sp {
    #contents {
      padding: 8px 0 40px;
    }

    .title03 {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 25.6px;
      border-bottom: 1px solid v.$color19;
      padding-bottom: 4px;
    }

    .break {
      background: #ffffff;
      border-radius: 8px;
      padding: 8px;
      line-height: 1.6;
    }

    .tx_14px {
      font-size: 1.4rem;
      line-height: 22px;
      padding: 10px 0;
      display: block;
      text-align: right;
    }
  }
}

@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-calendar {
	&1 {
		padding: 16px 0 24px;

		@include r.sp {
			padding: 8px 0 32px;
		}
	}

	.nk23_c-tab5__content {
		padding-top: 24px;

		@include r.sp {
			padding-top: 8px;
		}
	}

	.nk23_c-title01 {
		margin-bottom: 16px;

		@include r.sp {
			margin-bottom: 8px;
		}
	}

	.nk23_c-table21 {
		margin-bottom: 16px;

		@include r.sp {
			margin-bottom: 8px;
		}
	}

	// .p-calendar .nk23_c-table01 .
	.nk23_c-table01 {
		&:not(:last-child) {
			margin-bottom: 32px;
		}
	}

	.nk23_c-selectbox {
		@include r.pc {
			margin-bottom: 16px;
		}

		&.is-style1 {
			.nk23_c-selectbox__select {
				width: 105px;

				@include r.sp {
					width: 100%;
				}
			}
		}
		&__select {
			text-align: -webkit-center;
		}
	}
	// .nk23_c-table21__icon {
	//   @include r.pad {
	//     font-size: 1rem;
	//   }
	// }
}

@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-about_website {
	margin: 0 auto;
	padding: 16px 16px 40px !important;
	display: flex;
	max-width: 992px;
	box-sizing: border-box;

	.link-pagetop {
		margin-top: 8px;
	}

	#contents730 {
		width: 770px !important;
		padding: 15px 20px 20px !important;
		background-color: #fff;
		border-radius: 8px;
		font-size: 1.3rem;
		line-height: 2.1rem;
	}

	h2 { margin: 0 0 1em 0; }
	.ico-02 { margin:0; padding:2px 0 2px 10px; background:url(/shared/images/icon/ico-02.gif) no-repeat scroll 0px 3px; }
	.bd-ccc { border:1px solid #ccc; }
	.box-A { display:block; }
	.box-A p { margin:0 0 0 50px !important; padding:0 0 5px 0; }
	.box-A img { padding:0; float:left; }
	.clr20 { margin: 0; padding: 0; clear: both; font-size: 0px; line-height: 20px; height: 20px; }
	.clr15 { margin: 0; padding: 0; clear: both; font-size: 0px; line-height: 15px; height: 15px; }

	.box-Q {
		margin-left: 20px;
		width: calc(100% - 40px);
	}

	.box690 {
		&:not(.tx-small) {
			margin: 16px 0;
		}
	}

	.box-A {
		padding-left: 20px;
		padding-right: 20px;

		table {
			margin: 0 0 5px 48px;
			width: max-content;
			border-spacing: 0;
			border-collapse: separate;
		}

		td {
			padding: 2px 4px;

			img {
				margin-bottom: 16px;
			}
		}

		tr {
			&:last-child {
				td {
					img {
						margin-bottom: 0;
					}

					&:first-child {
						border-bottom-left-radius: 8px;
					}

					&:last-child {
						border-bottom-right-radius: 8px;
					}
				}
			}
		}



		// .p-about_website .box-A+.box690 .
		& + .box690 {
			margin-bottom: 15px;
			padding: 0 20px;

			img {
				display: inline-block;
			}

			.link-pagetop {
				padding-right: 0;
			}
		}

		.val-top {
			.mL10 {
				margin-bottom: 16px;
			}
		}

		b {
			display: block;
		}
	}

	.box690 {
		margin-bottom: 15px;
	}

	#ls-qa-website {
		margin: 32px 0 0 20px;
	}

	a[id] {
		display: flex;
	}

	#qa16 {
		margin-top: 33px;
	}

	.mt30 {
		margin-top: 30px;

		& + .tb01b {
			margin-top: -2px !important;
		}
	}

	.tb-tajyo {
		border: none;

		td {
			border: none;
		}

		td {
			font-size: 1.3rem;
			line-height: 1.8rem;

			&.val-top {
				vertical-align: top;
			}

			img {
				width: auto;
				min-width: unset;
				max-width: unset;
			}
		}
	}

	.tb01b {
		tr {
			&:not(:first-child) {
				td {
					padding-top: 1px;
					padding-bottom: 1.5px;
				}
			}
		}
	}

	tr {
		&:first-child {
			td {
				&:first-child {
					border-top-left-radius: 8px;
				}

				&:last-child {
					border-top-right-radius: 8px;
				}
			}
		}

		td {
			&.no-radius {
				border-radius: 0 !important;
			}
		}
	}
}

@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-henko_info {
  &1 {
    padding: 20px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }

    .nk23_c-title01 {
      margin-bottom: 20px;
    }

    .nk23_c-listbtn__item:not(:last-child) {
      margin-right: 8px;
    }
  }
}

@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-bangumi {
  padding: 16px 0 40px;
  max-width: 960px;
  margin: auto;

  #bangumi-header {
    background: #fff;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 8px;


    h2 {
      font-size: 1.6rem;
      font-weight: 700;

      .tx-xsmall {
        font-size: 1.2rem;
        font-weight: normal;
      }

      .mR10 {
        margin-right: 8px;
      }

      img {
        width: 36px;

        &.fnb {
          width: 28px;
          padding: 0;
        }
      }
    }

    .tx-time {
      margin: 0 0 8px 0;
    }

  }

  .bangumi_kakukaisai {
    font-size: 1.3rem;
    font-weight: 700;
    border-radius: 8px;
    overflow: hidden;

    tr {
      th {
        font-weight: 700;
      }

      td {
        text-align: left;

        &:first-child {
          border-bottom: 0;
        }

        .w45pr {
          width: 45%;
          font-weight: 400;
        }

        a {
          &:first-child {
            margin-right: 16px;
          }
        }

        &.al-center {
          word-break: keep-all;
          white-space: nowrap;
        }
      }

      &:nth-child(3) {
        td {
          &.al-center {
            word-break: normal;
            white-space: normal;
          }
        }
      }

      &.al-center {
        td {
          text-align: center;
          font-weight: 400;
        }
      }
    }


  }
}

@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-netbank_accountactivity {
  margin: 0 auto;
  padding: 16px 16px 40px !important;
  display: flex;
  max-width: 992px;
  box-sizing: border-box;

  .nk23_c-box6 {
    .clearfix {
      a {
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }

  .contents750 {
    width: 770px;

    .contents750 {
      padding: 0;
    }
  }

  .cboxElement {
    margin-bottom: 5px;
  }

  ul.tab-navigation {
    margin-bottom: 22px;
  }

  .title-spat4-01 {
    margin-bottom: 16px;
  }

  .title-spat4-04 {
    margin-bottom: 10px !important;
  }

  .list00 {

    // .p-netbank_accountactivity .list00+.title-spat4-04 .
    &+.title-spat4-04 {
      margin-top: 16px;
    }

    // .p-netbank_accountactivity .list00+.title-spat4-03 .
    &+.title-spat4-03 {
      margin-top: 30px !important;
    }
  }

  p {

    // .p-netbank_accountactivity p+.title-spat4-03 .
    &+.title-spat4-03 {
      margin-top: 45px !important;
    }
  }

  // .p-netbank_accountactivity .nk23_c-box6 .
  .nk23_c-box6 {
    padding-bottom: 30px;
  }
}
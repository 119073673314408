@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-kis_info {
  .twoColEq_L,
  .twoColEq_R {
    .tb01 {
      tbody {
        th {
          padding: 4px 2px;
        }

        td {
          width: calc(100% / 8);
        }
      }

      td {
        &:not(:first-child) {
          text-align: right;
        }
        &.al-right {
          @include r.sp {
            padding: 4px 8px;
          }
        }
      }
    }
    .tb04 {
      .logo img {
        width: 32px;

        &.fnb {
          width: 24px;
        }
      }
    }
  }

  #disp-chg-pc {
    margin-bottom: 8px;
  }
}

@media print {
  .twoColEq_R,
  .twoColEq_L {
    // border: 1px solid v.$color19;
    // border-radius: 0 !important;
  }

  #jockey-prof {
    width: calc(100% - 220px);
  }

  #boxProf-jockey {
    border-radius: 0;
  }

  h3.tl-h3 {
    padding: 0;
  }

  .nk23_c-table25 {
    table {
      td,
      th {
        font-size: 1.2rem;

        &:first-child {
          border-left: 1px solid v.$color19;
        }
      }
    }
  }

  .tb01 tr th:first-child {
    border-left: 1px solid v.$color19;
  }

  .tb01 tr th:last-child {
    border-right: 1px solid v.$color19;
  }

  #boxProf-jockey {
    padding: 8px;
    border-right: 1px solid v.$color19;
    border-bottom: 1px solid v.$color19;
    border-left: 1px solid v.$color19;
  }

  .nk23_c-table25__content {
    min-width: auto;
  }

  .content-table {
    padding: 0;

    h3.tl-h3 {
      padding: 32px 0 0;
    }
  }

  #disp-chg-pc .tb01 tr th:first-child,
  #disp-chg-pc .tb01 tr td:first-child {
    border-left: 1px solid v.$color19;
  }

  #disp-chg-pc .tb01 tr th:last-child,
  #disp-chg-pc .tb01 tr td:last-child {
    border-right: 1px solid v.$color19;
  }

  #disp-chg-pc .tb01 tr:last-child td {
    border-bottom: 1px solid v.$color19;
  }

  #disp-chg-pc .tb01 thead th:nth-child(4) {
    width: 200px !important;
  }
}

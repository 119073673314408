@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-favorite_cho {
	padding: 16px 0 40px;

	.nk23_c-text01.is-style3 {
		margin-bottom: 16px;
	}

	.tl-main-txt {
		margin-bottom: 16px;
	}

	#contents950 {
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
	}

	form {
		display: flex;
	}

	.twoCol680_L {
		@include r.pc {
			padding-right: 32px;
			flex: 1;
		}

		.tb01 {
			td {
				padding-top: 8px;
				padding-bottom: 8px;
			}
		}
	}

	.twoCol250_R,
	.twoCol680_L {
		@include r.pc {
			margin-bottom: 16px;
		}
	}

	.twoCol250_R {
		@include r.pc {
			width: 249px;
			flex-shrink: 0;
		}

		.tx-small {
			margin-top: 8px;
		}

		// .p-favorite_cho .twoCol250_R .tb01 .
		.tb01 {
			margin-bottom: 8px;

			td {
				text-align: left;
			}

			tr {
				&:nth-child(2) {
					.tx-small {
						margin-top: 0;
					}
				}
			}
		}
	}

	.tb01 {
		overflow: hidden;
		border-radius: 8px;

		th {
			&:nth-child(1) {
				width: 1%;
				min-width: 200px;
			}

			&:nth-child(2) {
				width: 1%;
				min-width: 200px;
			}

			&:nth-child(3) {
				width: 1%;
				min-width: 60px;
			}

			&:nth-child(4) {
				width: 1%;
				min-width: 60px;
			}

			&:nth-child(5) {
				width: 1%;
				min-width: 100px;
			}
		}
	}

	// .p-favorite_cho .btn-style-reset .
	.btn-style-reset {
		margin-top: 8px;
		margin-bottom: 0;
		max-width: 100%;
	}

	.tl-h3 {
		margin-bottom: 16px;
		background-color: transparent;
		font-size: 1.4rem;
		font-weight: 700;
		padding: 0;

		.tx-normal {
			font-weight: normal;
		}
	}
}

@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-odds_bai_search {
  &1 {
    padding: 16px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }

    .nk23_c-tab1 {
      margin-top: 16px;

      @include r.sp {
        margin: 16px 0;
      }
    }

    .nk23_c-table03 {
      margin-top: 16px;
    }

    .nk23_c-tab2__text {
      position: relative;
      text-align: right;
      top: auto;
      right: auto;
      margin-top: 16px;

      @include r.sp {
        padding-right: 8px;
      }
    }
  }
}
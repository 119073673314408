@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-aisyou_uma {
	&1 {
		@include r.pc {
			padding-top: 16px;
			padding-bottom: 24px;
		}

		@include r.sp {
			padding: 8px 0 40px;
		}
	}

	.nk23_c-grtable19 {
		margin-top: 8px;
	}

	.nk23_c-table19 {
		tbody {
			td {
				&:last-child {
					word-break: keep-all;
					white-space: nowrap;
				}
			}
		}

		@include r.sp {
			margin-bottom: 16px;
		}
	}

	.nk23_c-tab2 {
		margin-top: 16px;

		&__content {
			.nk23_c-tab2__content {
				padding-top: 0;
			}
		}
	}

	.nk23_c-text01 {
		margin-bottom: 8px;
	}

	.nk23_c-title01,
	.nk23_c-filter1 {
		margin-bottom: 16px;
	}

	.nk23_c-filter1 {
		@include r.sp {
			margin: 8px 0 16px;
		}
	}

	.nk23_c-modal1 {
		&.is-show {
			@include r.pc {
				margin-top: -24px;
			}
		}
	}

	.nk23_c-table01 {
		@include r.sp {
			.has-after {
				word-break: keep-all;
				white-space: nowrap;
			}
		}
		// &.is-style7 {
		//   .nk23_c-table01__table {
		//     thead {
		//       th {
		//         &:first-child {
		//           @include r.sp {
		//             min-width: 73px;
		//           }
		//         }
		//       }
		//     }
		//   }
		// }
	}

	.nk23_c-modal1 .nk23_c-table01.is-style8 td {
		@include r.sp {
			line-height: 1;
		}
	}
}

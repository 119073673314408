@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-mobilesite_guide {
	margin: 0 auto;
	padding: 16px 16px 40px !important;
	display: flex;
	max-width: 992px;
	box-sizing: border-box;

	#contents740 {
		width: 100%;
	}

	ul.tab-navigation {
		margin-top: 16px;
	}

	.title-spat4-01 {
		margin-bottom: 16px !important;
	}
}

@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-about_jyusyo {
  margin: 0 auto;
  padding: 16px 16px 40px !important;
  display: flex;
  max-width: 992px;
  box-sizing: border-box;

  .link-pagetop {
    margin-top: 8px;
  }

  #contents730 {
    width: 770px !important;
    padding: 15px 20px 20px !important;
    background-color: #fff;
    border-radius: 8px;
    font-size: 1.3rem;
    line-height: 2.1rem;

    h2 {
      margin-bottom: 17px;
    }

    .box690,
    .box-Q,
    .box-A {
      max-width: 690px;
      margin: auto;
    }

    .box-A {
      margin-top: 15px;

      p+p {
        margin-left: 37px;
      }
    }

    #btnSet {
      margin: 14px 0 17px;

      ul {
        display: flex;

        li {
          float: none;

          a {
            background: #FFFFFF;
            color: #221F1F;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            text-indent: 0;
            font-size: 1.6rem;
            border: 1px solid #C9C9C9;
            box-shadow: 0 2px 0 #c9c9c9;
            position: relative;
            padding-bottom: 1px;
            transition: none;

            &:hover {
              box-shadow: none;
              top: 2px;
            }
          }
        }
      }
    }

    .news {
      a {
        color: #2461EE;

        &:hover {
          text-decoration: underline;
        }
      }
    }



    .tb-noborder {

      .race2_3-m,
      .race3_4-s,
      .race3_4-l,
      .race-f {
        margin-bottom: 18px;
        height: 88px;
        padding: 3px 0 0 0;
      }

      tr {
        &:first-child {
          td {
            border-top: 0;
          }
        }

        &:last-child {
          td {
            border-bottom: 0;
          }
        }

        th {
          border-top: 1px solid #C9C9C9;
          border-bottom: 1px solid #C9C9C9;

        }

        td {
          border-top: 1px solid #C9C9C9;
          border-bottom: 1px solid #C9C9C9;

          &:nth-child(2) {
            padding-left: 3px;
          }

          &.al-center {

            img {
              margin: auto;
              height: 20px;
            }
          }

          ul {
            width: 200px;
            font-size: 1.3rem;
            font-family: "Noto Sans JP";

            li {
              width: 100% !important;

              img {
                display: inline-block;
                margin-right: 2px;
              }

              &.parts01,
              &.parts03 {
                font-weight: 700;
              }

              &.parts01 {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                padding: 2px 0 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #221F1F;

                span {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  letter-spacing: -0.05rem;
                }

                img {
                  height: 16px;
                }
              }

              &.parts02 {
                color: #221F1F;
              }

              &.parts03 {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                background-size: 100% 25px;
                min-height: auto;
                height: auto;

                span {
                  display: block;
                  padding: 4px 0;
                  line-height: 1.8rem;
                }
              }
            }

            &.race-urawa {
              border: 2px solid #0B318F;
              border-radius: 8px;
              background: #0B318F;
              box-sizing: border-box;

              li {

                &.parts01 {
                  background: #F2F4F9;
                }

                &.parts02 {
                  background: #B8C5E6;
                  border-bottom: 2px solid #0B318F;
                  padding: 2px 0;
                }

              }
            }

            &.race-kawasaki {
              border: 2px solid #338F8F;
              border-radius: 8px;
              background: #338F8F;
              box-sizing: border-box;

              li {

                &.parts01 {
                  background: #F2FAFA;
                }

                &.parts02 {
                  background: #ADD9D9;
                  border-bottom: 2px solid #338F8F;
                  padding: 2px 0;
                }

              }
            }

            &.race-ooi {
              border: 2px solid #CC2A29;
              border-radius: 8px;
              background: #CC2A29;
              box-sizing: border-box;

              li {

                &.parts01 {
                  background: #FAF2F2;
                }

                &.parts02 {
                  background: #E6A1A1;
                  border-bottom: 2px solid #CC2A29;
                  padding: 2px 0;
                }

              }
            }

            &.race-funabashi {
              border: 2px solid #3A993A;
              border-radius: 8px;
              background: #3A993A;
              box-sizing: border-box;

              li {

                &.parts01 {
                  background: #F2FAF2;

                }

                &.parts02 {
                  background: #A3CCA3;
                  border-bottom: 2px solid #3A993A;
                  padding: 2px 0;
                }

              }
            }
          }
        }
      }

    }
  }

  #show_window {
    padding-bottom: 8px;
    -webkit-overflow-scrolling: touch;
    margin-top: 20px;

    #page01,
    #page05 {
      .tb-noborder {
        tr {
          &:first-child {
            td {

              &:first-child {
                border-top-left-radius: 8px;
              }
            }
          }

          &:last-child {
            td {

              &:first-child {
                border-bottom-left-radius: 8px;
              }

            }
          }
        }
      }
    }

    #page04,
    #page08 {
      .tb-noborder {
        tr {
          &:first-child {
            td {

              &:last-child {
                border-top-right-radius: 8px;
              }
            }
          }

          &:last-child {
            td {

              &:last-child {
                border-bottom-right-radius: 8px;
              }
            }
          }

        }
      }
    }

    &::-webkit-scrollbar {
      width: 160px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #F7F7F7;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #C9C9C9;
    }

    #mySlides {
      width: 690px;
      margin-bottom: 0;
      display: flex;

      #page01,
      #page02,
      #page03,
      #page04 {
        float: none;
      }
    }
  }
}

@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-kis_list {
  padding: 16px 0 40px;

  .nk23_c-title01 {
    margin-bottom: 16px;
  }
  &__ttl {
    font-size: 1.4rem;
    line-height: 22px;
    padding-top: 16px;
    &--style1 {
      margin-top: -5px;
    }
  }
}

@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-leading_bms {
  &1 {
    padding: 20px 0 40px;

    .nk23_c-title01 {
      margin-bottom: 20px;
    }

    .nk23_c-block18 {
      &:only-child {
        @include r.sp {
          margin-bottom: 0;
        }
      }

      &.is-style1 {
        @include r.sp {
          width: 100%;
          max-width: calc(100% - 16px);
          margin-left: 8px;
        }
      }

      & + .nk23_c-text01 {
        @include r.sp {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }

    .nk23_l-container {
      @include r.sp {
        padding: 0;
      }
    }

    .nk23_c-table24 {
      @include r.sp {
        margin-left: 0;
        margin-right: 0;
      }

      &__icon {
        @include r.sp {
          top: 200px;
        }
      }

      &__table {
        thead {
          th {
            &:nth-child(2) {
              width: 200px;

              @include r.sp {
                width: 1%;
                min-width: 200px;
              }
            }

            &:nth-child(3) {
              @include r.sp {
                width: 1%;
                min-width: 80px;
              }
            }

            &:nth-child(4) {
              @include r.sp {
                width: 1%;
                min-width: 104px;
              }
            }

            &:nth-child(5),
            &:nth-child(6) {
              @include r.sp {
                width: 1%;
                min-width: 47px;
                white-space: nowrap;
                word-break: keep-all;
              }
            }

            &:nth-child(7) {
              @include r.sp {
                width: 1%;
                min-width: 53px;
              }
            }

            &:nth-child(8) {
              @include r.sp {
                width: 1%;
                min-width: 95px;
              }
            }
          }
        }
        tbody {
          td {
            @include r.sp {
              &:last-child {
                padding: 4px 8px;
              }
            }
          }
        }
      }
    }
  }
}

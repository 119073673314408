@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-race_trend_blood {
  &1 {
    padding: 16px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }
  }

  .nk23_c-slide02 {
    margin-top: 16px;

    @include r.sp {
      margin-top: 8px;
    }
  }

  .nk23_c-box1 {
    margin-top: 16px;

    @include r.sp {
      margin-top: 8px;
    }
  }

  .nk23_c-tab2 {
    margin-top: 16px;
  }

  .nk23_c-table15 {
    &__ttl {
      span {
        @include r.sp {
          letter-spacing: -0.05em;
        }
      }
    }
  }

}
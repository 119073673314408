@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-vote_result {
  padding: 20px 0 40px;

  .nk23_c-tab2__navi {
    @include r.sp {
      margin: 0 -8px;
    }
  }

  .nk23_c-tab2__subnavi {
    @include r.sp {
      padding: 0;
    }
  }

  .nk23_c-tab2__content .nk23_c-tab2__content {
    @include r.sp {
      padding-top: 8px;
    }
  }

  .nk23_c-table01 {
    $root: ".nk23_c-table01";

    @include r.sp {
      margin-bottom: 8px;
    }

    &__inner {
      padding-bottom: 0;

      @include r.sp {
        padding-bottom: 8px;
      }
    }

    // .nk23_l-container {
    //   padding-bottom: 8px;
    // }

    thead {
      th {
        border-top: 1px solid #c9c9c9;
        word-break: keep-all;
        white-space: nowrap;
        @include r.pc {
          &:first-child {
            width: 143px;
          }
          &:nth-child(2),
          &:nth-child(3) {
            width: 115px;
          }
          &:nth-child(4) {
            width: 228px;
          }
          &:nth-child(5),
          &:nth-child(6) {
            width: 68px;
          }
        }
      }
    }

    .text-left {
      text-align: left !important;
    }

    .is-pagging {
      // margin-bottom: 8px;

      @include r.sp {
        background: #fff;
      }

      .scrollbar-track-x {
        background: #ebebeb;
      }

      .nk23_c-pagging {
        border-radius: 0;
        border-top: 1px solid #c9c9c9;

        @include r.sp {
          padding: 16px 16px 8px;
        }

        &:last-child {
          border-radius: 0 0 8px 8px;
          border-top: 0;

          @include r.sp {
            border-top: 0;
          }
        }

        .nk23_c-pagenavi a,
        .nk23_c-pagenavi span {
          @include r.sp {
            margin-bottom: 8px;
          }
        }
      }

      table {
        border-radius: 0 !important;
      }

      #{$root}__inner {
        padding-bottom: 0 !important;

        @include r.sp {
          padding-bottom: 8px !important;
        }
      }

      #{$root}__table {
        tr {
          &:last-child {
            border-bottom: 1px solid #c9c9c9;
          }
        }
      }
    }

    &.is-style16 {
      .nk23_c-table01__table {
        @include r.sp {
          width: 860px;
        }

        tbody {
          td {
            @include r.sp {
              padding: 1px 4px;
            }

            &:nth-child(1) {
              @include r.sp {
                min-width: auto;
                width: 1%;
                word-break: keep-all;
                white-space: nowrap;
              }
            }

            &:nth-child(2),
            &:nth-child(3) {
              @include r.sp {
                width: 107px;
              }
            }

            &:nth-child(4),
            &:nth-child(8) {
              @include r.sp {
                width: 46px;
              }
            }

            &:nth-child(6),
            &:nth-child(7) {
              @include r.sp {
                width: 61px;
              }
            }
          }
        }
      }
    }
  }

  .nk23_c-title01 {
    margin-bottom: 20px;
  }

  .nk23_c-table01 {
    &__top {
      .nk23_c-link {
        font-weight: bold;
      }
    }

    &__inner {
      @include r.pc {
        margin-bottom: 0;
      }
    }
  }

  .nk23_c-text01 {
    margin-top: 8px;
  }

  .nk23_c-text01 + .nk23_c-text01 {
    margin-top: 4px;
  }

  .nk23_c-block06 {
    margin-bottom: 16px;

    @include r.sp {
      border-radius: 0;
      margin: 0 -8px 16px;
    }

    &--nobd {
      .nk23_c-block06__ttl {
        border-bottom: 0;
        padding: 16px;

        @include r.sp {
          padding: 12px 16px;
        }
      }
    }
  }

  .nk23_c-text02 {
    font-size: 1.4rem;
    margin-bottom: 16px;
  }

  &4 {
    .nk23_c-table01 {
      &.is-style16 {
        .nk23_c-table01__table {
          @include r.sp {
            width: 880px;
          }

          td {
            &:nth-child(1) {
              @include r.sp {
                min-width: 125px;
              }
            }
          }
        }
      }
    }
  }
}

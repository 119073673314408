@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-about_program {
  margin: 0 auto;
  padding: 16px 16px 40px !important;
  display: flex;
  max-width: 992px;
  box-sizing: border-box;

  .link-pagetop {
    margin-top: 8px;
  }

  #contents730 {
    width: 770px !important;
    padding: 15px 20px 20px !important;
    background-color: #fff;
    border-radius: 8px;
    font-size: 1.3rem;
    line-height: 2.1rem;
  }

  #qa01 {
    margin-top: 33px;
  }

  .box-Q {
    margin-left: 20px;
    width: calc(100% - 40px);
  }

  .box-A {
    padding-left: 20px;
    padding-right: 20px;

    table {
      margin: 0 0 5px 48px;
      width: max-content;
    }

    td {
      padding: 2px 4px;

      img {
        margin-bottom: 16px;
      }
    }

    tr {
      &:last-child {
        td {
          img {
            margin-bottom: 0;
          }
        }
      }
    }

    p {
      padding-bottom: 5px;
    }

    img {
      &+p {
        padding-bottom: 0;
      }
    }

    // .p-about_website .box-A+.box690 .
    &+.box690 {
      margin-bottom: 15px;
      padding: 0 20px;

      img {
        display: inline-block;
      }

      .link-pagetop {
        padding-right: 0;
      }
    }

    .val-top {
      .mL10 {
        margin-bottom: 16px;
      }
    }

    .w600px {
      width: 600px;

      &+.tb01b {
        margin-top: -2px !important;
        margin-bottom: 0;
      }
    }

    .ml37 {
      &+.tb01b {
        margin-top: 0 !important;
      }
    }

    .mL50 {
      margin-left: 50px;
    }

    .mt10 {
      margin-top: 10px;

      &+.al-right {
        margin-top: -15px;
      }
    }

    .mb49 {
      margin-bottom: 49px;
    }

    .pl0 {
      padding-left: 0;

      &:nth-child(6) {
        margin-top: 15px;
        padding-bottom: 17px;
      }
    }

    .mt0 {
      margin-top: 0 !important;
    }

    .mb0 {
      margin-bottom: 0 !important;
    }

    .tb01b {
      border-radius: 8px;
      border-collapse: separate;
      border-spacing: 0;

      td {
        width: auto;
      }

      tr {
        &:last-child {
          td {
            &:last-child {
              border-bottom-right-radius: 8px;
            }

            &:first-child {
              border-bottom-left-radius: 8px;
            }
          }
        }
      }

      &.custom-radius {
        tr {
          &:last-child {
            td {
              &:first-child {
                border-bottom-left-radius: 0;
              }
            }
          }
        }
      }

      .boradius-bl {
        border-bottom-left-radius: 8px;
      }

      .boradius-br {
        border-bottom-right-radius: 8px;
      }
    }
  }

  .box690 {
    &:not(.tx-small) {
      margin: 16px 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  // .p-about_program #ls-qa-program .
  #ls-qa-program {
    margin: 36px 0 0 20px;
  }

  a[id] {
    display: flex;
  }

  .tb-tajyo {
    border: none;

    td {
      border: none;
    }

    td {
      font-size: 1.3rem;
      line-height: 1.8rem;

      &.val-top {
        vertical-align: top;
      }

      img {
        width: auto;
        min-width: unset;
        max-width: unset;
      }
    }
  }

  .tb04 {
    .w10px {
      padding: 0 7px;
    }

    td {
      padding-top: 2.5px;
      padding-bottom: 2.5px;
    }

    &+p {
      margin-top: 4px;
    }
  }

  .mt22 {
    margin-top: 22px !important;
  }

  table {
    tr {
      &:first-child {
        td {
          &:first-child {
            border-top-left-radius: 8px;
          }

          &:last-child {
            border-top-right-radius: 8px;
          }
        }
      }

      td {
        &.no-radius {
          border-radius: 0 !important;
        }
      }
    }

    &.col7 {
      tr {
        &:nth-child(2) {
          td {
            width: calc(100% / 7);
          }
        }
      }
    }

    &.col9 {
      tr {
        &:nth-child(2) {
          td {
            width: calc(100% / 9);
          }
        }
      }
    }

    .bg-e8e {
      td {
        background-color: v.$color29;
        color: #fff;
        font-weight: 700;
      }
    }
  }
}
@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-best {
	&1 {
		padding: 16px 0 40px;

		@include r.sp {
			padding-top: 8px;
			padding-bottom: 56px;
		}
	}

	.nk23_c-tab2 {
		margin-top: 16px;

		&__content {
			.nk23_c-tab2__content {
				padding-top: 0;
			}
		}
	}

	.nk23_c-title01,
	.nk23_c-filter1 {
		margin-bottom: 16px;
	}

	.nk23_c-filter1 {
		@include r.sp {
			margin: 8px 0 16px;
		}
	}
	.nk23_c-table01 {
		&--style1 {
			.nk23_c-table01__table {
				th {
					&:not(:nth-of-type(3)) {
						width: 113px;
					}
					&:nth-of-type(1),
					&:nth-of-type(2) {
						width: 45px;
					}
				}
				tr {
					&[class^='nk23_u-bg-color'] {
						td {
							color: v.$color0;
							&[class^='nk23_u-bg-color'] {
								color: #ffffff;
							}
						}
					}
				}
			}
		}
	}
}

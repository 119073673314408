@use "../../settings/variable"as v;
@use "../../settings/mixin"as r;
@use "../../settings/_path"as p;

.p-about_browser {
  display: flex;
  max-width: 960px;
  margin: auto;

  #submenu {
    width: 100%;
  }

  #contents730 {
    width: 770px;
    background: #fff;
    padding: 15px 20px 20px 19px !important;
    border-radius: 8px;

    h2 {
      margin-bottom: 18px;
    }

    p {
      font-size: 1.3rem;
      line-height: 2.1rem;
      display: block;

      &:nth-child(4) {
        margin-top: 10px;
      }

      a {
        color: #2461EE;

        &:hover {
          text-decoration: underline;
        }
      }

    }

    h3 {
      margin-bottom: 12px;

      &:nth-of-type(2) {
        margin-top: 47px;
      }

      &:nth-of-type(3) {
        margin-top: 7px;
      }
    }
  }

  &__txt {
    margin-top: 13px;
  }

  &__img {
    margin: 13px 0;

    &:last-child {
      margin: 13px 0 0;

    }

    .tx-small {
      font-size: 1.2rem;
    }
  }

  &__mt {
    margin-top: 10px;
  }
}
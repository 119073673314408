@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-telephone_howtobuy {
	margin: 0 auto;
	padding: 16px 16px 40px !important;
	display: flex;
	max-width: 992px;
	box-sizing: border-box;

	.contents750 {
		width: 770px;

		.contents750 {
			padding: 0;
		}
	}

	.cboxElement {
		margin-bottom: 5px;
	}

	ul.tab-navigation {
		margin-bottom: 22px;
	}

	.title-spat4-01 {
		margin-bottom: 16px;
	}

	.box-freedial {
		margin-top: 48px !important;

		img {
			margin: auto;
			max-width: 288px;
		}

		& + p {
			display: flex;
			justify-content: center;

			a {
				border-radius: 8px;
				overflow: hidden;
				width: max-content;

				img {
					width: 100%;
					max-width: 730px;
					display: inline-block;
				}
			}
		}
	}

	.nk23_c-box6 {
		padding-bottom: 30px;

		p:last-child {
			margin-bottom: 0;
		}
	}
}

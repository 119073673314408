@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-analyze_top {
  &1 {
    padding: 16px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }
  }

  // .p-analyze_top .nk23_c-tab2 .
  .nk23_c-tab2 {
    margin-top: 16px;

    &__content {
      .nk23_c-tab2__content {
        padding-top: 0;
      }
    }
  }

  .nk23_c-title01 {
    margin-bottom: 16px;
  }
}

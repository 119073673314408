@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-aisyou_cho {
  &1 {
    padding: 16px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }

    .nk23_c-tab1 {
      margin: 16px 0;
    }

    .nk23_c-text01 {
      margin-bottom: 8px;
    }
  }

  .nk23_c-grtext1 {
    margin: 16px 0;
  }

  .nk23_c-table01 {
    &.is-style8 {
      background: #fff;

      @include r.min-w(767px) {
        border-radius: 8px 8px 8px 8px;
      }

      .nk23_c-table01__table {
        border-radius: 0;

        td {
          border-bottom: 1px solid #c9c9c9;
        }
      }

      .nk23_c-table01__inner {
        padding-bottom: 0;

        @include r.sp {
          padding-bottom: 8px;
        }
      }

      .nk23_c-table01__grtop {
        border-bottom: 1px solid #c9c9c9;
      }

      .nk23_c-pagging {
        background: none;
      }

      .nk23_c-table01__icon {
        @include r.sp {
          top: 160px;
          transform: translate(-50%, 0);
        }
      }
    }
  }

  .nk23_c-u-bg-white {
    background: #fff;
  }

  .nk23_c-table19 {
    @include r.tablestyle;

    // .p-aisyou_cho .nk23_c-table19__icon .
    &__icon {
      @include r.sp {
        top: 160px;
      }
    }
  }
}

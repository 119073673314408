@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-grace_blood {
  &1 {
    padding-bottom: 40px;

    .nk23_c-title01 {
      margin: 16px 0;
    }

    .nk23_c-tab2 {
      margin-top: 16px;
    }

    .js-tab3 {
      .nk23_c-tab2__subnavi {
        border-radius: 8px 8px 0 0;
        padding-top: 16px;
        background: #fff;
        box-shadow: 0 2px 3px rgba(34, 31, 31, 0.1);
        position: relative;
        z-index: 2;

        @include r.sp {
          padding: 16px 8px 8px;
          border-radius: 0;
        }
        &__item {
          &:not(:last-child) {
            margin-right: 8px;
          }
        }

        .nk23_c-btn01 {
          box-shadow: none;
          min-height: 38px;
          padding-left: 16px;
          padding-right: 16px;
          &.is-active {
            background-color: #fff;
            color: #221f1f;
            border-color: #221f1f;
            top: 0;
          }
          @include r.hover {
            background-color: #fff;
            color: #221f1f;
            border-color: #221f1f;
            top: 0;
          }
        }
      }

      .nk23_c-tab7__top {
        border-radius: 0;
      }

      .nk23_c-tab7 {
        border-radius: 0 0 8px 8px;

        &__navi {
          @include r.sp {
            justify-content: center;
          }
        }
      }

      .nk23_c-tab7__navi__item {
        padding: 0 16px;
      }

      .nk23_c-tab7__navi__text {
        border: 0;
        padding: 0 0 4px;
        border-radius: 0;
        opacity: 0.7;

        &.is-active,
        &:hover {
          border-bottom: 1px solid #000;
          opacity: 1;
        }
      }
    }

    .nk23_c-tab2__content .nk23_c-tab2__content {
      padding-top: 0;
    }

    .nk23_c-tab2__subnavi.is-style1 {
      @include r.sp {
        padding: 0 8px;
      }
    }

    .nk23_c-table15 {
      &__wrap {
        table {
          td {
            @include r.sp {
              padding: 4px 2px;
            }
          }
        }
      }

      &__ttl {
        span {
          @include r.sp {
            letter-spacing: -0.05em;
          }
        }
      }
    }
  }
}

@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-bataijyu {

  &__sc1 {
    padding: 16px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }

    .nk23_c-title01 {
      margin-bottom: 16px;
    }

    .nk23_c-filter3 {
      margin-bottom: 16px;
    }

    .nk23_c-text01 {
      margin-bottom: 8px;
    }

    .nk23_c-table02 {
      margin-top: 16px;
    }

    .nk23_c-chart2 {
      margin-bottom: 8px;
    }
  }

}

@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-about_race {
	margin: 0 auto;
	padding: 16px 16px 40px !important;
	display: flex;
	max-width: 992px;
	box-sizing: border-box;

	.link-pagetop {
		margin-top: 8px;
	}

	#contents730 {
		width: 770px !important;
		padding: 15px 20px 20px !important;
		background-color: #fff;
		border-radius: 8px;
		font-size: 1.3rem;
		line-height: 2.1rem;
	}

	.box-Q {
		margin-left: 20px;
		width: calc(100% - 40px);
	}

	#qa01 {
		margin-top: 33px;
	}

	.box-A {
		padding-left: 20px;
		padding-right: 20px;

		table {
			margin: 0 0 5px 48px;
			width: max-content;
		}

		td {
			padding: 2px 4px;

			img {
				margin-bottom: 16px;
			}
		}

		tr {
			&:last-child {
				td {
					img {
						margin-bottom: 0;
					}
				}
			}
		}

		p {
			padding-bottom: 5px;
		}

		img {
			& + p {
				padding-bottom: 0;
			}
		}

		& + .box690 {
			margin-bottom: 15px;
			padding: 0 20px;

			img {
				display: inline-block;
			}

			.link-pagetop {
				padding-right: 0;
			}
		}
	}

	.box690 {
		&:not(.tx-small) {
			margin: 16px 0;
		}

		// .p-about_race .box690:last-child .
		&:last-child {
			margin-bottom: 0;
		}
	}

	#ls-qa-race {
		margin: 32px 0 0 20px;
	}

	a[id] {
		display: flex;
	}
}

@use "../../settings/_variable" as v;
@use "../../settings/_mixin" as r;
@use "../../settings/_path" as p;

.p-top {
	&1 {
		padding: 20px 0 40px;

		@include r.sp {
			padding-top: 10px;
		}
		.nk23_l-side {
			&__content {
				&.js-slide04__content {
					@include r.pc {
						margin-top: -14px;
					}
				}
			}
		}
		.nk23_c-tab5__top {
			@include r.sp {
				width: calc(100% + 16px);
				margin-left: -8px;
			}
		}

		@include r.pc {
			.nk23_c-block01__bottom {
				&.js-mheight {
					.nk23_c-block01__col {
						&:first-child {
							border-right: 1px solid v.$color23;
						}
					}
				}
			}
		}
	}

	.mt16 {
		margin-top: 16px;
	}

	.mt-8-sp {
		@include r.sp {
			margin-top: 8px;
		}
	}

	.mw-auto-sp {
		@include r.sp {
			min-width: auto;
		}
	}

	.js-slide-head {
		margin-bottom: 8px;
	}

	.nk23_c-list09 {
		// Add strix St 23.11.20
		font-size: 1.2rem;
		// margin-bottom: 8px;
		// End strix St 23.11.20
	}

	.nk23_c-block01 {
		@include r.sp {
			margin: 0 -8px;
		}
	}

	.nk23_c-block02 {
		@include r.pc {
			position: relative;
			margin-bottom: 16px;
		}
		@include r.sp {
			margin-bottom: 8px;
		}
	}

	.nk23_c-block09__wrap {
		@include r.sp {
			margin: 0 -8px;
		}
	}

	.jsSideFixed {
		opacity: 1;
		pointer-events: all;
		visibility: visible;

		&.is-sticky {
			position: sticky;
			top: 8px;
			z-index: 3;
			opacity: 0;
			pointer-events: none;
			visibility: hidden;

			&.is-show {
				opacity: 1;
				pointer-events: all;
				visibility: visible;
			}
		}

		&.is-transition {
			transition:
				opacity 0.6s ease-in-out,
				visibility 0.6s ease-in-out;
		}
	}

	.nk23_c-block01__titlebtn {
		display: block;
		/*.nk23_c-block01__list__title {
			overflow: visible;
			display: inline-block;
		}
		*/
	}

	.nk23_c-block17 {
		// Add strix St 23.11.20
		display: grid;
		grid-template-columns: 1fr auto;
		background: #ffffff;
		margin-bottom: 16px;
		// End strix St 23.11.20

		@include r.sp {
			margin: 8px 0;
		}
	}

	.nk23_c-btn02 {
		&.is-color1 {
			.nk23_c-btn02__text {
				&.is-color13 {
					color: v.$color13 !important;
				}
			}
		}
	}
}

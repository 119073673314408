@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-jyusyosiki {
	max-width: 992px;
	min-width: 100%;
	margin: 0 auto;
	padding: 0 16px;
	@include r.sp {
		padding: 8px 8px 0;
		margin: 0;
	}

	.img-btn01 {
		width: 56px;
	}

	.ico-umanum {
		width: 20px;
	}

	.icon-race {
		width: 36px;
	}

	.tl-main {
		text-align: center;
		display: flex;
		justify-content: center;
		margin-bottom: 16px;
		@include r.sp {
			margin-bottom: 6px;
		}
	}

	.tl-main img {
		width: 164px;

		@include r.sp {
			width: 200px;
		}
	}

	.title01 {
		text-align: center;
		display: flex;
		justify-content: center;
		margin-bottom: 8px;
	}

	&__link {
		display: flex;
		justify-content: flex-end;
		padding: 16px 0;
		@include r.sp {
			padding: 0 8px 14px 8px;
		}

		a {
			color: v.$color-link;
			font-size: 1.4rem;
			line-height: 20px;

			&:not(:last-child) {
				margin-right: 16px;
			}

			@include r.hover {
				text-decoration: underline;
			}
		}
	}

	#contents770 {
		width: 100%;
		margin-left: 0 !important;

		h3.tl-h3 {
			border-radius: 0;
			padding: 0;
			margin-bottom: 16px;
			font-size: 1.2rem;
			background: transparent;

			.bl-left {
				font-size: 1.4rem;
				margin-right: 16px;
			}

			.hitokuchi {
				color: #ffffff;
				text-align: center;
				font-size: 1.2rem;
				line-height: 22px;
				min-width: 108px;
				padding: 0 8px;
				height: 22px;
				display: inline-block;

				&.bg_pink {
					background: #eb6ad5;
				}

				&.bg_orange {
					background: #f59331;
				}
			}

			.crosingtime {
				float: right;
				font-weight: normal;
			}
		}

		.bg-fff {
			background: #ffffff;

			.tb00 {
				width: 100%;

				th {
					padding: 7px;
					font-size: 1.2rem;
				}

				td {
					padding: 7px;
					font-size: 1.5rem;
				}
			}
		}
	}

	.tb01.stripe {
		width: 100%;

		th {
			font-size: 1.3rem;
		}

		td {
			text-align: left;
			font-size: 1.3rem;
			background: #ffffff;

			img {
				display: inline-block;
			}

			.bl-left5 {
				float: left;
				margin-right: 4px;
			}

			&.al-center {
				text-align: center;
			}

			&.al-right {
				text-align: right;
			}

			.tx-10px {
				font-size: 1rem;
			}

			.lh_1-2 {
				line-height: 1.2;
				margin-top: -5px;
			}

			.ico-weather01 {
				&::before {
					top: 3px;
					position: relative;
					content: "";
					display: inline-block;
					width: 20px;
					height: 20px;
					background: url(p.$path + "images/common/icon/icon_sun-orange.svg") no-repeat center / 20px auto;
				}
			}
		}
	}

	.tb01 {
		width: 640px;
		margin: 16px auto;
		border-radius: 8px;
		overflow: hidden;

		th {
			font-size: 1.3rem;

			&:first-child {
				border-top: none;
			}
		}

		td {
			background: #ffffff;

			hr {
				margin: 5px -4px;
				border-color: v.$color19;
			}
		}

		tr {
			&:first-child {
				td {
					border-top: none;
				}
			}
		}

		.nk23_u-color1 {
			font-weight: bold;
		}
	}

	.tb06 {
		width: 640px;
		margin: 0 auto;
		border-radius: 8px;
		overflow: hidden;

		th {
			background: v.$color29;
			border-bottom: 1px solid v.$color19;
			color: #ffffff;
			font-weight: bold;
			font-size: 1.3rem;
			padding: 4px;
		}

		td {
			border-left: 1px solid v.$color19;
			border-bottom: 1px solid v.$color19;
			background: #ffffff;
			padding: 4px;
			font-size: 1.3rem;
		}

		tr {
			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}

	.txt-bt {
		width: 640px;
		margin: 0 auto;
		font-size: 1.3rem;
		line-height: 1.8;

		a {
			color: v.$color-link;
			@include r.hover {
				text-decoration: underline;
			}
		}
	}

	#navJyusyoshiki {
		.crt {
			width: 246px;
			height: 24px;
			line-height: 24px;
			border-radius: 24px;
			background: v.$urawa-color;
			margin-bottom: 16px;
			font-size: 1.4rem;
			color: #ffffff;
			font-weight: bold;
			text-align: center;
			@include r.hover {
				opacity: 0.8;
			}
		}
	}

	@include r.sp {
		#contents {
			padding: 0 8px;
		}
		#update {
			display: flex;
			justify-content: flex-end;
			font-size: 1.2rem;
		}
		#sikibetuSelect {
			width: 205px;
			font-size: 1.9rem;
			padding: 4px 5px;
			border-radius: 8px;
			margin-right: 8px;
			border: 1px solid v.$color19;
			background: #ffffff url(p.$path + "images/common/icon/icon_down.svg") no-repeat center right 4px/10px auto;
		}
		.btn_disp {
			width: 80px;
			height: 37px;
			background: #ffffff;
			border-radius: 24px;
			text-align: center;
			line-height: 37px;
			border: 1px solid v.$color19;
			box-shadow: 0 2px 0 v.$color19;
			font-size: 1.4rem;
		}
		.title05.al_center {
			text-align: center;
			font-size: 1.6rem;
			padding: 5px 0;

			.tx_voteunit {
				height: 22px;
				line-height: 22px;
				border-radius: 8px;
				color: #ffffff;
				font-size: 1.4rem;
				display: block;
				margin-top: 2px;
				margin-left: 5px;
				margin-right: 5px;
				margin-bottom: 5px;

				&.bg_pink {
					background: #eb6ad5;
				}

				&.bg_orange {
					background: #f59331;
				}
			}
		}
		.al_right {
			text-align: right;
			font-size: 1.4rem;
		}
		.tb01 {
			width: 100%;

			&.mB15 {
				margin-top: 0;

				th {
					background: #ffffff;
					color: v.$color0;
					font-weight: normal;
				}

				td {
					border: none;
				}
			}

			tr {
				&:last-child {
					td {
						border-bottom: none;
					}
				}
			}

			td.al_left {
				text-align: left;
			}
		}
		.tb06 {
			width: 100%;

			td {
				&:first-child {
					border-left: none;
				}
			}
		}
		.tb_loto {
			width: 100%;
			background: #ffffff;
			border-radius: 8px;
			margin-bottom: 16px;

			th,
			td {
				padding: 4px;

				h3 a {
					color: v.$color-link;
					font-size: 1.6rem;
				}

				ul {
					float: left;

					li {
						&:not(:last-child) {
							margin-bottom: 3px;
						}
					}
				}

				div {
					float: right;
				}

				.button01 {
					width: 50px;
					height: 26px;
					line-height: 24px;
					text-align: center;
					display: inline-block;
					background: #ffffff;
					border-radius: 24px;
					font-size: 1.4rem;
					border: 1px solid v.$color19;
					box-shadow: 0 2px 0 v.$color19;
				}

				.num_uma {
					display: inline-block;
					width: 28px;
					height: 28px;
					border-radius: 4px;
					font-size: 1.6rem;
					line-height: 28px;
					background: #f7f7f7;
					text-align: center;
				}

				.tx_red01 {
					padding: 5px 8px;
					border-radius: 8px;
					display: inline-block;
					border: 1px solid v.$color13;
					color: v.$color13;
					margin-left: 4px;
					font-size: 1.2rem;
					line-height: 1;
					font-weight: bold;
				}
			}

			th {
				text-align: left;
			}

			td {
				border-top: 1px solid v.$color19;
			}

			.ico_racenumber {
				width: 40px;
				height: 25px;
				line-height: 23px;
				color: #ffffff;
				background: v.$color0;
				border-radius: 8px;
				margin-right: 5px;
				font-size: 1.2rem;
				font-weight: normal;
				display: inline-block;
				text-align: center;
			}

			.tx_12px {
				font-size: 1.2rem;
			}

			.w60px {
				width: 60px;
				text-align: center;
			}
		}
		.txt-bt-sp {
			font-size: 1.6rem;
			margin-top: -10px;
			margin-bottom: 16px;

			a {
				color: v.$color-link;
			}

			p {
				display: block;
				position: relative;
				padding-left: 20px;
				padding-bottom: 5px;

				&::before {
					content: "※";
					position: absolute;
					left: 0;
					top: 0;
					width: 20px;
				}
			}
		}
		.nk23_c-tab3__navi {
			margin: 0 8px;
		}
	}
}

@use "../../settings/variable"as v;
@use "../../settings/mixin"as r;
@use "../../settings/_path"as p;

.p-grace_repay {
  &1 {
    padding-bottom: 40px;

    .nk23_c-title01 {
      margin: 16px 0;
    }

    .nk23_c-tab2 {
      margin-top: 16px;
    }

    .nk23_c-tab7 {
      background: transparent;

      &__top {
        background: #fff;
        border-radius: 8px;

        @include r.sp {
          border-radius: 0;
        }
      }
    }

    .nk23_c-tab2__content .nk23_c-tab2__content {
      padding-top: 0;
    }

    .nk23_c-table16 {
      &.is-style1 {
        .nk23_c-table16__table {
          @include r.sp {
            margin-top: 0;
          }

          @include r.max-w(320px) {
            font-size: 1.2rem;
          }

          thead {
            th {
              @include r.sp {
                word-break: keep-all;
                white-space: nowrap;
              }

              @include r.max-w(320px) {
                padding: 8px 2px;
              }

              &:nth-child(1) {
                @include r.max-w(320px) {
                  width: 45px;
                }
              }
            }
          }

          tbody {
            td {
              @include r.sp {
                word-break: keep-all;
                white-space: nowrap;
              }

              @include r.max-w(320px) {
                padding: 8px 2px;
              }
            }
          }
        }
      }
    }

    .nk23_c-tab2__subnavi.is-style1 {
      @include r.sp {
        padding: 0 8px;
      }
    }
  }

}
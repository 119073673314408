@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-odds_nin {
	&1 {
		padding: 16px 0 40px;

		@include r.sp {
			padding-top: 8px;
		}

		.nk23_c-tab1 {
			margin-top: 16px;

			@include r.sp {
				margin: 16px 0;
			}
		}

		.nk23_c-table03 {
			margin-top: 16px;
		}
	}

	&__ttl {
		margin-bottom: 16px;
		min-height: 24px;

		@include r.sp {
			padding: 12px 16px;
			background: #fff;
			margin-bottom: 0;
		}
	}

	&__row {
		display: flex;
		justify-content: space-between;

		@include r.sp {
			flex-direction: column;
		}

		.nk23_c-tab7__content {
			width: calc((100% - 16px) / 2);

			@include r.pc {
				display: block;
			}

			@include r.sp {
				width: 100%;
			}

			&:nth-child(2) {
				.p-odds_nin__ttl,
				.p-odds_nin__note {
					visibility: hidden;

					@include r.sp {
						visibility: visible;
					}
				}
			}
		}

		.nk23_c-table06 {
			margin-bottom: 32px;

			@include r.sp {
				margin-bottom: 16px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__note {
		font-size: 1.2rem;
		margin-top: 8px;

		@include r.sp {
			padding-left: 8px;
		}

		&.is-style1 {
			font-weight: 700;
			margin-bottom: 8px;
		}
	}

	.nk23_c-tab7 {
		background: transparent;

		&__top {
			background: #fff;
		}
	}
	.nk23_c-table04 {
		thead {
			th {
				word-break: keep-all;
				white-space: nowrap;
			}
		}
		tbody {
			td {
				&:not(:nth-of-type(3)) {
					word-break: keep-all;
					white-space: nowrap;
				}
				&:last-child {
					@include r.sp {
						padding: 4px 8px;
					}
				}
			}
		}
	}

	.nk23_c-text01 {
		font-weight: 700;
		line-height: 1.4;
		padding: 16px 0 0 16px;

		@include r.sp {
			padding: 8px 0 0 16px;
		}
	}

	// tab2にあるテキストはPC版で非表示
	[data-tab4-content="tab2"] {
		.nk23_c-text01 {
			display: none;

			@include r.sp {
				display: block;
			}
		}
	}
}

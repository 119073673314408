@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;

.p-my_login {
	&#contents {
		@include r.sp {
			padding: 20px 8px 40px;
		}
	}
  //-----------------
  .pT50 {
    padding-top: 50px;

    @include r.sp {
      padding-top: 0;
    }
  }
}

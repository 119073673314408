@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-kijyoba_list {
	padding: 16px 0 24px;

	#race-header550-2 {
		@include r.sp {
			margin: 8px 8px 16px;
		}
	}

	.tx-small {
		@include r.sp {
			padding: 0 8px;
		}
	}

	.tb01 {
		margin-bottom: 16px;
		th {
			@include r.sp {
				white-space: nowrap;
			}
		}
	}

	.tl-main {
		img {
			width: 450px;

			@include r.sp {
				width: 220px;
			}
		}
	}
}

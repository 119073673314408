@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-telephone_guide {
  display: flex;
  max-width: 960px;
  margin: auto;

  p {
    font-size: 1.3rem;
    line-height: 2.24rem;
  }

  .contents750 {
    background: #fff;
    padding: 24px 9px;
    border-radius: 8px;
    width: 770px;

    .clearfix {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 -10px 16px;

      .tx-blue {
        font-size: 1.3rem;
        margin-top: 4px;
        color: #221f1f;
      }

      .w200px {
        padding: 0 10px;
        box-sizing: border-box;
        text-align: center;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
  }

  .title-spat4-02 {
    margin-left: -9px !important;
  }

  .tx-small {
    margin-top: 8px;
  }

  .title-spat4-03 {
    margin-top: 24px;
  }

  p {
    + .title-spat4-04 {
      margin-top: 32px;
    }
  }
}

@use "../../settings/variable"as v;
@use "../../settings/mixin"as r;
@use "../../settings/_path"as p;

.p-repay {
  &1 {
    padding: 20px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }

    .nk23_c-title01 {
      margin-bottom: 10px;
    }

    .nk23_c-listbtn__item:not(:last-child) {
      margin-right: 8px;
    }

    .nk23_c-card01 {
      margin-bottom: 16px;

      @include r.sp {
        margin: 0 8px 16px;
      }
    }
  }

  .nk23_c-notice {
    @include r.sp {
      padding: 6px 8px;
    }
  }

  .nk23_c-tab2 {
    &__navi__wrap {
      position: relative;
      z-index: 1;
    }
  }

  &__date {
    font-size: 1.4rem;
    margin: 8px 0 16px;

    @include r.sp {
      margin: 8px;
      font-size: 1.2rem;
    }

    &__txt {
      font-weight: bold;
      font-size: 1.6rem;

      @include r.sp {
        font-size: 1.4rem;
      }
    }
  }

  &__group {
    margin-bottom: 16px;

    .nk23_c-notice {
      margin-bottom: 8px;
      margin-top: 8px;
    }

    .nk23_c-table01 {
      @include r.pc {
        &__table {
          &:last-child {
            tbody {
              th {
                background-color: v.$color34;
                color: v.$color0;
                font-weight: bold;
                padding: 8px;
              }
            }
          }
        }
      }

      @include r.sp {
        margin: 0 8px;

        &__table {
          border-radius: 0 0 8px 8px;

          tr {
            &:last-child {

              td,
              th {
                border-bottom: none;
              }
            }
          }
        }
      }

      &__top {
        min-height: 56px;
        padding: 0 16px;

        @include r.sp {
          padding: 0 8px;
          justify-content: flex-start;
          border-radius: 8px 8px 0 0;
          overflow: hidden;
          position: relative;
          flex-wrap: wrap;

          &.is-open {
            border-radius: 8px;
            margin-bottom: 8px;

            .nk23_c-table01__top__arrow {
              &::before {
                transform: rotate(90deg);
              }
            }
          }

          .nk23_c-btn01 {
            margin: 10px 48px;
          }
        }

        &__arrow {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          &::before {
            content: "";
            position: relative;
            width: 8px;
            height: 16px;
            transform: rotate(-90deg);
            background: url(p.$path + "images/common/icon/icon_arrow-right.svg") no-repeat center / 100%;
          }
        }
      }

      &__inner {
        padding-bottom: 0;
      }

      .nk23_u-txtNote {
        position: relative;
        padding-left: 12px;
        display: inline-block;
        width: auto;

        &::before {
          content: "※";
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      &__title {
        &__txt {
          @include r.sp {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }

  .nk23_c-tab2.is-style1 {
    @include r.sp {
      .nk23_c-tab2__navi__wrap {
        padding: 0 !important;
      }

      .nk23_c-tab2__navi__text {
        padding-bottom: 12px;

        .is-small {
          font-size: 1.4rem;
        }
      }
    }
  }
}

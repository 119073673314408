@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-kankakubetu {
  &1 {
    padding: 16px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }

    .nk23_c-title01 {
      margin-bottom: 16px;
    }

    .nk23_c-tab1 {
      margin-bottom: 16px;
    }

    .nk23_l-container1 {
      @include r.sp {
        padding: 0;
      }
    }

    .nk23_c-table14 {
      &__inner {
        @include r.sp {
          padding-right: 4px;
        }
      }
      &__table {
        @include r.sp {
          min-width: 630px;
        }

        tbody {
          tr {
            td {
              @include r.sp {
                padding: 4px;
                width: auto;
              }
            }
          }
        }
      }
    }
  }
}

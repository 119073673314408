@use "../../settings/variable"as v;
@use "../../settings/mixin"as r;
@use "../../settings/_path"as p;

.p-grace {
  &__sc1 {
    padding: 16px 0 40px;

    @include r.sp {
      padding-top: 8px;
    }

    &__btn {
      display: flex;
      justify-content: center;
      margin-top: 16px;

      .nk23_c-btn01 {
        min-width: 240px;

        @include r.sp {
          min-width: 100%;
        }
      }
    }
  }

  .nk23_c-tab1__box {
    margin-bottom: 16px;
  }

  .nk23_c-tab2 {
    &__content {
      .nk23_c-tab2__content {
        padding-top: 0;
      }
    }

    &__subnavi__item {
      @include r.sp {
        margin-bottom: 16px;
      }
    }
  }

  .nk23_c-table13__icon {
    @include r.sp {
      top: 74px;
    }
  }

  .nk23_c-title01 {
    margin-bottom: 16px;
  }

  .nk23_c-filter2 {
    margin-bottom: 0;
    border-radius: 8px 8px 0 0;

    @include r.sp {
      margin-bottom: 8px;
      border-radius: 8px;
    }
  }

  .nk23_c-tab7 {
    background: transparent;

    &__top {
      background: #ffffff;
      margin-bottom: 16px;
      border-radius: 8px;
      max-width: 960px;
      margin: 0 auto 16px;

      @include r.sp {
        border-radius: 0;
        margin: 0 -8px 16px -8px;
        padding: 12px 4px;
      }
    }

    &__navi {
      flex-wrap: wrap;

      &__item {
        @include r.sp {
          margin: 4px 4px;
        }
      }
    }
  }

  .nk23_c-table01 {
    margin-top: 16px;

    @include r.sp {
      margin-top: 0;
    }

    &--style3 {
      .nk23_c-table01__table {
        tr {
          &:first-child {
            th {
              width: calc(100% / 3);
            }
          }

          &:not(:first-child) {
            th {
              width: auto;
              min-width: 70px;

              &:nth-child(3n + 2) {
                min-width: 70px;
              }

              &:nth-of-type(1),
              &:nth-of-type(6),
              &:nth-of-type(11) {
                min-width: 88px;
              }

              &:nth-of-type(5),
              &:nth-of-type(10),
              &:nth-of-type(15) {
                min-width: 93px;
              }
            }
          }
        }
      }
    }

    &__table {
      @include r.sp {
        min-width: 100%;
        font-size: 1.4rem;
      }

      td {
        @include r.sp {
          text-align: center;
        }
      }
    }

    &__top {
      @include r.sp {
        margin: 16px -8px 0;
        border-bottom: 1px solid #c9c9c9;
      }
    }

    &__title {
      @include r.sp {
        padding: 16px 8px;
      }
    }

    .nk23_c-text01 {
      @include r.sp {
        margin: 8px 8px 0;
      }
    }
  }

  .nk23_c-tab2--sp {
    .nk23_c-tab2__subnavi {
      @include r.sp {
        padding: 16px 8px 0;
        background: #fff;
        margin: 0 -8px;
        width: 100%;
      }

      &:after {
        @include r.sp {
          display: none;
        }
      }
    }

    .nk23_c-tab2__subnavi__item {
      &:last-child {
        @include r.sp {
          display: inline-block;
        }
      }
    }
  }

  .nk23_c-tab2 {
    .swiper-button-next {
      &:after {
        display: none;
      }
    }
  }

  .nk23_c-tab2 {
    &.is-style1 {
      .nk23_c-tab2__navi__text {
        &:hover {
          transition: .3s ease-in-out;
        }
      }
    }
  }

  .nk23_c-txtNote {
    margin-top: 8px;

    @include r.sp {
      padding: 0;
    }
  }
}
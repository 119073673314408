@use "../../settings/variable"as v;
@use "../../settings/mixin"as r;
@use "../../settings/_path"as p;

.p-news_kako {
  @include r.sp {
    .note-content {
      background: #fff;
      border-radius: 0 0 8px 8px;
      padding: 4px;
      font-size: 1.6rem;
      line-height: 1.45;
      color: #000000;
      font-weight: bold;
    }
  }
}
@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-sitemap {
  display: flex;
  max-width: 960px;
  margin: auto;

  #submenu {
    width: 100%;
  }

  #contents730 {
    width: 770px;
    background: #fff;
    padding: 15px 20px 21px !important;
    border-radius: 8px;

    p {
      font-size: 1.3rem;
      line-height: 2.1rem;
      display: block;

      a {
        color: #2461ee;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    h2 {
      margin-bottom: 36px;
    }
  }

  > .menuBox {
    > ul {
      margin-bottom: 14px;
    }
  }

  div {
    &.menuBox {
      &:last-child {
        li {
          span {
            a {
              word-break: keep-all;
              white-space: nowrap;
            }
          }
        }
      }
      h3 {
        font-weight: 400 !important;
        font-size: 1.6rem;
        border-bottom: 1px solid #c9c9c9;
        line-height: 1.9rem;
        padding: 0 0 4px;
        margin-bottom: 17px !important;

        &:before {
          background-image: none;
          background-color: #81b23f;
          border-radius: 0;
          width: 6px;
          height: 20px;
          opacity: 1;
          top: 0;
        }

        &:after {
          background-image: none;
          display: none !important;
        }
      }

      h4 {
        color: #221f1f;
        font-size: 1.4rem;
        font-weight: bold;
        margin: 0 0 14px;
      }

      ul {
        font-size: 1.3rem;

        li {
          background: url(/shared/images/icon/ico-02.svg) no-repeat 0 3px;
          line-height: 1.9rem;
          margin-top: 1px;
          span {
            @media screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
              width: 180px;
            }
          }
          a {
            color: #2461ee;
            @media screen and (min-width: 768px) and (max-width: 1280px) and (orientation: portrait) {
              font-size: 1.1rem;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .limited {
		position: relative;
		top: -2px;

    &:after {
      content: "";
      background: url(p.$path + "images/common/icon/icon_horse-blue.svg") no-repeat;
      content: "";
      display: inline-block;
      width: 18px;
      height: 18px;
      background-size: 18px 18px;
      margin-left: 5px;
      position: relative;
      top: 3px;
    }
  }
}

.menuBox {
  margin: 0 0 38px 0;

  &:nth-of-type(3) {
    margin-bottom: 49px;
  }

  &.style2 {
    margin-bottom: 28px;

    > ul {
      margin-bottom: 17px;

      &:nth-child(4),
      &:nth-child(5) {
        padding-left: 12px;
      }

      &:last-child {
        justify-content: space-between;
        max-width: 476px;
        padding-left: 26px;

        li {
          div {
            width: 168px;
          }
        }
      }
    }
  }

  &:nth-of-type(2) {
    margin-bottom: 0;

    > ul {
      li {
        margin-bottom: 20px;
      }
    }
  }

  &:nth-of-type(4) {
    margin-bottom: 34px;

    h3 {
      &:before {
        background-color: #004ea2 !important;
      }
    }
  }

  &:nth-of-type(5) {
    margin-bottom: 49px;
  }

  &:nth-of-type(6) {
    margin-bottom: 23px;

    ul {
      li {
        div {
          width: 168px;
        }
      }
    }
  }

  &:nth-of-type(7) {
    margin-bottom: 14px;

    ul {
      &:nth-child(2) {
        margin-bottom: 18px;
      }
    }
  }

  &:nth-of-type(8) {
    margin-bottom: 31px;
  }

  &:nth-child(9) {
    ul {
      li {
        div {
          width: 167px;
        }
      }
    }
  }

  &:nth-child(9),
  &:nth-child(10) {
    margin-bottom: 30px;
  }

  &:nth-child(11) {
    margin-bottom: 50px;
  }

  &:nth-child(12) {
    margin-bottom: 26px;
  }

  &:nth-child(13) {
    margin-bottom: 30px;
  }

  &:nth-child(14) {
    margin-bottom: 12px;
  }

  &:nth-child(15) {
    margin-bottom: -7px;
  }

  &:nth-child(16) {
    margin-bottom: 33px;
  }

  &:nth-child(17) {
    margin-bottom: 13px;
  }

  &:nth-of-type(9),
  &:nth-of-type(11),
  &:nth-of-type(12),
  &:nth-of-type(13),
  &:nth-of-type(14) {
    > ul {
      li {
        margin-bottom: 20px;
      }
    }
  }

  &:last-child {
    margin: 0;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    height: auto;
    float: none;

    div {
      width: 142px;
      height: auto;
    }

    span {
      display: block;
      padding-left: 12px;
      width: 168px;
      height: auto;
    }
  }
}

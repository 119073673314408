@use '../../settings/variable' as v;
@use '../../settings/mixin' as r;
@use '../../settings/_path' as p;

.p-jyusyosiki_result {
	padding: 16px 0 40px;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;

	[data-tab-content] {
		padding-top: 16px;
	}

	.contents950 {
		max-width: 1200px;
	}

	.ico-umanum {
		width: 20px;
	}

	.tl-main {
		margin-bottom: 16px;
		text-align: center;

		img {
			display: inline-block;
		}
	}

	.mT30 {
		font-size: 1.3rem;
		line-height: 2.2rem;
	}

	.tx-large {
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 960px;

		& + .tx-large {
			margin: 16px auto;
		}

		& + .mT30 {
			margin-left: auto;
			margin-right: auto;
			max-width: 960px;
		}
	}

	.nk23_c-listlink {
		margin-bottom: 16px;
	}

	.tb01 {
		margin: 16px 0;

		@include r.pc {
			border-radius: 8px;
			overflow: hidden;
		}

		.hitban {
			display: inline-block;
			vertical-align: top;
		}

		td {
			img {
				display: inline-block;

				&:not([class^='ico-']) {
					@include r.pc {
						position: relative;
						top: -2px;
					}
				}
			}
		}

		thead {
			th {
				font-size: 1.2rem;

				&:first-child {
					width: 1%;
					min-width: 85px;
				}

				&:nth-child(2) {
					width: 1%;
					min-width: 52px;
				}

				&:nth-child(3) {
					width: 1%;
					min-width: 110px;
				}

				&:nth-child(4),
				&:nth-child(5),
				&:nth-child(6),
				&:nth-child(7) {
					width: 1%;
					min-width: 120px;
				}

				&:nth-child(8),
				&:nth-child(9) {
					width: 1%;
					min-width: 175px;
				}

				&:nth-child(8) {
					// width: 1%;
					// min-width: 62px;
				}
			}

			th.tx_sort.tablesorter-header {
				padding-bottom: 20px;
				cursor: pointer;
				background-image: url(p.$path + 'images/common/icon/icon-desc-white.svg'),
					url(p.$path + 'images/common/icon/icon-asc-white.svg');
				background-position: bottom 12px center, bottom 4px center;
				background-size: 8px 6px, 8px 6px;
				background-repeat: no-repeat, no-repeat;
			}

			th.tx_sort.tablesorter-headerDesc {
				background-image: url(p.$path + 'images/common/icon/icon-desc-white.svg');
				background-position: bottom 12px center;
				background-size: 8px 6px;
				background-repeat: no-repeat;
			}

			th.tx_sort.tablesorter-headerAsc {
				background-image: url(p.$path + 'images/common/icon/icon-asc-white.svg');
				background-position: bottom 4px center;
				background-size: 8px 6px;
				background-repeat: no-repeat;
			}
		}

		tbody {
			td {
				font-size: 1.3rem;
				height: 52px;

				&:not([class*='bg-']) {
					background-color: #fff;
				}

				&:first-child {
					// width: 1%;
					// min-width: 40px;
				}
			}
		}
	}

	#box-search {
		margin-bottom: 16px;

		.bl-left img.mR5 {
			margin-right: 0;
		}
	}
}

@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-leading_kis_cho {
  &1 {
    padding: 20px 0 40px;

    .nk23_l-container {
      @include r.sp {
        padding: 0;
      }
    }

    .nk23_c-title01 {
      margin-bottom: 20px;
    }

    .nk23_c-block18 {
      &:only-child {
        @include r.sp {
          margin-bottom: 0;
        }
      }

      &.is-style1 {
        @include r.sp {
          width: 100%;
          max-width: calc(100% - 16px);
          margin-left: 8px;
        }
      }

      & + .nk23_c-text01 {
        @include r.sp {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }

    .nk23_c-table24 {
      @include r.sp {
        margin-left: 0;
        margin-right: 0;
      }

      &__icon {
        @include r.sp {
          top: 200px;
        }
      }

      &__table {
        @include r.sp {
          min-width: 688px;
        }

        thead {
          th {
            @include r.sp {
              min-width: auto;
              width: auto;
              padding: 4px;
            }

            &:nth-child(2) {
              width: 120px;

              @include r.sp {
                width: 1%;
                min-width: 120px;
              }
            }
          }
        }

        tbody {
          td {
            &:nth-child(2),
            &:nth-child(4) {
              text-align: left;
            }

            &:nth-child(9),
            &:nth-child(10),
            &:nth-child(11) {
              text-align: center;
            }

            &:last-child {
              text-align: right;
              @include r.sp {
                padding: 4px 8px;
              }
            }
          }
        }
      }
    }
  }
}

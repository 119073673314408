@use '../../settings/variable'as v;
@use '../../settings/mixin'as r;
@use '../../settings/_path'as p;


.p-play_video {
  padding: 16px 8px 40px;

  h2 {
    font-size: 2.4rem;
    font-weight: 700;
    color: #221F1F;
    line-height: 2rem;
    border-top: 0;
    border-bottom: 0;
    box-shadow: none;
    margin-bottom: 16px;
  }

  .play_video {
    &.type {
      font-size: 1.4rem;
    }
  }

  #tools {
    display: block;

    a {
      width: 300px;
      background: #FFFFFF;
      border-radius: 24px;
      font-size: 1.6rem;
      display: block;
      font-weight: 700;
      text-align: center;
      padding: 0;
      margin: auto;
      border: 1px solid #C9C9C9;
      box-shadow: 0 2px 0 #C9C9C9;
      min-height: 28px;
      position: relative;

      @include r.max-w(320px) {
        width: 250px;
      }

      &:hover {
        box-shadow: none;
        position: relative;
        top: 2px;
      }
    }
  }
}
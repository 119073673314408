@use "../../settings/variable" as v;
@use "../../settings/mixin" as r;
@use "../../settings/_path" as p;

.p-download_wallpaper {
  display: flex;
  max-width: 960px;
  margin: auto;

  #contents740 {
    width: 770px;
    background: #fff;
    padding: 15px 15px 27px !important;
    border-radius: 8px;
    font-size: 1.3rem;
    line-height: 2.1rem;

    h2 {
      margin-bottom: 16px;
    }
    h4 {
      margin-bottom: 8px;
      font-weight: 700;
    }
    img.al-left {
      width: auto;
      display: inline-block;
    }
    p {
      font-size: 1.3rem;
      line-height: 2.1rem;
      margin-bottom: 13px;
      &.img-group {
        display: flex;
        align-items: flex-end;
        margin-top: 33px;
        margin-bottom: 38px;
        img {
          &:first-child {
            margin: 0 124px 0 17px;
          }
        }
      }
      &.bd-btm-ccc {
        border-bottom: 1px solid #c9c9c9;
      }
      &:nth-of-type(6) {
        margin-bottom: 8px;
      }
    }
    a {
      color: v.$color-link;
      u {
        text-decoration: none;
      }
      @include r.hover {
        text-decoration: underline;
      }
      &.ico-popup {
        margin: 0;
        padding: 0 0 0 24px;
        background: url(p.$path + "images/common/icon/ico-popup-gif.svg") no-repeat left 8px / 20px;
      }
    }
  }
}
